export enum PaymentMethod {
  CREDIT_CARD = 'Cartão',
  BOLETO = "Boleto",
  UNDEFINED = '-'
}

export enum PaymentStatus {
  PROCESSING = 'Processando',
  AUTHORIZED = 'Autorizado',
  PAID = 'Pago',
  REFUNDED = 'Reembolsado',
  WAITING_PAYMENT = 'Aguardando Pagamento',
  PENDING_REFUND = 'Reembolso Pendente',
  REFUSED = 'Recusado',
  CHARGEDBACK = 'Estornado',
  UNDEFINED = '-'
}

export enum PaymentType {
  SUBSCRIPTION = 'Assinatura',
  FIRST_TRANSACTION = 'Primeira Transação',
  INFORMATIVE = 'Informativo',
  ADDITIONAL_KM = 'Quilometragem Adicional',
  FINES = 'Multa',
  CANCELLATION_FINE = 'Multa de Cancelamento',
  EXTRA = 'Extra',
  ACCESSORIES = 'Acessórios',
  CHARGEBACK = 'Estorno',
  SUBSCRIPTION_OWNER_DEALER = 'Assinatura',
  SUBSCRIPTION_OWNER_FORD = 'Assinatura',
  ADDITIONAL_KM_FORD_RENTAL = 'Quilometragem Adicional',
  FINES_FORD_RENTAL = 'Multa',
  CANCELLATION_FINE_FORD_RENTAL = 'Multa de Cancelamento',
  EXTRA_FORD_RENTAL = 'Extra',
  ACCESSORIES_FORD_RENTAL = 'Acessórios',
  MONTHLY_CHARGE = 'Assinatura'
}

export interface Statement {
  createdDate?: Date | string,
  beginSubDate?: Date | string,
  endSubDate?: Date | string,
  details?: string,
  paymentMethod?: string,
  paymentStatus?: string,
  paymentType?: string,
  plate?: string,
  tid?: string,
  vehicle?: string,
  version?: string
}
