import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'contract-finish',
  templateUrl: './contract-finish.component.html',
  styleUrls: ['./contract-finish.component.scss']
})
export class ContractFinishComponent implements OnInit {

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.translate.use('pt');
  }

}
