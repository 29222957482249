import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {FleetStatus, SubscriptionDetailFleet} from "../../models/subscription-detail.model";

@Component({
  selector: 'status-fleet',
  templateUrl: './status-fleet.component.html',
  styleUrls: ['./status-fleet.component.scss']
})
export class StatusFleetComponent implements OnInit {

  form: FormGroup;
  public resultList: Array < SubscriptionDetailFleet > ;

  constructor(private formBuilder: FormBuilder) { }

  @Input() PAYMENT = false;
  @Input() PENDING_ANALYSE =  false;
  @Input() APPROVED = false;
  @Input() VEHICLE_PRODUCED =  false;
  @Input() LICENSE_PLATE = false;
  @Input() TRANSPORT =  false;
  @Input() DELIVERY_SCHEDULE = false;
  @Input() VEHICLE_DELIVERED =  false;
  @Input() CONNECTED = false;
  @Input() DISCONNECTED =  false;
  @Input() CANCELED =  false;
  @Input() FINALIZED = false;
  @Input() WITHOUT = false;
  @Input() entryList: Array < SubscriptionDetailFleet >;
  @Input() close = new EventEmitter<boolean>();
  @Output() result = new EventEmitter<Array<SubscriptionDetailFleet>>();

  ngOnInit(): void {
    this.getForm();
    this.change();
  }

  getForm(){
    this.form = this.formBuilder.group({
      PAYMENT: [false as boolean],
      PENDING_ANALYSE: [false as boolean],
      APPROVED: [false as boolean],
      VEHICLE_PRODUCED: [false as boolean],
      LICENSE_PLATE: [false as boolean],
      TRANSPORT: [false as boolean],
      DELIVERY_SCHEDULE: [false as boolean],
      VEHICLE_DELIVERED: [false as boolean],
      CONNECTED: [false as boolean],
      DISCONNECTED: [false as boolean],
      CANCELED: [false as boolean],
      FINALIZED: [false as boolean],
    });
  }

  getStatusFleet(status: string) {
    return FleetStatus[status];
  }


  getClear(){
    this.getForm();
    this.result.emit(this.entryList);
  }

  change(){
    this.close.asObservable().subscribe( value => {
      if(value === true){
        this.getForm();
      }
    });
  }


  send() {
    this.filterList();
    console.log(this.form);
    this.result.emit(this.resultList);
  }

  filterList(){

    // this.resultList = this.entryList.filter(value => {
    //    if(this.form.get('PAYMENT').value){ if(FleetStatus[value.status] === FleetStatus.PAYMENT) return value; };
    //    if(this.form.get('PENDING_ANALYSE').value){ if(FleetStatus[value.status] === FleetStatus.PENDING_ANALYSE) return value; };
    //    if(this.form.get('APPROVED').value){ if(FleetStatus[value.status] === FleetStatus.APPROVED) return value; };
    //    if(this.form.get('VEHICLE_PRODUCED').value){ if(FleetStatus[value.status] === FleetStatus.VEHICLE_PRODUCED) return value; };
    //    if(this.form.get('LICENSE_PLATE').value){ if(FleetStatus[value.status] === FleetStatus.LICENSE_PLATE) return value; };
    //    if(this.form.get('TRANSPORT').value){ if(FleetStatus[value.status] === FleetStatus.TRANSPORT) return value; };
    //    if(this.form.get('DELIVERY_SCHEDULE').value){ if(FleetStatus[value.status] === FleetStatus.DELIVERY_SCHEDULE) return value; };
    //    if(this.form.get('VEHICLE_DELIVERED').value){ if(FleetStatus[value.status] === FleetStatus.VEHICLE_DELIVERED) return value; };
    //    if(this.form.get('CONNECTED').value){ if(FleetStatus[value.status] === FleetStatus.CONNECTED) return value; };
    //    if(this.form.get('DISCONNECTED').value){ if(FleetStatus[value.status] === FleetStatus.DISCONNECTED) return value; };
    //    if(this.form.get('CANCELED').value){ if(FleetStatus[value.status] === FleetStatus.CANCELED) return value; };
    //    if(this.form.get('FINALIZED').value){ if(FleetStatus[value.status] === FleetStatus.FINALIZED) return value; };
    //    if(this.form.get('WITHOUT').value){ if(FleetStatus[value.status] === FleetStatus.WITHOUT) return value; };
    // });
  }

}
