import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cancel-contract',
  templateUrl: './cancel-contract.component.html',
  styleUrls: ['./cancel-contract.component.scss'],
})
export class CancelContractComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }

  onClose (){}

}
