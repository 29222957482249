import { SubscriptionDetail } from './../../../shared/models/subscription-detail.model';
import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubscriptionService } from '../../../shared/service/subscription.service';
import { UploadUpdateComponent } from 'src/app/shared/components/upload-update/upload-update.component';
import { ApplicationService } from '../../../core/services/application.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Driver } from 'src/app/shared/models/driver.model';
import { Alert } from '../../../shared/class/alert';
import { HttpErrorHandleService } from 'src/app/shared/service/data/http-error-handle/http-error-handle.service';
import { ErrorHandler } from '../../../shared/class/ErrorHandler';
import { FineService } from 'src/app/shared/service/fine.service';

@Component({
  selector: 'driver-list',
  templateUrl: './driver-list.component.html',
  styleUrls: ['./driver-list.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class DriverListComponent implements OnInit {
  public drivers: Array<Driver> = [];
  closeResult = '';
  public subscription: SubscriptionDetail;

  public driverId: any;
  public plate: any;
  public dialogAction: boolean = false;
  public isFleet: boolean = false;

  driverSelect: any[] = [];
  selectedDriverId: any;
  changeMainDriveSuccess: boolean = false;
  isCnpj: boolean = false;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private modalService: NgbModal,
    private dialog: MatDialog,
    private subscriptionService: SubscriptionService,
    private spinner: NgxSpinnerService,
    private applicationService: ApplicationService,
    private errorHandler: ErrorHandler,
    private alert: Alert,
    private fineService: FineService
  ) { }

  ngOnInit(): void {
    this.translate.use('pt');
    this.loadDrivers();
    this.loadSubscriptionDetails();
    this.plate = localStorage.getItem('plate-driver');
    this.isCnpj = this.applicationService.getUserLogged().info.document != undefined && this.applicationService.getUserLogged().info.document.length > 11;
    this.checkTypeCustomer();
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event) {
		this.dialogAction = false;
	}

  checkTypeCustomer(): void {
    this.subscriptionService.isFleetOwner().subscribe(result => {
      this.isFleet = result;
    });
  }

  loadDrivers() {
    this.spinner.show();

    this.subscriptionService.drivers(this.applicationService.getActiveSubscription().uuid).subscribe(
      (response) => {
        this.spinner.hide();
        this.drivers = response;
      },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        this.errorHandler.error(err, this.loadDrivers.bind(this));
      }
    );
  }

  goToTerms(driver: Driver) {
    this.router.navigate(['/dashboard/terms', driver.id]);
  }

  driverEdit(driver: Driver) {
    this.dialog.open(UploadUpdateComponent, { width: '950px', height: '750px', data: {driver: driver, isCnpj: this.subscription.cnpj} });
  }

  async excludeDriver(driver: Driver) {
    const question = await this.alert.question(`Tem certeza que deseja excluir o motorista ${driver.name} do veículo de placa ${this.plate} ?`);
    if (question.dismiss) return;
    this.spinner.show();

    this.subscriptionService.deleteDriver(this.applicationService.getActiveSubscription().uuid, driver.id)
      .subscribe(
        () => {
          this.spinner.hide();
          this.alert.success('Motorista excluído com sucesso!');
          this.loadDrivers();
          this.getDrivers();
        },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          this.alert.error('Erro ao excluir motorista');
          this.errorHandler.error(err, this.excludeDriver.bind(this), driver.id);
        }
      );
  }

  loadSubscriptionDetails() {
    this.spinner.show();

    this.subscriptionService.detail(this.applicationService.getActiveSubscription().uuid)
      .subscribe(
        (response) => {
          this.spinner.hide();
          this.subscription = response;
          this.getDrivers();
        },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          this.errorHandler.error(err, this.loadSubscriptionDetails.bind(this));
        }
      );
  }

  getDrivers() {
    this.spinner.show();

    this.fineService.getDriversByPlate(this.subscription.plan.vehicle.plate, this.applicationService.getUserLogged().token.uuid).subscribe(
      (data) => {
        const drivers = <Driver[]>data;
        this.driverSelect = [];

        drivers.forEach(driver => {
          this.driverSelect.push({label: driver.name + " (CNH: " + driver.cnh + ")", value: driver.id});
        });

        this.spinner.hide();
      },
			(err: HttpErrorResponse) => {
				this.spinner.hide();
      }
    );
  }

  confirmChangeMainDriverModal() {
    this.spinner.show();

    this.subscriptionService.changeMainDriver(this.applicationService.getActiveSubscription().uuid, this.selectedDriverId).subscribe(
      () => {
        this.loadDrivers();
        this.changeMainDriveSuccess = true;
        this.spinner.hide();
      }
    )
  }

  closeChangeMainDriverModal() {
    this.selectedDriverId = undefined;
    this.changeMainDriveSuccess = false;
  }

  showAction(driver: Driver, $event: any): void {
		$event.stopPropagation();
		this.driverId = driver.id;
		this.dialogAction = !this.dialogAction;
	}
}