import { Component, OnInit } from '@angular/core';
const menuData = require('./footer.data.json').data;

@Component({
	selector: 'footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	menu = menuData;

	ngOnInit() {
	}
}
