import { Component,Injector, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { Alert } from 'src/app/shared/class/alert';
import { Summary, SummaryFilter } from 'src/app/shared/models/summary.model';
import { ErrorHandler } from '../../../shared/class/ErrorHandler';
import { Filter } from '../../../core/class/Filter';
import { SummaryFilterFields } from './component/filter-summary/filter-summary.component';
import { StorageService } from 'src/app/shared/service/data/storage/storage.service';

@Component({
  selector: 'app-dashboard-summary',
  templateUrl: './dashboard-summary.component.html',
  styleUrls: ['./dashboard-summary.component.scss']
})
export class DashboardSummaryComponent implements OnInit {

  public summarys: Array<Summary> = [];
  public oldSummary: Array<Summary> = [];
  public totalSummary: Array<Summary> = []
  public searchForm: FormGroup;
  
  constructor(
    protected injector: Injector,
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
    private subscriptionService: SubscriptionService,
    private applicationService: ApplicationService,
    private errorHandler: ErrorHandler,
    private alert: Alert,
    public translateService: TranslateService,
    public formBuilder: FormBuilder,
    public storage: StorageService
  ) { }

  async ngOnInit() {
    
    this.translate.use('pt');
    this.loadSummary();

    this.searchForm = this.formBuilder.group({
      search: ['']
    });

    this.searchForm.valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged()
    ).subscribe(value => this.filterSummary(value.search));

    
  }

  async loadSummary(filters?: SummaryFilterFields) {
    const subscription = this.applicationService.getActiveSubscription()

    this.subscriptionService.summary(subscription.uuid).subscribe(
      (response) => {
 
        if (filters) {
					this.filterData(response, filters);
				} else {
					this.summarys = response;
				}

				
      },
      (err: HttpErrorResponse) => {
				this.spinner.hide();
        this.errorHandler.error(err, this.loadSummary.bind(this));
      }
    );

  
  }

  seachFilter(value: string, searchValue: string): boolean {
    if (value.toLowerCase().search(searchValue.toLowerCase()) >= 0) {
      return true
    }

    return false
  }

  filterSummary(filterValue: string) {
      const summarys = [];

      for (const summary of this.summarys) {

        for (const [property, value] of Object.entries(summary)) {
          let alredyInsert = false;

          if (!alredyInsert) {
            const valueToSearch = property === 'type' ? summary.type.description : value;

            if (this.seachFilter(String(valueToSearch), filterValue)) {
              alredyInsert = true;
              summarys.push(summary)
            }
          }
        }
      
      this.summarys = summarys;

    }
  }

  seeMore() {
    if (this.totalSummary.length > 10) {
      this.totalSummary.slice(0, 10).map(summary => this.summarys.push(summary));
      this.totalSummary = this.totalSummary.splice(10, this.totalSummary.length);

    } else if (this.totalSummary.length > 0) {
      this.totalSummary.map(summary => this.summarys.push(summary));
      this.totalSummary = [];
    }
  }

  checkStatus(summary: Summary): string {
    if (summary.status) {
      if(summary.status.description === 'Autorizado') {
        return 'Aguardando Pagamento';
      } 

      return summary.status.description;
    }else{
      return '';  
    }

  }

  translatePayment(summary: Summary): string {
     if(summary.paymentMethod ===  summary.paymentList.BOLETO){
      return 'Boleto Bancário';
    }else if(summary.paymentMethod === summary.paymentList.CREDIT_CARD){
      return 'Cartão de Crédito';
    }else{
      return '';
    }
  }

  translateValue(summary: Summary): string {
    return summary.type.type === summary.typeList.INFORMATIVE ? '' : summary.amount;
  }

  generateReport(code: string) {
    this.spinner.show();
    this.subscriptionService.getPaytmentReceipt(code).subscribe(result => {
      this.spinner.hide();
      const blob = new Blob([result], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = url;
      link.download = "recibo_".concat(code).concat('.pdf');
      link.click();
      this.alert.success("Recibo Gerado!");
    }, error => {
      this.spinner.hide();
      console.log(error);
      this.alert.error("Ocorreu um erro ao gerar o recibo!");
    });
  }

  bindingFilters(filterValue: SummaryFilterFields) {
    this.loadSummary(filterValue);
  }

  filterData(extracts: Array<Summary>, filters: SummaryFilterFields) {
    this.spinner.show();
    const validFilters = Filter.sanitizeValidFilters(filters) as SummaryFilterFields;

      if (Object.keys(validFilters).length > 0 && extracts.length > 0) {
        const extractFilters = extracts.map(extract => SummaryFilter.fromSummary(extract));
        const filter = new Filter<SummaryFilter, SummaryFilterFields, Summary>(extractFilters, validFilters, [
          extractFilters[0].filterPropertys.date,
        ]);

        this.summarys = filter.process();
        this.spinner.hide();
      } else {
        this.summarys = extracts;
        this.spinner.hide();
      }
  }

  
	openPaymentSlip(extract: Summary) {
		if(extract.mainTransaction) {
			window.open(extract.boletoUrl, '_blank');
		} else {
			this.downloadTicket(extract.tid);
		}
	}

  downloadTicket(ticketId: string){
		this.spinner.show();
		this.subscriptionService.downloadBoletoSAPP(ticketId).subscribe({
      next: (result) => {
        this.spinner.hide();
        const blob = new Blob([result], {type: 'application/pdf'});
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = "boleto_".concat(ticketId).concat('.pdf');
        link.click();
        this.alert.success("Boleto Gerado!");
      }, 
      error: () => {
        this.spinner.hide();
        this.alert.error("Ocorreu um erro ao gerar o boleto!");
		  }
    });
	}

}