import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

let node = document.createElement('script');
node.src = environment.fma.url;
node.type = 'text/javascript';
node.async = true;
node.setAttribute('data-fma-script', '');
node.setAttribute('data-enable-pkce', 'true');
node.setAttribute('data-enable-guest-guid', 'false');
node.setAttribute('data-lang', 'pt_pt');
if (environment.fma.clientid != undefined) node.setAttribute('data-client-id', environment.fma.clientid);
if (environment.fma.appid != undefined) node.setAttribute('data-app-id', environment.fma.appid);
if (environment.fma.ibmid != undefined) node.setAttribute('data-ibm-cid', environment.fma.ibmid);
node.setAttribute('data-redirect-url', environment.fma.redirecturl);
document.getElementsByTagName('head')[0].appendChild(node);

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
	.then(() => {
		if ('serviceWorker' in navigator && environment.production) {
			navigator.serviceWorker.register('./ngsw-worker.js');
		}
	})
	.catch(err => console.error(err));
