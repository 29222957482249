import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardFleetRoutingModule } from './dashboard-fleet-routing.module';
import {HomeComponent} from "./home/home.component";
import { ExtractGeralComponent } from './extract-geral/extract-geral.component';
import {GuardFleet} from "./auth/guard.fleet";
import {BreadcrumbComponent} from "../../shared/components/breadcrumb/breadcrumb.component";
import {SharedModule} from "../../shared/shared.module";
import {
  KilometersPercentageComponent
} from "../../shared/components/kilometers-percentage/kilometers-percentage.component";
import {DialogModule} from "primeng/dialog";
import {DatesComponent} from "../../shared/components/dates/dates.component";
import {StatusFleetComponent} from "../../shared/components/status-fleet/status-fleet.component";
import {PlateComponent} from "../../shared/components/plate/plate.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CalendarModule} from "primeng/calendar";
import {ListboxModule} from "primeng/listbox";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {DashboardStatementComponent} from "../dashboard/dashboard-statement/dashboard-statement.component";
import {DashboardModule} from "../dashboard/dashboard.module";


@NgModule({
  declarations: [HomeComponent, ExtractGeralComponent],
  imports: [
    CommonModule,
    DashboardFleetRoutingModule,
    SharedModule,
    DialogModule,
    ReactiveFormsModule,
    CalendarModule,
    ListboxModule,
    FormsModule,
    OverlayPanelModule,
    DashboardModule
  ],
  providers: [GuardFleet, BreadcrumbComponent],
  entryComponents: [KilometersPercentageComponent, DatesComponent, StatusFleetComponent, PlateComponent, DashboardStatementComponent]
})
export class DashboardFleetModule { }
