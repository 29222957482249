export enum PaymentMethod{
  CREDIT_CARD = ('CREDIT_CARD'),
  BOLETO = ('BOLETO')
}

export enum TransactionStatus{
  PROCESSING=('PROCESSING'),
  AUTHORIZED = ('AUTHORIZED'),
  PAID = ('PAID'),
  REFUNDED = ('REFUNDED'),
  WAITING_PAYMENT = ('WAITING_PAYMENT'),
  PENDING_REFUND = ('PENDING_REFUND'),
  REFUSED = ('REFUSED'),
  CHARGEDBACK = ('CHARGEDBACK')
}

export enum StatusRequest{
  PAYMENT = ('PAYMENT'),
  PENDING_ANALYSE = ('PENDING_ANALYSE'),
  APPROVED = ('APPROVED'),
  VEHICLE_PRODUCED = ('VEHICLE_PRODUCED'),
  LICENSE_PLATE = ('LICENSE_PLATE'),
  TRANSPORT = ('TRANSPORT'),
  DELIVERY_SCHEDULE = ('DELIVERY_SCHEDULE'),
  VEHICLE_DELIVERED = ('VEHICLE_DELIVERED')
}

export class RequestStatusModel{
  status?: StatusRequest[];
  createdDate?: Date;
  paymentMethod?: PaymentMethod;
  transactionStatus?: TransactionStatus;
  constructor() {
  }

  public static getTitle(status: StatusRequest): string{
    switch (status) {
      case StatusRequest.PAYMENT: {
        return 'Pagamento';
        break;
      }
      case StatusRequest.PENDING_ANALYSE: {
        return 'Em análise';
        break;
      }
      case StatusRequest.APPROVED: {
        return 'Aprovado';
        break;
      }
      case StatusRequest.VEHICLE_PRODUCED: {
        return 'Veículo nacionalizado';
        break;
      }
      case StatusRequest.LICENSE_PLATE: {
        return 'Veículo em processo de emplacamento';
        break;
      }
      case StatusRequest.TRANSPORT: {
        return 'Transporte para a concessionária';
        break;
      }
      case StatusRequest.DELIVERY_SCHEDULE: {
        return 'Agendamento da entrega';
        break;
      }
      case StatusRequest.VEHICLE_DELIVERED: {
        return 'Veículo entregue';
        break;
      }
    }
  }

  public static getDescription(status: StatusRequest): string{
    switch (status) {
      case StatusRequest.PAYMENT: {
        return 'Pagamento recebido.';
        break;
      }
      case StatusRequest.PENDING_ANALYSE: {
        return 'Seu pedido está em análise pelo nosso time.\n Fique ligado, pois nessa etapa pode ser que entraremos em contato para confirmar alguns dados.';
        break;
      }
      case StatusRequest.APPROVED: {
        return 'Parabéns! Você está mais próximo de ter seu Ford 0km por assinatura.';
        break;
      }
      case StatusRequest.VEHICLE_PRODUCED: {
        return 'Seu veículo foi produzido.\n Liberado para transporte internacional. \n Veículo chegou no porto.\n';
        break;
      }
      case StatusRequest.LICENSE_PLATE: {
        return 'Aguardando vistoria do Detran. \n Veículo sendo emplacado.';
        break;
      }
      case StatusRequest.TRANSPORT: {
        return 'Veículo liberado para transporte à concessionária.';
        break;
      }
      case StatusRequest.DELIVERY_SCHEDULE: {
        return 'Aqui verificamos:\n Se está tudo certo para a entrega. \n O agendamento para entrega, fique de olho que entraremos em contato em breve!';
        break;
      }
      case StatusRequest.VEHICLE_DELIVERED: {
        return 'Aproveite para desfrutrar do seu Ford Go e todas as vantagens que oferecemos.';
        break;
      }
    }
  }

}
