import { NgxSpinnerService } from 'ngx-spinner';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandler } from 'src/app/shared/class/ErrorHandler';
import { Fine } from 'src/app/shared/models/fine.model';
import { Driver } from 'src/app/shared/models/driver.model';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
import { ApplicationService } from 'src/app/core/services/application.service';
import { FineService } from 'src/app/shared/service/fine.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Alert } from 'src/app/shared/class/alert';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
	selector: 'app-dashboard-fine-detail',
	templateUrl: './dashboard-fine-detail.component.html',
	styleUrls: ['./dashboard-fine-detail.component.scss'],
})
export class DashboardFineDetailComponent implements OnInit {
	@Input() fine: Fine;
	fineImageBase64: any;
	drivers: Driver[];
	driverSelect: any[];
	selectedDriverId: any;

	timeLeft: number;
	hourLeft: number;
	minLeft: number;
	secLeft: number;

	show = false;
	success = false;
	loading = true;
	loadingAit = false;

	fineTax = '';

	constructor(
		public spinner: NgxSpinnerService,
		private errorHandler: ErrorHandler,
		private translate: TranslateService,
		private router: Router,
		private applicationService: ApplicationService,
		private fineService: FineService,
		private sanitizer: DomSanitizer,
		private alert: Alert
	) { }

	ngOnInit(): void {
		this.translate.use('pt');

		this.getDrivers();

		let fineValor = parseFloat(this.fine.valor);
		let fineBoleto = parseFloat(this.fine.valorBoleto);
		let fineRes = 'R$ ' + (fineValor - fineBoleto).toFixed(2).replace('.',',');
		if (fineRes == 'R$ NaN') {
			fineRes = 'R$ 38,60';
		}
		this.fineTax = fineRes;
	}

	ngAfterViewInit() {
		var time = new Date(this.fine.createdDate);
		time.setTime(time.getTime() + 120*60*60*1000);

		this.timeLeft = time.getTime() - (new Date()).getTime();

		const source = timer(0, 1000);
		source.subscribe(val => {
			this.timeLeft = this.timeLeft - 1000;
			this.hourLeft = Math.floor(this.timeLeft/1000/60/60);
			this.minLeft = Math.floor(this.timeLeft/1000/60) % 60;
			this.secLeft = Math.floor(this.timeLeft/1000) % 60;

			if (this.hourLeft < 0) this.hourLeft = 0;
			if (this.minLeft < 0) this.minLeft = 0;
			if (this.secLeft < 0) this.secLeft = 0;
		});
	}

	async confirm(id: number) {
		if (id == undefined) return;

		const question = await this.alert.question('Tem certeza que quer indicar esse motorista?');
		if (question.dismiss) return;

		this.spinner.show();

		const driver = this.drivers.filter(driver => driver.id == id);

		try {
			this.fine.condutorIndicado = driver[0].id;
			this.fine.nomeCondutorIndicado = driver[0].name;
			this.fine.docCondutorIndicado = driver[0].cnh;
			this.fine.status = '1';

			this.show = false;
			this.success = true;
			this.loading = true;

			setTimeout(() => {
				this.show = true;
			}, 0);

			this.fineService.saveFine(this.fine.id, this.fine).subscribe(data => {
				this.loading = false;
				this.spinner.hide()
			});
		}
		catch (Error) {
    		this.spinner.hide();
		}
	}

	getDrivers() {
		this.fineService.getDriversByPlate(this.fine.placa, this.applicationService.getUserLogged().token.uuid).subscribe(
			(data) => {
				this.drivers = <Driver[]>data;

				this.driverSelect = [];
				this.drivers.forEach(driver => {
					this.driverSelect.push({label: driver.name, value: driver.id});
				});

				this.loading = false;
			},
			(err: HttpErrorResponse) => {
				this.spinner.hide();
				this.loading = false;
			}
		);
	}

	getAit() {
		this.loadingAit = true;

		this.fineService.getFineImage(this.fine.idMulta).subscribe(data => {
			const aits = <any[]>data;
			var validAit;

			validAit = aits.filter(ait => {
				return (ait.referencia == 'N');
			});

			if (validAit.length == 0) {
				validAit = aits.filter(ait => {
					return (ait.referencia == 'NS');
				});
			}

			if (validAit.length == 0) {
				validAit = aits.filter(ait => {
					return (ait.referencia == 'EX');
				});
			}

			if (validAit.length > 0) {
				this.fineImageBase64 = "data:" + validAit[0].imagemTipo + ";base64," + validAit[0].imagem;
				var link = document.createElement('a');
				link.href = this.fineImageBase64;
				link.download = "multa_" + validAit[0].idMulta + "." + validAit[0].imagemTipo;
				link.click();
			}

			this.loadingAit = false;
		});
	}

	verifyString(value: string): boolean {
	  if (value === '' || value === undefined || value === null)
	     return false;
	  return true;
	}
}
