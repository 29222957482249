import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ApplicationService} from "../../../core/services/application.service";
import {SubscriptionDetail} from "../../models/subscription-detail.model";
import {SubscriptionService} from "../../service/subscription.service";
import {PaymentModel} from "./model/payment.model";
import {NgxSpinnerService} from "ngx-spinner";
import {Alert} from "../../class/alert";

const menuData = require('./payment-history.json').data;

@Component({
  selector: 'payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent implements OnInit {

  menu = menuData;
  todayNumber: number = Date.now();
  subscription: SubscriptionDetail;
  payments: PaymentModel[] = [];
  lastPayment: PaymentModel;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public subscriptionService: SubscriptionService,
              public spinner: NgxSpinnerService, private alert: Alert) {
  }

  ngOnInit(): void {
    if (this.data.subscription) this.subscription = this.data.subscription;

    this.getPayments();
  }

  getPayments() {
    this.subscriptionService.getAllPayments().subscribe(result => {
        this.payments = result;
        this.lastPayment = this.payments.find(pay => pay.paid === true);
      },
      error => {
        this.alert.error('Erro ao carregar histórico. Por favor, tente novamente mais tarde.');
      }
    );
  }

  download(payment: PaymentModel) {
    this.spinner.show();
    this.subscriptionService.downloadBoleto(payment.boletoCode).subscribe(
      (data) => {
        const downloadURL = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = this.subscription.code.toString().concat('_parcela_').concat(payment.installment).concat('.pdf');
        link.click();
        this.spinner.hide();
        this.alert.success(` Download realizado! \n 2° Via da parcela ${payment.installment}`);
      },
      (err) => {
        this.spinner.hide();
        this.alert.error('Erro ao fazer download. Por favor, tente novamente mais tarde.');
      }
    );
  }

}
