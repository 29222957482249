import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User, AccessTokenResponse } from 'src/app/shared/service/User/user';
import { StorageService } from '../data/storage/storage.service';
import { TranslateService } from '@ngx-translate/core';
import {Router} from "@angular/router";

@Injectable({ providedIn: 'root' })
export class AuthService {
  private baseurl = `${environment.baseUrl}`;
  public user: User;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private storageService: StorageService,
    private http: HttpClient) { }

    getHeaders() {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `${environment.oath.basic}`,
          'Accept-Language': this.translateService.currentLang
        })
      };
    }

  /**
   * get OAuth token
   * doc https://www.oauth.com/oauth2-servers/access-tokens/access-token-response/
   * @param userData Content from user.ts model
   * @return user.ts with token or HttpErrorResponse
   */
  getToken(userData: User) {
    let grant_type = 'password';
    let username = userData.username;
    let password = userData.password;
    let body = `grant_type=${grant_type}&username=${username}&password=${password}`;

    return this.http.post(`${this.baseurl}/oauth/token`, body,
      this.getHeaders()).pipe(
        map((token: AccessTokenResponse) => {
          userData.token = token
          return userData;
        }),
        catchError((err: HttpErrorResponse) => throwError(err)),
      );
  }

  shouldRefresh(){
    const accessToken = this.loadAccessToken();

    if (accessToken) {
      const now = Math.trunc(new Date().getTime() / 1000);
      const time_delta = parseInt(accessToken.exp) - now;
      return time_delta <= 300;
    }

    return false;
  }

  doRefresh(){
    let grant_type = 'refresh_token';
    let refresh_token = this.loadRefreshToken();
    let body = `grant_type=${grant_type}&refresh_token=${refresh_token}`;

    return this.http.post(`${this.baseurl}/oauth/token`, body, this.getHeaders()).pipe(
      map((token: AccessTokenResponse) => {
        let userData = JSON.parse(localStorage.getItem(environment.storage.user));
        userData.token = token;
        return userData;
      }),
      catchError((err: HttpErrorResponse) => throwError(err)),
    );
  }

  loadAccessToken(){
    const user = JSON.parse(localStorage.getItem(environment.storage.user));

    if (user) {
      return JSON.parse(atob(user.token.access_token.split('.')[1]));
    }

    return undefined;
  }

  loadRefreshToken(){
    const user = JSON.parse(localStorage.getItem(environment.storage.user));

    if (user) {
      return user.token.refresh_token;
    }

    return undefined;
  }


  isTokenValid() {
    const access_token = this.loadAccessToken();

    if (access_token) {
      try {
        const now = Math.trunc(new Date().getTime() / 1000);
        const exp_date = parseInt(access_token.exp);

        if (exp_date >= now){
          return true;
        }

      } catch(e){
        return false;
      }
    }

    return false;
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(['']);
  }
}
