import { PersonalInformation } from "./personal-info.model";

export class CustomerUpdate {
	name?: string;
	mobile?: string;
	rg?: string;
	issuer?: string;
	address?: Address = new Address();
    personalInfo?: PersonalInformation = new PersonalInformation();
}

export class Address {
    street?: string;
    number?: any;
    complement?: string;
    neighborhood?: string;
    city?: string;
    state?: string;
    postalCode?: string;
}