import Swal, { SweetAlertResult } from 'sweetalert2';
import { Injectable } from '@angular/core';

@Injectable()
export class Alert {
	constructor() {}

	success(message: string) {
    const imgStyle = 'width: 40px; height: 40px;';

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        title: 'title-alert',
        popup: 'popup-base',
        header: 'header-alert',
        confirmButton: 'bt-blue-106',
        actions: 'action-alert',
        content: 'content-alert'
      },
    });

    return swalWithBootstrapButtons.fire({
      title: `<div><img src="../../assets/image/icons/Checkmark.svg" style="${imgStyle}"> `,
      text: message
    });
	}

	checkEmail(message: string) {
		var textStyle = 'text-align: center; color: #B7B7B7; font-size: 14px; width: 272px; margin: auto auto 32px auto; line-height: 21px;'
		var imgStyle = 'margin: auto auto 16px auto; width: 90px; height: 82px;'
		var titleStyle = 'font-size: 16px; font-weight: 500; color: #707070; margin: 16px auto 16px auto;'
		var divStyle = 'display: flex; flex-direction: column; width: 336px; margin: auto;'
		return Swal.fire({
			html: `<div style="${divStyle}"><img src="../../assets/image/icons/new-email.svg" style="${imgStyle}"> ` +
			`<span style="${titleStyle}">Agora é só abrir seu e-mail</span> ` +
			`<span style="${textStyle}">${message}</span></div>`,
			confirmButtonColor: '#00095B',
			confirmButtonText: 'OK',
			showCancelButton: false,
			allowOutsideClick: true,
		});
	}


	error(message: string) {
		return Swal.fire(
			`<span style=\'font-size: 16px; color: rgba(0,0,0,.64)\'>${message}</span>`,
			'',
			'error',
		);
	}

	warning(message: string) {
    const imgStyle = 'width: 40px; height: 40px;';

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        title: 'title-alert',
        popup: 'popup-base',
        header: 'header-alert',
        confirmButton: 'bt-blue-106',
        actions: 'action-alert',
        content: 'content-alert'
      },
    });

    return swalWithBootstrapButtons.fire({
      title: `<div><img src="../../assets/image/icons/safety.svg" style="${imgStyle}"> `,
      text: message
    });
	}


	question(message: string) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        popup: 'popup-base',
        container: 'content-question',
        confirmButton: 'bt-white',
        cancelButton: 'bt-blue',
        actions: 'action-question',
        content: 'content-question'
      }
    });

		return swalWithBootstrapButtons.fire({
      html: `<p>${message}</p>`,
			confirmButtonText: 'Sim',
			cancelButtonText: 'Não',
			showCancelButton: true,
			allowOutsideClick: true,
      reverseButtons: true
		});
	}

	multipleErrors(errors: Array<string>) {
		let html: string = '';

		errors.forEach(err => {
			html = `${html} <h4 style='font-family: FordAntenna; font-size: 14px; color: #d63031;' align='left'>${err}</h4>`;
		});

		html = `<div style='max-height: 400px; overflow-y: auto;'>${html}</div>`;

		return Swal.fire({
			title: `<h2 style='font-family: FordAntenna; font-size: 16px; color: #707070;'>
				Foram identificados os seguintes erros:</h2>`,
			icon: 'error',
			html,
			showCancelButton: true,
			showConfirmButton: false,
			cancelButtonText: 'Fechar',
			cancelButtonAriaLabel: 'close message'
		});
	}

	private createToastrMessage() {
		return Swal.mixin({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			showCancelButton: false,
			timer: 5000,
			timerProgressBar: true,
			inputAutoTrim: true,

			customClass: {
				title: 'text-left',
			},
			onOpen: (toast) => {
				toast.addEventListener('mouseenter', Swal.stopTimer);
				toast.addEventListener('mouseleave', Swal.resumeTimer);
			}
		});
  	}

	errorToastr(message: string): Promise<SweetAlertResult> {
		const toastr = this.createToastrMessage();

		return toastr.fire({
			icon: 'error',
			padding: '30px',
			title: message,
		});
	}

	close() {
		return Swal.close();
	}

	cancel() {
		return Swal.clickCancel();
	}
}
