type FinesAgreementStatus = 'PENDING' | 'APPROVED' | 'REFUSED' | null;

export class Driver {
  constructor(
    public id?: number,
    public cnh?: number,
    public cpf?: string,
    public validityCnh?: Date | string,
    public cnhValidityStatus?: string,
    public name?: string,
    public motherName?: string,
    public birthday?: string,
    public finesAgreement?: FinesAgreementStatus,
    public mainDriver?: boolean,
    public rg?: string,
    public issuer?: string
  ) {}

  static fromJson(jsonData: any): Driver {
    const element = {};

    for (const [key, value] of Object.entries(jsonData)) {
      if (typeof(value) === 'string') {
        Object.assign(element, { [key]: String(value).trim() });
      } else {
        Object.assign(element, { [key]: value });
      }
    }

    return element;
  }

  static fromJsonArr(jsonData: Array<any>): Array<Driver> {
    const elementArr: Array<Driver> = [];

    for (const subscription of jsonData as Array<any>) {
      elementArr.push(Driver.fromJson(subscription));
    }

    return elementArr;
  }
}
