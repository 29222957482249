import { Type } from "src/app/pages/dashboard/dashboard-summary/component/filter-summary/filter-summary.component";

export type payment = 'CREDIT_CARD' | 'BOLETO' | string;

export class Summary {
  constructor(
    public code?: string,
    public date?: string,
    public type?: {
      type?: string,
      description?: string,
    },
    public status?: {
      status?: string,
      description?: string,
    },
    public amount?: string,
    public paymentMethod?: string,
    public description?: string,
    public boletoUrl?: string,
		public charged?: boolean,
		public tid?: string,
		public mainTransaction?: boolean
  ) { }

  get dayMonthList() {
    const days = String(this.date).split('/')[0];
    const months = String(this.date).split('/')[1];
    

    switch (months) {
      case '01':
        return `${days}/Jan`;
      case '02':
        return `${days}/Fev`;
      case '03':
        return `${days}/Mar`;
      case '04':
        return `${days}/Abr`;
      case '05':
        return `${days}/Mai`;
      case '06':
        return `${days}/Jun`;
      case '07':
        return `${days}/Jul`;
      case '08':
        return `${days}/Ago`;
      case '09':
        return `${days}Set`;
      case '10':
        return `${days}/Out`;
      case '11':
        return `${days}/Nov`;
      case '12':
        return `${days}/Dez`;
    }
  }


  //Adiantamento da função do filtro de busca por período
  get mounthList() {
    const days = String(this.date).split('/')[0];
    const months = String(this.date).split('/')[1];
    const yearHour = String(this.date).split('/')[2];
    const year =  String(yearHour).split(' ')[0];

    switch (months) {
      case '01':
        return `Jan/${year}`;
      case '02':
        return `Fev/${year}`;
      case '03':
        return `Mar/${year}`;
      case '04':
        return `Abr/${year}`;
      case '05':
        return `Mai/${year}`;
      case '06':
        return `Jun/${year}`;
      case '07':
        return `Jul/${year}`;
      case '08':
        return `Ago/${year}`;
      case '09':
        return `Set/${year}`;
      case '10':
        return `Out/${year}`;
      case '11':
        return `Nov/${year}`;
      case '12':
        return `Dez/${year}`;
    }
  }

  get paymentList() {
    return {
      CREDIT_CARD: 'CREDIT_CARD',
      BOLETO: 'BOLETO',
      UNDEFINED:','
    }
  }

  get statusList(){
    return {
      PAID: 'PAID',
      WAITING_PAYMENT: 'WAITING_PAYMENT',
      REFUSED: 'REFUSED',
      PENDING_SLIP: 'PENDING_SLIP',
      REFUNDED:'REFUNDED', 
      PENDING_REFUND:'PENDING_REFUND',
      AUTHORIZED:'AUTHORIZED',
      PROCESSING:'PROCESSING',
      CHARGEDBACK: 'CHARGEDBACK', 
      UNDEFINED:'UNDEFINED'
    
    }
  } 
  get typeList(){
    return {
      SUBSCRIPTION:'SUBSCRIPTION',
    	FIRST_TRANSACTION:'FIRST_TRANSACTION',
	    INFORMATIVE:'INFORMATIVE',
      ADDITIONAL_KM:'ADDITIONAL_KM',
      FINES:'FINES',
      CANCELLATION_FINE:'CANCELLATION_FINE',
      EXTRA:'EXTRA',
      ACCESSORIES:'ACCESSORIES',
      CHARGEDBACK:'CHARGEDBACK',
      MONTHLY_CHARGE:'MONTHLY_CHARGE',
      MAINTENANCE:'MAINTENANCE'
    }
  }

  static fromJson(jsonData: any) {
    const element = {};

    for (const [key, value] of Object.entries(jsonData)) {
      if (typeof (value) === 'string') {
        Object.assign(element, { [key]: String(value).trim() });
      } else {
        Object.assign(element, { [key]: value });
      }
    }

    return Object.assign(new Summary(), element);
  }

  static fromJsonArr(jsonData: Array<any>) {
    return jsonData.map(json => Summary.fromJson(json));
  }


}
export class SummaryFilter {
	constructor(
		private _data?: Summary,
		private date?: string,
		private type?: Type,
		private status?: string,
	) {}

	get filterPropertys() {
		return {
			date: 'date',
			type: 'type',
			status: 'status',
		}
	};
	static fromSummary(summary: Summary) {
		const element = {};

		Object.assign(element, { _data: summary });
		Object.assign(element, { date: summary.date });
		Object.assign(element, { type: summary.type.type });

		if (summary.status) {
			Object.assign(element, { status: summary.status.status });
		} else {
			Object.assign(element, { status: null });
		}

		if (summary.paymentMethod === summary.paymentList.BOLETO && summary.status.status === summary.statusList.WAITING_PAYMENT ) {
			(element as any).status = 'pendingSlip';
		}
        

		return Object.assign(new SummaryFilter(), element);
	}
 
}


