import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from "@angular/router";
import {ApplicationService} from "../../../core/services/application.service";
import {Injectable} from "@angular/core";
import {AuthService} from "../../../shared/service/Auth/auth.service";

@Injectable()
export class GuardFleet implements CanActivate{

  constructor(private authService: AuthService, private applicationService: ApplicationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isTokenValid()) {
      if(this.applicationService.getUserFleet() === 'true') {
        return true;
      }else{
        window.location.replace('/dashboard');
      }

    }else {
      this.applicationService.clearApplication();
      window.location.replace('/');
    }
  }
}
