import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {ContractorRequest, RegistryLicensedRequest, SendImageReq} from './dashboard-service.interface';
import {User} from '../../../shared/service/User/user';
import {ApplicationService} from '../../../core/services/application.service';
import {UploadFile} from "../../../shared/components/upload/upload.interface";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private baseUrl = `${environment.baseUrl}`;


  constructor(
    private http: HttpClient,
    private applicationService: ApplicationService,
  ) { }


  public setItem(key: string, value: string): void {
    <any>window.localStorage.setItem(key, value);
  }

  public getItem(key: string): any {
    return <any>window.localStorage.getItem(key);
  }

  sendImage(req: SendImageReq): Observable<any> {
    const user = this.applicationService.getUserLogged();
    const url = `${this.baseUrl}/customer/${user.token.uuid}/document/image`;

    return this.http.post(url, { cnhComplete: req.cnhComplete, faceMatch: req.faceMatch }).pipe(
      map((response: any) => response),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }

  sendImageContractor(req: ContractorRequest): Observable<any> {
    const user = this.applicationService.getUserLogged();
    const url = `${this.baseUrl}/customer/${user.token.uuid}/document/image/contractor`;

    return this.http.post(url, req).pipe(
      map((response: any) => response),
      catchError((err: HttpErrorResponse) => throwError(err)),
    );
  }

  sendFilesRegistryLicensed(req: Array<UploadFile>): Observable<any> {
    const user = this.applicationService.getUserLogged();
    const url = `${this.baseUrl}/customer/${user.token.uuid}/document/registrylicensed`;

    return this.http.post(url, req).pipe(
      map((response: any) => response),
      catchError((err: HttpErrorResponse) => throwError(err)),
    );
  }

  updateMainDriver(req: SendImageReq, isCnpj: boolean, name: string, cpf: string, isMainDriver: boolean): Observable<any> {
    const sub = this.applicationService.getActiveSubscription();
    const user = this.applicationService.getUserLogged();
    var url;

    if (isMainDriver && !isCnpj) {
      url = `${this.baseUrl}/customer/${user.token.uuid}/document/image`;

      return this.http.put(url, { cnhComplete: req.cnhComplete, faceMatch: req.faceMatch }).pipe(
        map((response: any) => response),
        catchError((err: HttpErrorResponse) => throwError(err)),
      );
    }
    else {
      url = `${this.baseUrl}/customer/${user.token.uuid}/subscription/${sub.uuid}/driver`;

      return this.http.post(url, {name: name, cpf: cpf,  driverImages: { cnhComplete: req.cnhComplete, faceMatch: req.faceMatch }}).pipe(
        map((response: any) => response),
        catchError((err: HttpErrorResponse) => throwError(err)),
      );
    }
  }

  getSubscriptions(): any {

    const user = (<User>JSON.parse(this.getItem("user")));

    const httpRequestOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic ' + user.token.access_token,
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    return this.http.get(`${this.baseUrl}/customer/${user.token.uuid}/subscriptions`,
      httpRequestOptions)
      .pipe(
        map((response: Object) => {
          this.setItem("subscriptions", JSON.stringify(response));
          return response;
        }),
        catchError((err: HttpErrorResponse) => throwError(err)),
      );
  }

}



