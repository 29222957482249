import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ForgotPasswordComponent} from './pages/forgot-password/forgot-password.component';
import {LoginComponent} from './pages/login/login.component';
import {NewPasswordsComponent} from './pages/new-passwords/new-passwords.component';

import {AuthGuard} from './core/oauth/auth.guard';
import {NewCustomerComponent} from './pages/new-customer/new-customer.component';
import {UsersComponent} from "./pages/users/users.component";

const routes: Routes = [
	{
		path: '',
		component: LoginComponent,
	},
	{
		path: 'forgot-password',
		component: ForgotPasswordComponent,
	},
	{
		path: 'new-password',
		component: NewPasswordsComponent,
	},
	{
		path: 'new-customer',
		component: NewCustomerComponent,
	},
	{
		path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(d => d.DashboardModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'users',
		component: UsersComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'fleet',
		loadChildren: () => import('./pages/dashboard-fleet/dashboard-fleet.module').then(m => m.DashboardFleetModule)
	},
	{ path: '**', redirectTo: '/'}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
	providers: []
})
export class AppRoutingModule {
}
