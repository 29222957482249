import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Observable, throwError} from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/shared/service/User/user';
import { ApplicationService } from 'src/app/core/services/application.service';
import {AdditionalUserModel} from "../../../pages/users/additional-user.model";
import { PersonalInformation } from '../../models/personal-info.model';
import { CustomerUpdate } from '../../models/customer-update.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private baseurl = `${environment.baseUrl}`;

  constructor(
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private translateService: TranslateService,
    private http: HttpClient,
  ) { }

  /**
   * Steps about set new password
   */
  setPassword(user: any): any {

    let body, httpMethod;
    const tokenQueryParam = this.route.snapshot.queryParamMap.get('token');
    if (tokenQueryParam) {
      body = { token: tokenQueryParam, password: user.newPassword };
      httpMethod = 'PUT';
    } else {
      body = { email: user.username };
      httpMethod = 'POST';
    }

    const httpRequestOptions = {
      reportProgress: true,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.translateService.currentLang
      })
    };

    const httpRequest = new HttpRequest(httpMethod, `${this.baseurl}/reset/password/forget`, body, httpRequestOptions);
    return this.http.request(httpRequest)
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            return event;
          }
        }),
        catchError((err: HttpErrorResponse) => throwError(err)),
      );
  }

  newPassword(user: any): any {
    let body, httpMethod;
    const tokenQueryParam = this.route.snapshot.queryParamMap.get('token');
    if (tokenQueryParam) {
      body = { token: tokenQueryParam, password: user.newPassword };
      httpMethod = 'PUT';
    } else {
      body = { email: user.username };
      httpMethod = 'POST';
    }

    const httpRequestOptions = {
      reportProgress: true,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.translateService.currentLang
      })
    };

    const httpRequest = new HttpRequest(httpMethod, `${this.baseurl}/reset/password/new`, body, httpRequestOptions);
    return this.http.request(httpRequest)
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            return event;
          }
        }),
        catchError((err: HttpErrorResponse) => throwError(err)),
      );
  }

  setInfo(data: CustomerUpdate): any {
    const httpRequestOptions = {
      reportProgress: false,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.translateService.currentLang
      })
    };

    const user: User = this.applicationService.getUserLogged();

    return this.http.put(`${this.baseurl}/customer/${user.token.uuid}`, data, httpRequestOptions)
      .pipe(
        map((response: Object) => {
          return response;
        }),
        catchError((err: HttpErrorResponse) => throwError(err)),
      );
  }

  getInfo(): any {
    const httpRequestOptions = {
      reportProgress: false,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.translateService.currentLang
      })
    };

    const user: User = this.applicationService.getUserLogged();

    return this.http.get(`${this.baseurl}/customer/${user.token.uuid}`, httpRequestOptions)
      .pipe(
        map((userInfo: Object) => {
          user.info = userInfo;
          this.applicationService.setUserLogged(user);
          return user;
        }),
        catchError((err: HttpErrorResponse) => throwError(err)),
      );
  }

  getPersonalInformation(): Observable<PersonalInformation> {
    const httpRequestOptions = {
      reportProgress: false,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.translateService.currentLang
      })
    };

    const user: User = this.applicationService.getUserLogged();

		return this.http.get(`${this.baseurl}/customer/${user.token.uuid}/personal-information`, httpRequestOptions).pipe(
			map((response: any) => response),
			catchError((err: HttpErrorResponse) => throwError(err)),
		);
	}

  getAllUsers(): Observable<AdditionalUserModel[]>{
    const user: User = this.applicationService.getUserLogged();
    return this.http.get<AdditionalUserModel[]>(`${this.baseurl}/customer/${user.token.uuid}/users`);
  }

  saveAdditionalUser(additionalUser: AdditionalUserModel): Observable<any>{
    const user: User = this.applicationService.getUserLogged();
    const url = `${this.baseurl}/customer/${user.token.uuid}/additional-user`;
    return this.http.post(url, additionalUser);
  }

  deleteAdditionalUser(customerId: string): Observable<any>{
    const user: User = this.applicationService.getUserLogged();
    const param = new HttpParams().set('customerId', customerId);
    return this.http.delete(`${this.baseurl}/customer/${user.token.uuid}/additional-user`, {params: param});
  }

}
