import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SubscriptionNavbarComponent } from './components/subscription-navbar/subscription-navbar.component';
import { InputFordComponent } from './components/input-ford/input-ford.component';
import { BtnFordComponent } from './components/btn-ford/ford-btn.component';
import { FooterComponent } from './components/footer/footer.component';
import { ValidateFormInputPipe, ValidateFormInputFeedbackPipe, validateFormInputFeedbackCreditCard } from './pipes/form.pipe';
import { UploadComponent } from './components/upload/upload.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';

import { DragAndDropDirective } from './directive/drag-and-drop.directive';

export function HttpLoaderFactory(httpClient: HttpClient) {
	return new TranslateHttpLoader(httpClient);
}

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { UploadDocComponent } from './components/upload-doc/upload-doc.component';
import { UploadUpdateComponent } from './components/upload-update/upload-update.component';
import { CPFMaskDirective } from './components/upload-doc/cpf-mask.directive';
import { CredcardComponent } from './components/credcard/credcard.component';
import { NavMobileComponent } from './components/nav-mobile/nav-mobile.component';
import { CreditCardExpireDate, CreditCardNumberCard, CreditCardCvv } from '../shared/pipes/creditcard.pipe';
import { PostalCodePipe } from './pipes/postalCode.pipe';
import { ErrorHandler } from './class/ErrorHandler';
import { FirstLetterCasePipe } from './pipes/firstlettercase.pipe';
import { AlertCnhComponent } from './components/alert-cnh/alert-cnh.component';
import {MessagesModule} from "primeng/messages";
import {AccordionStatusComponent} from "./components/accordion-status/accordion-status.component";
import {PaymentHistoryComponent} from "./components/payment-history/payment-history.component";
import { TelPipe } from './pipes/mobile.pipe';
import { KilometersPercentageComponent } from './components/kilometers-percentage/kilometers-percentage.component';
import { DatesComponent } from './components/dates/dates.component';
import { StatusFleetComponent } from './components/status-fleet/status-fleet.component';
import { PlateComponent } from './components/plate/plate.component';
import { AccordionModule } from 'primeng/accordion';
import { ProgressBarModule } from 'primeng/progressbar';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
	declarations: [
		SubscriptionNavbarComponent,
		InputFordComponent,
		BtnFordComponent,
		FooterComponent,
		ValidateFormInputPipe,
		ValidateFormInputFeedbackPipe,
		UploadDocComponent,
		UploadUpdateComponent,
		CPFMaskDirective,
		UploadComponent,
		DragAndDropDirective,
		CredcardComponent,
		BreadcrumbComponent,
		NavMobileComponent,
		CreditCardExpireDate,
		CreditCardNumberCard,
		CreditCardCvv,
		validateFormInputFeedbackCreditCard,
		PostalCodePipe,
		FirstLetterCasePipe,
		AlertCnhComponent,
		AccordionStatusComponent,
		PaymentHistoryComponent, 
		TelPipe,
    KilometersPercentageComponent,
    DatesComponent,
    StatusFleetComponent,
    PlateComponent
	],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    NgbModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      }
    }),
    MessagesModule,
    AccordionModule,
    ProgressBarModule,
    CalendarModule,
  ],
	exports: [
		MatAutocompleteModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatDatepickerModule,
		MatDialogModule,
		MatExpansionModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatSelectModule,
		MatSidenavModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatSortModule,
		MatTableModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		MatStepperModule,
		SubscriptionNavbarComponent,
		InputFordComponent,
		BtnFordComponent,
		FooterComponent,
		TranslateModule,
		ValidateFormInputPipe,
		ValidateFormInputFeedbackPipe,
		NgbModule,
		UploadComponent,
		DragAndDropDirective,
		BreadcrumbComponent,
		NavMobileComponent,
		CreditCardExpireDate,
		CreditCardNumberCard,
		CreditCardCvv,
		validateFormInputFeedbackCreditCard,
		PostalCodePipe,
		FirstLetterCasePipe,
		AlertCnhComponent,
		CPFMaskDirective,
		AccordionStatusComponent,
		PaymentHistoryComponent, 
		TelPipe,
    KilometersPercentageComponent,
    DatesComponent,
    StatusFleetComponent,
    PlateComponent
	],
	providers: [
		CreditCardExpireDate,
		CreditCardNumberCard,
		CreditCardCvv,
		validateFormInputFeedbackCreditCard,
		ErrorHandler,
	]
})
export class SharedModule { }
