import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DriveService {
    private subject = new Subject();
    private subjectResponse = new Subject();
    private subjectUser = new Subject();

    sendChangeStatus(status: boolean) {
        this.subject.next({ status: status});
    }

    sendPermissionModal(permission: boolean) {
        this.subjectResponse.next({ permission: permission})
    }

    clearStatus() {
        this.subject.next();
        this.subjectResponse.next();
        this.subjectUser.next();
    }

    getChangeStatus(): Observable<any> {
        return this.subject.asObservable();
    }

    getPermissionModal(): Observable<any> {
        return this.subjectResponse.asObservable();
    }

    sendUpdateUser(event: boolean) {
        this.subjectUser.next({ event: event});
    }

    getUpdateUser(): Observable<any> {
        return this.subjectUser.asObservable();
    }
    
}