import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {NgxSpinnerModule} from 'ngx-spinner';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ForgotPasswordComponent} from './pages/forgot-password/forgot-password.component';
import {LoginComponent} from './pages/login/login.component';
import {NewPasswordsComponent} from './pages/new-passwords/new-passwords.component';
import {Alert} from './shared/class/alert';
import {AuthService} from './shared/service/Auth/auth.service';
import {HttpErrorHandleService} from './shared/service/data/http-error-handle/http-error-handle.service';
import {UserService} from './shared/service/User/user.service';
import {SharedModule} from './shared/shared.module';
import {SubscriptionService} from './shared/service/subscription.service';
import {StorageService} from './shared/service/data/storage/storage.service';
import {DashboardModule} from './pages/dashboard/dashboard.module';
import {CoreModule} from './core/core.module';
import {CancelContractComponent} from './shared/class/cancel-contract/cancel-contract.component';
import {NewCustomerComponent} from './pages/new-customer/new-customer.component';
import {NavMobileService} from './shared/components/nav-mobile/nav-mobile.service';
import {Ng2PicaModule} from 'ng2-pica';
import {FineService} from './shared/service/fine.service';
import {DashboardFleetModule} from "./pages/dashboard-fleet/dashboard-fleet.module";
import { MenubarModule } from 'primeng/menubar';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { PanelMenuModule } from 'primeng/panelmenu';
import { CardModule } from 'primeng/card';
import {UsersModule} from "./pages/users/users.module";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    NewPasswordsComponent,
    NewCustomerComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    MenubarModule,
    SidebarModule,
    ButtonModule,
    PanelMenuModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSpinnerModule,
    CommonModule,
    CardModule,
    DashboardModule,
    CoreModule,
    Ng2PicaModule,
    DashboardFleetModule,
    UsersModule
  ],
  providers: [
    Alert,
    StorageService,
    AuthService,
    UserService,
    SubscriptionService,
    HttpErrorHandleService,
    CancelContractComponent,
    NavMobileService,
    FineService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
