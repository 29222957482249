import {Component, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {AdditionalUserComponent} from "./additional-user/additional-user.component";
import {UserService} from "../../shared/service/User/user.service";
import {AdditionalUserModel, getTypeUser} from "./additional-user.model";
import {NgxSpinnerService} from "ngx-spinner";
import {Alert} from "../../shared/class/alert";


@Component({
  selector: 'users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  displayedColumns: string[] = ['name', 'email', 'type', 'date', 'actions'];
  users: AdditionalUserModel[] = [];
  form: FormGroup;

  constructor(public dialog: MatDialog, private userService: UserService, private spinner: NgxSpinnerService, private alert: Alert) {
  }

  ngOnInit(): void {
    this.getAllUsers();
  }

  openDialog() {
    const dialog = this.dialog.open(AdditionalUserComponent, {disableClose: true, width: '473px'});
    dialog.afterClosed().subscribe(af => {
      if(af) this.getAllUsers();
    });
  }

  getAllUsers() {
    this.spinner.show();
    this.userService.getAllUsers().subscribe(item => {
        this.users = item;
        this.spinner.hide();
      },
      error => {
        this.alert.warning('Ocorreu um erro ao listar os usuários!');
      });
  }

  edit(additionalUserModel: AdditionalUserModel) {
    const dialog = this.dialog.open(AdditionalUserComponent, {data: {user: additionalUserModel}, disableClose: true, width: '473px'});
    dialog.afterClosed().subscribe(af => {
      if(af) this.getAllUsers();
    });
  }

  async delete(customerId: number) {
    const question = await this.alert.question('Tem certeza deseja deletar esse usuário? <br>  Essa ação irá anular o processo feito até agora.');
    if (question.dismiss) return;

    this.spinner.show();
    this.userService.deleteAdditionalUser(String(customerId)).subscribe(del => {
        this.alert.success('Usuário excluido com sucesso');
        this.getAllUsers();
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        this.alert.warning('Erro: ação não permitida!');
      });
  }

  getType(type: string): string {
    return getTypeUser(type);
  }
}
