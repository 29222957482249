import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'creditCardExpireDate', pure: false })
export class CreditCardExpireDate implements PipeTransform {
    transform(date: string) {
        let sanitizeValue = String(date).replace(/\/|/g, '').trimLeft().trimRight().substr(0, 4);
        return this.sanitizeDate(sanitizeValue);
    }

    sanitizeDate(date: string) {
        date = date.replace(/\D/g, '');
        switch (date.length) {
            case 0:
            case 1:
            case 2:
                return date;
            case 3:
                return date.replace(/(\d+)(\d{1})/, '$1/$2');
            case 4:
                return date.replace(/(\d+)(\d{2})/, '$1/$2');
            default:
                return date;
        }
    }
}


@Pipe({ name: 'creditCardNumberCard', pure: false })
export class CreditCardNumberCard implements PipeTransform {
    transform(num: string) {
        let sanitizeValue = String(num).trimLeft().trimRight().substr(0,19) ;
        return this.sanitizeNumber(sanitizeValue);
    }

    sanitizeNumber(num: string) {
        num = num.replace(/\D/g, '');
        num = num.replace(/(\d{4})/g, '$1 ');
        return num
    }
}

@Pipe({ name: 'creditCardCvv', pure: false })
export class CreditCardCvv implements PipeTransform {
    transform(cvv: string) {
        return String(cvv).replace(/\D/g, '').trimLeft().trimRight().substr(0,4);
    }
}