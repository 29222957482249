export class Filter<DataType, FilterType, OutputData> {
	constructor (private data: Array<DataType>, private filters: FilterType, private exactSearch: Array<string>) {}

	process(): Array<OutputData> {
		const collection: Array<OutputData> = [];

		for (const register of this.data) {
			let match = 0;

			for (const [filterProperty, filterValue] of Object.entries(this.filters)) {
				if (this.search(register[filterProperty], filterValue, filterProperty)) {
					match++;
				}
			}

			if (match === Object.keys(this.filters).length) {
				collection.push(register['_data']);
			}
		}

		return collection;
	}

	private search(valueSearch: string, filterValue: string, field: string): boolean {
		const search = String(valueSearch).toLowerCase().search(String(filterValue).toLowerCase());


		if (this.exactSearch.includes(field) && search === 0) {
			return true;
		} else if (!this.exactSearch.includes(field) && search >= 0) {
			return true;
		}

		return false;
	}

	static sanitizeValidFilters(fields: any) {
		const element = {};

		for (const [key, value] of Object.entries(fields)) {
			if (value !== null) {
				Object.assign(element, { [key]: value });
			}
		}

		return element;
	}
}
