import { NgModule } from '@angular/core';
import { AuthGuard } from './oauth/auth.guard';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ApplicationService } from './services/application.service';

@NgModule({
  declarations: [],
  exports: [
    AuthInterceptor,
  ],
  providers: [
    AuthGuard,
    ApplicationService,
  ],
})
export class CoreModule {}
