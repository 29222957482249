import {Component, OnInit} from '@angular/core';
import {NavMobileService} from '../nav-mobile/nav-mobile.service';
import {ApplicationService} from '../../../core/services/application.service';
import {AuthService} from "../../service/Auth/auth.service";
import {MenuItem} from "primeng/api";
import {UserService} from "../../service/User/user.service";
import {User} from "../../service/User/user";
import {HttpErrorResponse} from "@angular/common/http";
import { DriveService } from '../../service/drive.service';

@Component({
  selector: 'subscription-navbar',
  templateUrl: './subscription-navbar.component.html',
  styleUrls: ['./subscription-navbar.component.scss'],
})
export class SubscriptionNavbarComponent implements OnInit {

  isMobile = false;
  showFiller = false;
  items: MenuItem[];
  user: User;

  constructor(
    public navMobile: NavMobileService,
    private authService: AuthService,
    public aplicationService: ApplicationService,
    private userService: UserService,
    private driveService: DriveService
  ) {
  }

  ngOnInit() {
    this.getUser();

    this.driveService.getUpdateUser().subscribe(res => {
      if(res) {
        this.getUser();
      }
    })
  }

  getUser(){
   this.userService.getInfo().subscribe(
     (user: User) => {
          this.user = user;
        },
        (err: HttpErrorResponse) => {
        }
      );
  }

  logout() {
    this.authService.logout();
  }
}
