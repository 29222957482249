import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {ApplicationService} from "../../../core/services/application.service";
import {Alert} from "../../../shared/class/alert";
import {AuthService} from "../../../shared/service/Auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class DriverGuard implements CanActivate {

  constructor(private applicationService: ApplicationService, private authService: AuthService, private alert: Alert) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const user =  this.applicationService.getUserLogged();

    if(this.authService.isTokenValid() && user.info.address !== null){
      return true;
    }

    this.alert.warning('Você não tem permissão para acessar: Meus Dados');
    return false;
  }

}
