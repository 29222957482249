import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

  public setItem(key: string, value: string): void {
    <any>window.localStorage.setItem(key, value);
  }

  public getItem(key: string): string {
    return <any>window.localStorage.getItem(key);
  }

  public setJSONItem(key: string, value: any): void {
    this.setItem(key, JSON.stringify(value));
  }

  public getJSONItem(key: string): any {
    return JSON.parse(this.getItem(key));
  }

  public removeItem(key: string): void {
    <any>window.localStorage.removeItem(key);
  }

}