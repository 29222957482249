import {Component, OnInit} from '@angular/core';
import {SubscriptionService} from "../../../shared/service/subscription.service";
import {NgxSpinnerService} from "ngx-spinner";
import {FleetStatus, SubscriptionDetailFleet} from "../../../shared/models/subscription-detail.model";
import {Router} from "@angular/router";
import {ApplicationService} from "../../../core/services/application.service";
import {Subscription} from "../../../shared/models/subscription.model";
import { FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {SelectItem} from "primeng/api";

@Component({
  selector: 'home-fleet',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss', '../shared/table.scss']
})
export class HomeComponent implements OnInit {
  constructor(private subscriptionService: SubscriptionService, public spinner: NgxSpinnerService, public applicationService: ApplicationService, public router: Router, private formBuilder: FormBuilder) {
  }

  public originSubscriptions = new Array<SubscriptionDetailFleet>();
  public subscriptionsDetail: Array<SubscriptionDetailFleet>;
  public filterDetail: Array<SubscriptionDetailFleet>;

  formDate: FormGroup;
  statusList: SelectItem[];
  selectStatus: SelectItem[] = [];

  status: any;
  br: any;
  currentQ: string = '';
  timer: any;

  ngOnInit(): void {
    this.getSubscriptions();
    this.getMonths();
    this.getFormDate();
    this.getFormStatus();
  }

  getSubscriptions() {
    this.spinner.show();
    this.subscriptionService.findAllFleet().subscribe(result => {
      this.spinner.hide();
      this.subscriptionsDetail = result;
      this.filterDetail = this.subscriptionsDetail;
      this.applicationService.setSubscriptions(this.filterDetail);
    });
  }

  getFormDate() {
    this.formDate = this.formBuilder.group({
      start: [false as boolean],
      startDate: new FormControl({value: '', disabled: true}),
      end: [false as boolean],
      endDate: new FormControl({value: '', disabled: true})
    });

    this.formDate.get('start').valueChanges.subscribe(value => {
      if (value === true) {
        this.formDate.get('startDate').enable();
      } else {
        this.formDate.get('startDate').disable();
      }
    });

    this.formDate.get('end').valueChanges.subscribe(value => {
      if (value === true) {
        this.formDate.get('endDate').enable();
      } else {
        this.formDate.get('endDate').disable();
      }
    });
  }

  getFormStatus() {
    this.statusList = [
      {label: 'Pagamento', value: FleetStatus.PAYMENT},
      {label: 'Em análise', value: FleetStatus.PENDING_ANALYSE},
      {label: 'Aprovado', value: FleetStatus.APPROVED},
      {label: 'Veículo nacionalizado', value: FleetStatus.VEHICLE_PRODUCED},
      {label: 'Processo de emplacamento', value: FleetStatus.LICENSE_PLATE},
      {label: 'Transporte p/ concessionária', value: FleetStatus.TRANSPORT},
      {label: 'Agendamento da entrega', value: FleetStatus.DELIVERY_SCHEDULE},
      {label: 'Veículo entregue', value: FleetStatus.VEHICLE_DELIVERED},
      {label: 'Conectado', value: FleetStatus.CONNECTED},
      {label: 'Desconectado', value: FleetStatus.DISCONNECTED},
      {label: 'Cancelado', value: FleetStatus.CANCELED},
      {label: 'Finalizado', value: FleetStatus.FINALIZED},
    ];
  }

  generalFilter(q: string) {
    this.spinner.show();
    this.filterDetail = this.subscriptionsDetail;

    if (this.formDate.get('start').value === true || this.formDate.get('end').value === true) {
      this.filterDate();
    }
    if (q != undefined && q.length != 0) {
      this.globalFilter(q);
    }else if(this.currentQ != undefined && this.currentQ.length != 0) this.globalFilter(this.currentQ);

    this.filterStatus();

    this.spinner.hide();
  }

  filterDate() {
    if (this.formDate.get('start').value === true || this.formDate.get('end').value === true) {
      const start = this.formDate.get('startDate').value as Date;
      const end = this.formDate.get('endDate').value as Date;

      this.filterDetail = this.filterDetail.filter(value => {
        const deliveryDate = value.plan.vehicle.deliveryDate !== null ? new Date(value.plan.vehicle.deliveryDate) : null;
        const terminated = value.terminated !== null ? new Date(value.terminated) : null;

        if ((this.formDate.get('start').value === true && this.formDate.get('end').value === true)) {
          if((deliveryDate >= start) && (terminated <= new Date(end.getFullYear(), end.getMonth() + 1, 0))) {
            return value;
          }
        } else if ((this.formDate.get('start').value)) {
          if (deliveryDate >= start) return value;
        } else if ((this.formDate.get('end').value)){
          if (terminated <= new Date(end.getFullYear(), end.getMonth() + 1, 0)) {return value;};
        }
      });
    }
  }

  clearFilterDate() {
    this.formDate.reset();
  }

  globalFilter(q: string) {
    if (q != undefined && q.length != 0) {
      this.filterDetail = this.filterDetail.filter(obj => {
        if(obj.plan.vehicle.model.toLowerCase().includes(q.toLowerCase())) return obj;
        if(obj.plan.vehicle.version.toLowerCase().includes(q.toLowerCase())) return obj;
        if(obj.plan.vehicle.plate !== null)  if(obj.plan.vehicle.plate.toLowerCase().includes(q.toLowerCase())) return obj;
      } );
    } else {
      this.filterDetail = this.subscriptionsDetail;
    }
  }

  liveSearch(q: string) {
    if (this.currentQ === q) {
      return;
    } else {
      this.currentQ = q;
    }

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.generalFilter(this.currentQ);
    }, 1000);
  }

  clearSearch(target: any) {
    target.value = '';
    this.currentQ = '';
    this.generalFilter(this.currentQ);
  }

  filterStatus() {
    let tempList = new Array<SubscriptionDetailFleet>();
    if (this.selectStatus.length > 0) {
      this.selectStatus.forEach(item => {
        this.filterDetail.forEach(ob => {
          if (FleetStatus[ob.status] === item.value) {tempList.push(ob);};
        });
      });
      this.filterDetail = tempList;
    }
  }

  clearFilterStatus() {
    this.selectStatus = [];
  }

  getMonths() {
    this.br = {
      monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Maio", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    };
  }

  detail(subscription: Subscription) {
    this.filterDetail = this.filterDetail.filter(value => value.uuid === subscription.uuid);
    this.applicationService.setSubscriptions(this.filterDetail);
    this.applicationService.setStatusFleet(this.getStatusFleet(this.filterDetail[0].status.toString()));
    this.router.navigate(['/dashboard']);
  }

  getStatusFleet(status: string): string{
    switch (FleetStatus[status]) {
      case FleetStatus.PAYMENT: {
        return 'Pagamento';
        break;
      }
      case FleetStatus.PENDING_ANALYSE: {
        return 'Em análise';
        break;
      }
      case FleetStatus.APPROVED: {
        return 'Aprovado';
        break;
      }
      case FleetStatus.VEHICLE_PRODUCED: {
        return 'Veículo produzido';
        break;
      }
      case FleetStatus.LICENSE_PLATE: {
        return 'Processo de emplacamento';
        break;
      }
      case FleetStatus.TRANSPORT: {
        return 'Transporte p/concessionária';
        break;
      }
      case FleetStatus.DELIVERY_SCHEDULE: {
        return 'Agendamento da entrega';
        break;
      }
      case FleetStatus.VEHICLE_DELIVERED: {
        return 'Veículo entregue';
        break;
      }
      case FleetStatus.CONNECTED: {
        return 'Conectado';
        break;
      }
      case FleetStatus.DISCONNECTED: {
        return 'Desconectado';
        break;
      }
      case FleetStatus.CANCELED: {
        return 'Cancelado';
        break;
      }
      case FleetStatus.FINALIZED: {
        return 'Finalizado';
        break;
      }
      case FleetStatus.WITHOUT: {
        return 'Sem status';
        break;
      }
    }
  }
}
