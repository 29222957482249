import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {Alert} from 'src/app/shared/class/alert';
import {DashboardService} from '../shared/dashboard.service';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {interval, Subscription, timer} from 'rxjs';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {SubscriptionService} from '../../../shared/service/subscription.service';
import {ApplicationService} from '../../../core/services/application.service';
import {mode as FileType, UploadFile} from '../../../shared/components/upload/upload.interface';
import {ErrorHandler} from '../../../shared/class/ErrorHandler';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-dashboard-contractor-upload',
  templateUrl: './dashboard-contractor-upload.component.html',
  styleUrls: ['./dashboard-contractor-upload.component.scss'],
  animations: [
    trigger('doc-submitted', [
      state('hide',
        style({display: 'none'})
      ),
      state('show',
        style({opacity: 1, display: '*'})
      ),
      transition('hide => show', [
        animate('200ms ease-in-out')
      ]),
      transition('show => hide', [
        animate('200ms ease-in-out')
      ])
    ]),

    trigger('uploadAnimation', [
      state('in',
        style({opacity: 1, display: '*'})
      ),
      state('out',
        style({opacity: 0, display: '*'})
      ),
      state('hide-loading',
        style({display: 'none'})
      ),
      transition('in <=> out', [
        animate('500ms ease-in-out')

      ])
    ]),

  ]
})
export class DashboardContractorUploadComponent implements OnInit {
  public uploadForm: FormGroup;
  public files: any[] = [];
  public allowExtensions = ['png', 'jpg', 'jpeg', 'pdf'];
  public filesCnh: Array<UploadFile> = [];
  public filesPhoto: Array<UploadFile> = [];

  _submitState: 'show' | 'hide';
  _uploadState: 'hide-loading' | 'in' | 'out';
  currentImage = '';

  private step1Img = '';
  private step2Img = '';
  private step3Img = '';
  private timer: Subscription;
  private animationInterval = 15000; //15s

  cnhCarousel = [
    {
      img1: 'assets/image/images/upload/cnh-bad-1.svg',
      img2: 'assets/image/images/upload/cnh-good-1.svg',
      text: 'Tire o documento da capinha'
    },
    {
      img1: 'assets/image/images/upload/cnh-bad-2.svg',
      img2: 'assets/image/images/upload/cnh-good-2.svg',
      text: 'Use um fundo uniforme com o documento aberto'
    }
  ];

  selfieCarousel = [
    {
      img1: 'assets/image/images/upload/selfie-bad-1.svg',
      img2: 'assets/image/images/upload/selfie-good-1.svg',
      text: 'Deixe o rosto iluminado'
    },
    {img1: 'assets/image/images/upload/selfie-bad-2.svg', img2: 'assets/image/images/upload/selfie-good-2.svg', text: 'Não use acessórios'}
  ];

  order = 0;

  constructor(
    private translate: TranslateService,
    private alert: Alert,
    private dashboardService: DashboardService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscriptionService: SubscriptionService,
    private applicationService: ApplicationService,
    private errorHandler: ErrorHandler,
    private formBuilder: FormBuilder,
  ) {
  }

  ngAfterViewInit() {
    const source = timer(0, 3000);
    source.subscribe(val => {
      this.order = this.order + 1;
      if (this.order > 1) {
        this.order = 0;
      }
    });
  }

  toggle() {
    if (this._submitState === 'hide') {
      this._submitState = 'show';
      this._uploadState = 'out';
      this.timer.unsubscribe();
      this.resetUpload();
      return;
    }

    this.timer = interval(this.animationInterval).subscribe((val) => {
      this.doTransition();
    });
    this._uploadState = 'in';
    this._submitState = 'hide';

  }

  fade($event) {
    if (!(this._uploadState === 'hide-loading')) {
      if (this.currentImage === this.step1Img && this._uploadState === 'out') {
        this.currentImage = this.step2Img;
        this._uploadState = 'in';
      } else if (this.currentImage === this.step2Img && this._uploadState === 'out') {
        this.currentImage = this.step3Img;
        this._uploadState = 'in';
      }
    }

  }

  resetUpload() {
    this.currentImage = this.step1Img;
    this._uploadState = 'hide-loading';
  }

  doTransition() {
    if (this.currentImage === this.step1Img || this.currentImage === this.step2Img) {
      this._uploadState = 'out';
    } else {
      this.timer.unsubscribe();
    }

  }

  ngOnInit(): void {
    this.translate.use('pt');

    this.step1Img = 'assets/image/images/loading/step1.png';
    this.step2Img = 'assets/image/images/loading/step2.png';
    this.step3Img = 'assets/image/images/loading/step3.png';

    this.currentImage = this.step1Img;
    this._uploadState = 'hide-loading';
    this.buildForm();
  }

  bindingFile(files: Array<UploadFile>, type: FileType) {
    if (type === 'cnh') {
      this.filesCnh = files;
    } else if (type === 'self') {
      this.filesPhoto = files;
    }
  }

  buildForm(): void {
    this.uploadForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      cpf: ['', [Validators.required, Validators.minLength(13)]],
    });
  }

  submit() {
    if (this.uploadForm.invalid) {
      return this.alert.warning('Os campos Nome e CPF são obrigatórios');
    }

    if (!this.filesCnh[0]) {
      return this.alert.warning('O envio da CNH é obrigatório');
    }

    if (!this.filesPhoto[0]) {
      return this.alert.warning('O envio da Foto é obrigatório');
    }

    return this.uploadFiles();
  }

  uploadFiles() {
    this.toggle();
    this.dashboardService.sendImageContractor({
      driverImages: {
        cnhComplete: this.filesCnh[0].base64,
        faceMatch: this.filesPhoto[0].base64,
      },
      name: this.uploadForm.get('name').value.trim(),
      cpf: String(this.uploadForm.get('cpf').value).replace(/\.|\,|\-/g, '').trimLeft().trimRight(),
    }).subscribe(
      () => this.loadSubscriptions(),
      (err: HttpErrorResponse) => {
        this.toggle();
        this.errorHandler.error(err, this.uploadFiles.bind(this));
      }
    );
  }

  loadSubscriptions() {
    this.subscriptionService.findAll().subscribe(
      (subscriptions) => {
        this.subscriptionService.isFleetOwner().subscribe(
          result => {
            if (result === true) {
              this.applicationService.setUserFleet('true');
              this.router.navigate(['/fleet']);
            }
            else {
              this.applicationService.setSubscriptions(subscriptions);
              this.toggle();
              this.router.navigate(['/dashboard']);
            }
          }
        );
      },
      (err: HttpErrorResponse) => {
        this.toggle();
        if (err.status === 400) {
          this.router.navigate(['/dashboard/contractor']);
        } else {
          this.applicationService.clearApplication();
          this.errorHandler.error(err, this.loadSubscriptions.bind(this));
        }
      }
    );
  }

  makeViewErrorsMessage(errors: Array<{ fieldName: string, message: string }>): string {
    let html = '';

    errors.forEach((err) =>
      html =
        `${html} <h4 style='color: #d63031; font-weight: 600; margin-left: 10px' align='left'>
				${err.message}
			</h4>`);

    return `<div style='max-height: 400px; overflow-y: auto;'>${html}</div>`;
  }

  carousel(order: number) {
    this.order = order;
  }
}
