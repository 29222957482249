import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubscriptionService } from '../../../shared/service/subscription.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Alert } from '../../../shared/class/alert';
import { ApplicationService } from '../../../core/services/application.service';
import { UploadFile } from '../../../shared/components/upload/upload.interface';
import { Driver } from 'src/app/shared/models/driver.model';
import { ErrorHandler } from '../../../shared/class/ErrorHandler';
import { SubscriptionDetail } from 'src/app/shared/models/subscription-detail.model';

@Component({
	selector: 'drivers-terms',
	templateUrl: './drivers-terms.component.html',
	styleUrls: ['./drivers-terms.component.scss'],

})
export class DriversTermsComponent implements OnInit {
	public files: any[] = [];
	public allowExtensions = ['pdf'];
	public filesTerms: Array<UploadFile> = [];
	public driver: Driver;
	public driverId: number;
	public subscription: SubscriptionDetail;

	constructor(
		private translate: TranslateService,
		private spinner: NgxSpinnerService,
		private subscriptionService: SubscriptionService,
		private alert: Alert,
		private router: Router,
		private errorHandler: ErrorHandler,
		public applicationService: ApplicationService,
		private route: ActivatedRoute,
	) { }

	ngOnInit(): void {
		this.translate.use('pt');
		this.driverId = parseInt(this.route.snapshot.params.driverId, 10);
		this.loadDriver();
		this.loadSubscriptionDetails();
	}

	bindingFile(files: Array<UploadFile>) {
		this.filesTerms = files;
	}

	loadDriver() {
		this.spinner.show();

		this.subscriptionService.drivers(this.applicationService.getActiveSubscription().uuid).subscribe(
			(response) => {
				this.driver = (response as Array<Driver>).filter(driver => driver.id == this.driverId)[0];
				this.spinner.hide();
			},
			(err: HttpErrorResponse) => {
				this.spinner.hide();

				if (err.status !== 401) {
					this.errorHandler.error(err, this.loadDriver.bind(this));
					this.router.navigate(['/dashboard/driveList']);
				} else {
					this.errorHandler.error(err, this.loadDriver.bind(this));
				}
			}
		)
	}

	loadSubscriptionDetails() {
		this.spinner.show();

		this.subscriptionService.detail(this.applicationService.getActiveSubscription().uuid).subscribe(
			(response) => {
				this.spinner.hide();
				this.subscription = response;
			},
			(err: HttpErrorResponse) => {
				this.spinner.hide();
				this.errorHandler.error(err, this.loadSubscriptionDetails.bind(this));
			}
		);
	}

	submit() {
		if (this.filesTerms.length > 0) {
			this.spinner.show();

			const formData = new FormData();
			formData.append('file', this.filesTerms[0]);

			this.subscriptionService.fineAggrement(this.driver, formData, this.subscription.cnpj).subscribe(
				() => {
					this.spinner.hide();
					this.translate.get('alert.general.termsUploaded').subscribe(
						msg => this.alert.success(msg).then(() => this.router.navigate(['/dashboard/driveList']))
					);
				},
				(err: HttpErrorResponse) => {
					this.spinner.hide();
					this.errorHandler.error(err, this.submit.bind(this));
				}
			)
		} else {
			this.translate.get('alert.general.noDocumentsProvided').subscribe(msg => {
				if (msg) {
					this.alert.error(msg);
				}
			});
		}
	}

	getFinesTerm() {
		this.spinner.show();

		const params = {
			id: this.driver.id,
			cpf: this.driver.cpf,
			plate: this.subscription.plan.vehicle.plate
		};

		this.subscriptionService.downloadTerm(params).subscribe(
			(data) => {
				this.spinner.hide();
				var downloadURL = window.URL.createObjectURL(data);
				var link = document.createElement('a');
				link.href = downloadURL;
				link.download = "termo.pdf";
				link.click();
			},
			(err) => {
				this.spinner.hide();
				console.log(err);
				alert('Error. Try again later.');
			}
		);
	}

}
