import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Fine } from '../models/fine.model';

@Injectable({
    providedIn: 'root'
})
export class FineService {
    private baseurl = `${environment.baseUrl}`;
    
    constructor(private http: HttpClient) {}

    getDriversByPlate(plate: string, uuid: string) {
        const url = `${this.baseurl}/drivers`;
    
        const param = {
            plate: plate,
            uuid: uuid
        };
        
        return this.http.post(url, param);
    }

    checkIfThereAreFines(uuid: string) {
		const url = `${environment.baseUrl}/exists-fines`;
		const params = {
			uuid: uuid
		};

		return this.http.post(url, params);
	}

    getAllFinesByUserUuid(uuid: string) {
        const url = `${this.baseurl}/fines`;
        const params = {
            uuid: uuid
        };
    
        return this.http.post(url, params);
    }

    getFineImage(fineId: string) {
        const url = `${this.baseurl}/ait/${fineId}`;
    
        return this.http.get(url);
    }

    saveFine(fineId: string, fine: Fine) {
        const url = `${this.baseurl}/fine/${fineId}`;
    
        return this.http.post(url, fine);
    }

}