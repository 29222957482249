import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Creditcard } from '../../service/subscription.interface';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubscriptionService } from '../../service/subscription.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Alert } from '../../class/alert';
import { CreditCardExpireDate, CreditCardNumberCard, CreditCardCvv } from '../../pipes/creditcard.pipe';


@Component({
  selector: 'credcard',
  templateUrl: './credcard.component.html',
  styleUrls: ['./credcard.component.scss']
})
export class CredcardComponent implements OnInit {

  public formCard: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CredcardComponent>,
    private spinner: NgxSpinnerService,
    private subscriptionService: SubscriptionService,
    public translate: TranslateService,
    private router: Router,
    private alert: Alert,
    private creditCardDate: CreditCardExpireDate,
    private creditCardNumber: CreditCardNumberCard,
    private creditCardCvv: CreditCardCvv,
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.formCard = this.formBuilder.group({
      number: ['', [Validators.required]],
      name: ['', [Validators.required]],
      expirationDate: ['', [Validators.required]],
      cvv: ['', [Validators.required]],
    });

    this.formCard.controls.expirationDate.valueChanges
      .subscribe(expirationDate => this.formCard.controls.expirationDate.setValue(this.creditCardDate.transform(expirationDate), { emitEvent: false }));

    this.formCard.controls.number.valueChanges
      .subscribe(number => this.formCard.controls.number.setValue(this.creditCardNumber.transform(number), { emitEvent: false }));

    this.formCard.controls.cvv.valueChanges
      .subscribe(cvv => this.formCard.controls.cvv.setValue(this.creditCardCvv.transform(cvv), { emitEvent: false }));
  }

  changeCard() {
    if (this.formCard.valid) {
      this.spinner.show();

      const { name, expirationDate, cvv } = this.formCard.value;
      const cardNumber = String(this.formCard.value.number).split(' ').join('');
      const cardExp = String(expirationDate).replace(/( )+/g, '').split('/').join('').trim();

      const change: Creditcard = {
        holderName: String(name).toUpperCase(),
        number: cardNumber,
        expiresAt: cardExp,
        cvv,
      };

      this.subscriptionService.changeCreditcard(change).subscribe(
        () => {
          this.translate.get('alert.200.changeCreditCard').subscribe(msg => {
            if (msg) {
              this.alert.success(msg)
              .then(() => this.dialogRef.close('update'));
              this.spinner.hide();
            }
          })

        },(err: HttpErrorResponse) => {
          this.spinner.hide();
          const translatedKey = "alert.general.changeCreditCardError";
        
          this.translate.get(translatedKey).subscribe(msg => this.alert.error(msg)
          .then(() => this.dialogRef.close('update')));

      }
        
        );

    } else {
      this.formCard.markAllAsTouched();
    }


  }

  closeDialog() {
    this.dialogRef.close();
  }
}
