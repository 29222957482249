import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ApplicationService } from '../../core/services/application.service';
import { AccessTokenResponse, User } from '../service/User/user';
import { Alert } from './alert';

interface Errors_422_Response {
  fieldName?: string;
  message?: string;
}

@Injectable()
export class ErrorHandler {
  private readonly REFRESH_TOKEN_TIMEOUT = 5000;
  private readonly REFRESH_TOKEN_STEP = 100;

  constructor(
    public alert: Alert,
    public http: HttpClient,
    public spinner: NgxSpinnerService,
    public applicationService: ApplicationService,
    public router: Router,
  ) {}

  async error(error: HttpErrorResponse, callback: any, ...callbackArgs: any) {
    const err_response = error.error

    if (error.status === 401) {
      this.spinner.show();

      if (!this.applicationService.getRefreshToken()) {
        this.spinner.hide();
        this.errorRefreshToken();
      }

      const refresh = await this.refreshToken();

      if (!refresh) {
        this.spinner.hide();
        this.errorRefreshToken();
      }

      this.spinner.hide();
      return callback(...callbackArgs);
    } else if (error.status === 422) {
      const errors = [];
      (err_response.errors as Array<Errors_422_Response>).forEach(err => errors.push(err.message));

      return this.alert.multipleErrors(errors);
    } else {
      return this.alert.error(err_response.message);
    }
  }

  async refresh(): Promise<boolean> {
    this.spinner.show();

    return new Promise(async (resolve) => {
      if (!this.applicationService.getRefreshToken()) return resolve(false);

      const refresh = await this.refreshToken();
      if (!refresh) return resolve(false);

      this.spinner.hide();
      return resolve(true)
    })
  }

  private refreshToken(): Promise<boolean> {
    let timeOut: number = 0;

    return new Promise((resolve) => {
      const interval = setTimeout(() => {

        this.refreshTokenRequest().subscribe(
          (response: AccessTokenResponse) => {
            const user = this.applicationService.getUserLogged() as User;
            user.token.access_token = response.access_token;
            this.applicationService.setUserLogged(user);
            clearInterval(interval);
            resolve(true);
          },
          () => {
            clearInterval(interval);
            resolve(false);
          },
        );

        timeOut += this.REFRESH_TOKEN_STEP;

        if (timeOut >= this.REFRESH_TOKEN_TIMEOUT) {
          clearInterval(interval);
          resolve(false);
        }
      }, this.REFRESH_TOKEN_STEP);
    });
  }

  private refreshTokenRequest(): Observable<any> {
    return this.http.post(`${environment.baseUrl}/oauth/token`, `grant_type=refresh_token&refresh_token=${this.applicationService.getRefreshToken()}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `${environment.oath.basic}`,
        'Accept-Language': 'pt'
      }),
    }).pipe(
      map((response: AccessTokenResponse) => response),
      catchError((err: HttpErrorResponse) => throwError(err)),
    )
  }

  private errorRefreshToken() {
    return this.alert.error('Sistema temporariamente indisponível, tente novamente em alguns minutos')
      .then(() => { this.applicationService.clearApplication(); this.router.navigate(['/']) });
  }
}


