import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Pendency } from 'src/app/shared/models/pendency.modal';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { Alert } from 'src/app/shared/class/alert';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler } from '../../../shared/class/ErrorHandler';

@Component({
  selector: 'drivers-debits',
  templateUrl: './drivers-debits.component.html',
  styleUrls: ['./drivers-debits.component.scss']
})
export class DriversDebitsComponent implements OnInit {
  public pendencys: Array<Pendency> = [];

  constructor(
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
    private subscriptionService: SubscriptionService,
    private applicationService: ApplicationService,
    private errorHandler: ErrorHandler,
    private alert: Alert,

  ) { }

  ngOnInit() {
    this.translate.use('pt');
    this.loadPayments();
  }

  loadPayments() {
    this.spinner.show()

    this.subscriptionService.paymentPendency(this.applicationService.getActiveSubscription().uuid).subscribe(
      (response) => {
        this.spinner.hide();
        this.pendencys = response
      },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        this.errorHandler.error(err, this.loadPayments.bind(this));
      }
    )
  }

}
