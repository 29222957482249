import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Summary, SummaryFilter } from 'src/app/shared/models/summary.model';
import { DashboardSummaryComponent } from '../../dashboard-summary.component';

export interface SummaryFilterFields {
	date?: string;
	type?: Type;
}

export interface SummaryStatusFields {
	paid: boolean;
	refused: boolean;
	pendingSlip: boolean;
	processing:boolean;
	authorized:boolean;
	refunded:boolean;
	pendingRefund: boolean;
	chargedback:boolean;
}
export interface Type{
	type:string, 
	description:string
}

@Component({
  selector: 'filter-summary',
  templateUrl: './filter-summary.component.html',
  styleUrls: ['./filter-summary.component.scss']
})
export class FilterSummaryComponent implements OnInit {

  public filter: FormGroup;
	public status: FormGroup;
	public lastStatus = '';
	public _filters = new BehaviorSubject<SummaryFilterFields>({ date: null, type: null });
	public summary : DashboardSummaryComponent;


	@Output('filters') filterEmit = new EventEmitter();

	constructor(private formBuilder: FormBuilder) {}

	ngOnInit() {
		this.buildForm();
		this.registerForm();
		this.emitFilter();

	}

	buildForm() {
		this.filter = this.formBuilder.group({
			date: [null as string],
			type: [null as Type],
			status: [null as string],
		});

		this.status = this.formBuilder.group({
			paid: [false as boolean],
			refused: [false as boolean],
			pendingSlip: [false as boolean],
			processing:[false as boolean],
			authorized:[false as boolean],
			refunded:[false as boolean],
			pendingRefund:[false as boolean],
			chargedback:[false as boolean]
		});
	}
	statusFormClick(status: string) {
		const statusList = ['paid', 'refused', 'pendingSlip', 'processing', 'authorized', 'refunded', 'pendingRefund', 'chargedback'];

		if (this.filter.value[status]) {
			statusList.forEach(field => {
				if (field !== status) {
					this.filter.controls[field].setValue(false);
				}
			})
		}
	}

	registerForm() {
		this.filter.valueChanges.subscribe((value) => {
			this.resetField(value);
			this._filters.next(this.filter.value);
		});

		
		
		this.status.valueChanges.subscribe((value) => {
			let status = null;
			let disableFields = [];

			if (value.paid && this.lastStatus !== 'paid') {
				disableFields = ['refused', 'pendingSlip', 'processing', 'authorized', 'refunded', 'pendingRefund','chargedback' ];
				status = 'paid';
			} else if (value.refused && this.lastStatus !== 'refused') {
				disableFields = ['paid', 'pendingSlip', 'processing', 'authorized', 'refunded', 'pendingRefund', 'chargedback'];
				status = 'refused';
			} else if (value.pendingSlip && this.lastStatus !== 'pendingSlip') {
				disableFields = [ 'paid', 'refused', 'processing', 'authorized', 'refunded', 'pendingRefund', 'chargedback'];
				status = 'authorized';
			} else if (value.processing && this.lastStatus !== 'processing') {
				disableFields = [ 'paid','refused', 'pendingSlip', 'authorized', 'refunded', 'pendingRefund', 'chargedback'];
				status = 'processing';
			}else if (value.authorized && this.lastStatus !== 'authorized') {
				disableFields = ['paid','refused','pendingSlip', 'processing', 'refunded', 'pendingRefund', 'chargedback'];
				status = 'authorized';
			}else if (value.refunded && this.lastStatus !== 'refunded') {
				disableFields = ['paid','refused','pendingSlip', 'processing', 'authorized', 'pendingRefund', 'chargedback'];
				status = 'refunded';
			}else if (value.pendingRefund && this.lastStatus !== 'pendingRefund') {
				disableFields = ['paid','refused', 'pendingSlip','processing', 'authorized', 'refunded', 'chargedback'];
				status = 'pendingRefund';
			}else if (value.chargedback && this.lastStatus !== 'chargedback') {
				disableFields = ['paid','refused', 'pendingSlip','processing', 'authorized', 'refunded', 'pendingRefund'];
				status = 'chargedback';
			}else {
				disableFields = ['paid', 'refused', 'pendingSlip', 'processing','authorized', 'refunded', 'pendingRefund', 'chargedback'];
				status = null;
			}

			for (const field of disableFields) {
				this.status.controls[field].setValue(false, { emitEvent: false });
			}

			this.lastStatus = status;
			this.filter.controls.status.setValue(status);
		});


	}

	resetField(formValue: SummaryFilterFields) {
		for (const [key, value] of Object.entries(formValue)) {
			if (String(value).trim().length === 0 || value === '') {
				this.filter.controls[key].reset();
			}
		}
	}

	emitFilter() {
		this._filters.pipe(
			debounceTime(800),
			distinctUntilChanged(),
		).subscribe((formValue) => this.filterEmit.emit(formValue));
	}

	types:Type[] = [
		{description:'SUBSCRIPTION', type:'Mensalidade'},
		{description:'FIRST_TRANSACTION', type:'Primeira Transação'},
		{description:'INFORMATIVE', type:'Informativo'},
		{description:'ADDITIONAL_KM', type: 'Quilometragem Adicional'},
		{description:'FINES', type:'Multa'},
		{description:'CANCELLATION_FINE', type:'Multa de Cancelamento'},
		{description:'EXTRA', type:'Extra'},
		{description:'ACCESSORIES', type:'Acessorios' },
		{description:'CHARGEDBACK', type:'Estorno'},
		{description:'MONTHLY_CHARGE', type:'Mensalidade Manual'}
	]

	resetTypeFilter(){
		this.summary.loadSummary;
		
	}

}
