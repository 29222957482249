import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';

@Injectable()
export class HttpErrorHandleService {

  constructor(
    private translateService: TranslateService,
  ) { }
  /**
   * Get errors
   *
   * OAuth
   * https://www.oauth.com/oauth2-servers/access-tokens/access-token-response/
   *
   * Spring Boot
   * error.status https://docs.spring.io/spring-boot/docs/current/api/org/springframework/boot/web/reactive/error/DefaultErrorAttributes.html
   */
  getErrorMessage(httpErrorResponse: HttpErrorResponse, sufix: string) {
    let key: string;
    if(httpErrorResponse){
      key = "alert." + httpErrorResponse.status + "." + sufix;
      if (this.translateService.instant(key) === key) key = 'alert.500';

    }else{
      key = "alert.general." + sufix
    }
    return this.translateService.get(key).pipe(
      tap(msg => msg)
    );


  }
}
