import { AfterContentChecked, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { isMobile } from 'mobile-device-detect';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadDocComponent } from '../../../shared/components/upload-doc/upload-doc.component';
import { SubscriptionService } from '../../../shared/service/subscription.service';
import { ApplicationService } from '../../../core/services/application.service';
import { Subscription} from './../../../shared/models/subscription.model';
import { SubscriptionDetail } from '../../../shared/models/subscription-detail.model';
import { Alert } from 'src/app/shared/class/alert';
import { CredcardComponent } from 'src/app/shared/components/credcard/credcard.component';
import { ErrorHandler } from '../../../shared/class/ErrorHandler';
import { RentalService } from "../../../shared/service/rental.service";
import { Rental } from "../../../shared/models/rental.model";
import { Driver } from "../../../shared/models/driver.model";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from 'src/environments/environment';
import { VehicleAccessorie } from 'src/app/shared/models/vehicle-accessorie.model';
import { MessageService } from 'primeng/api';

@Component({
	selector: 'app-dashboard-vehicle',
	templateUrl: './dashboard-vehicle.component.html',
	styleUrls: ['./dashboard-vehicle.component.scss'],
	providers: [NgbModalConfig, NgbModal]
})
export class DashboardVehicleComponent implements OnInit, AfterContentChecked {
	baseUrl = environment.baseUrl + '/customer';
	public subscriptionSelected: string;
	public subscriptions: Array < Subscription >;
	public subscription: SubscriptionDetail;
	public drivers: Array < Driver > ;
	public accessoriesVehicle: Array<VehicleAccessorie> = [];
	public rental: Rental;

	public vehicleCard: {
		imagePath: string;
		color: string;
		colorLabel: string
	} = {
		imagePath: 'assets/image/no-car.png',
		color: '',
		colorLabel: ''
	};

	public alertDriversPendency = false;
	public showCancel = false;
	public moreKilometersInfo = false;

	public amountWithAccessories: number = 0;

	tooltip = {
		one: false,
		two: false,
		three: false
	}

	initialMonth: number = 0;
	currentMonth: number = 0;
	currentTrimester: number = 0;
	isFleec = false;
	statusFleet = null;

	constructor(
		private translate: TranslateService,
		private router: Router,
		private modalService: NgbModal,
		public dialog: MatDialog,
		private http: HttpClient,
		private rentalService: RentalService,
		private alert: Alert,
		public subscriptionService: SubscriptionService,
		public spinner: NgxSpinnerService,
		public applicationService: ApplicationService,
		public errorHandler: ErrorHandler,
		public sanitizer: DomSanitizer,
		private messageService: MessageService,
    	private cdref: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.translate.use('pt');

    if (this.applicationService.getUserFleet() === 'true') {
		this.isFleec = true;
		this.statusFleet = this.applicationService.getStatusFleet();
    }
		this.subscriptions = this.applicationService.getSubscriptions();
		this.subscriptionSelected = this.applicationService.getActiveSubscription().name;
		this.loadSubscriptionDetails();
		this.getVehicleAccessories();
  	}

	ngAfterContentChecked() {
		this.subscription;
		this.cdref.detectChanges();
	}

  	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		this.onImageLoad();
	}

	changeSubscription(subscription: Subscription) {
		this.subscriptionSelected = subscription.name;
		this.applicationService.changeActiveSubscription(subscription.uuid);
		this.loadSubscriptionDetails();
	}

	loadSubscriptionDetails() {
		this.spinner.show();
		this.messageService.clear();

		this.subscriptionService.detail(this.applicationService.getActiveSubscription().uuid).subscribe(
			(response) => {
				this.spinner.hide();
				this.subscription = response;

				if (this.subscription.needMainDriver) {
					this.triggerMessage('error', 'Você precisa cadastrar um motorista principal para esse veículo.');
				}

				this.mountVehicleCard();
				this.checkDriversPendencys();
				this.loadRentalDetails();
				this.loadRentalMapImage();
				this.getVehicleAccessories();
				// this.mountMonthSettings(this.subscription);
				// this.mountTrimesterSettings(this.subscription);
			},
			(err: HttpErrorResponse) => {
				this.spinner.hide();
				this.errorHandler.error(err, this.loadSubscriptionDetails.bind(this));
			}
		);
	}

	loadRentalMapImage() {
		this.spinner.show();
		this.rentalService.getRentalMapImage(this.subscription.dealer.latitude, this.subscription.dealer.longitude).subscribe(
			(response) => {
				this.spinner.hide();
				this.subscription.dealer.mapImage = response;
			},
			(err: HttpErrorResponse) => {
				this.spinner.hide();
				this.errorHandler.error(err, this.loadRentalDetails.bind(this));
			}
		);
	}

	loadRentalDetails() {
		this.spinner.show();
		this.rentalService.getRentalOfDealer(this.subscription.dealer.id).subscribe(
			(response) => {
				this.spinner.hide();
				this.rental = < Rental > response;
			},
			(err: HttpErrorResponse) => {
				this.spinner.hide();
			}
		);
	}

	checkDriversPendencys() {
		this.alertDriversPendency = false;

		this.spinner.show();

		this.subscriptionService.drivers(this.applicationService.getActiveSubscription().uuid).subscribe(
			(response) => {
				this.drivers = response;
				response.map(driver => {
					if ((!driver.finesAgreement || driver.finesAgreement === 'REFUSED') && this.subscription.plan.vehicle.plate) {
						this.alertDriversPendency = true;
					}
				})
				this.spinner.hide();
			},
			(err: HttpErrorResponse) => {
				this.spinner.hide();
				this.errorHandler.error(err, this.checkDriversPendencys.bind(this));
			}
		);
	}

	mountVehicleCard() {
		this.spinner.show();

		var vin = this.subscription.plan.vehicle.chassis;
		var country = 'BRA';
		var year = new Date(this.subscription.plan.vehicle.deliveryDate).getFullYear().toString();

		this.subscriptionService.getVehicleImage(vin, country, year).subscribe(
			(res) => {
				this.vehicleCard.imagePath = res;
				this.spinner.hide();
			},
			(err: HttpErrorResponse) => {
				this.vehicleCard.imagePath = 'assets/image/no-car.png';
				this.spinner.hide();
			},
		);

		const colorMap = {
			branco: 'white',
			preto: 'black',
			cinza: 'grey',
			prata: 'silver'
		};

		this.vehicleCard.color = 'transparent';

		['branco', 'preto', 'cinza', 'prata'].map(colorSearch => {
			if (String(this.subscription.plan.vehicle.color).toLowerCase().includes(colorSearch)) {
				this.vehicleCard.color = colorMap[colorSearch];
			}
		});

		this.vehicleCard.colorLabel = this.subscription.plan.vehicle.color;
	}

	onImageLoad() {
		const elementImg = document.getElementById('vehicle-img');
		const elementCarCard = document.getElementById('car-card');
		const height = elementImg.getBoundingClientRect().height;
		elementImg.style.marginTop = '-' + (height * 0.75) + 'px';
		elementCarCard.style.paddingTop = ((height * 0.75) - 44) + 'px';
		elementCarCard.style.paddingBottom = ((height * 0.75) - 44) + 'px';
	}

	seeMore() {
		const btnSee = document.getElementById("more");
		const btnUnsee = document.getElementById("less");
		const btnCancel = document.getElementById("cancelB");

		if (btnUnsee.style.display === "none") {
			btnUnsee.style.display = "inline";
			btnCancel.style.display = "inline";
			btnSee.style.display = "none";
		} else {
			btnUnsee.style.display = "none";
			btnSee.style.display = "inline";
			btnCancel.style.display = "none";
		}
	}

	openAddDriverModal() {
		if (this.drivers.length > 7) {
			this.alert.error('Limite maximo de motoristas alcançado.');
		} else {
			this.dialog.open(UploadDocComponent, {
				width: isMobile ? '95vw' : '900px',
				minWidth: isMobile ? '95vw' : '',
				maxHeight: isMobile ? '85%' : '',
				data: {
					driver: null,
					mainDriver: this.drivers.length > 0 ? false : true
				}
			});
		}
	}

	openEditCardModal() {
		const modal = this.dialog.open(CredcardComponent, {
			width: '600px',
			height: '560px'
		});
		modal.afterClosed().subscribe(close => {
			if (close === 'update') {
				this.loadSubscriptionDetails();
			}
		});
	}

	showCancelSubscriptionModal(content: any, subscription: SubscriptionDetail) {
		if (subscription.cancellationRequest) return this.alert.warning('O cancelamento já foi solicitiado');

		this.modalService.open(content, {
				centered: true,
				ariaLabelledBy: 'mais informações'
			})
			.result
			.then(() => {}, (reason) => {
				if (reason === 'cancel_confirm') {
					this.cancelSubscription();
				}
			});
	}

	cancelSubscription() {
		this.spinner.show();

		this.subscriptionService.cancel(this.applicationService.getActiveSubscription().uuid).subscribe(
			() => {
				this.spinner.hide();
				this.router.navigate(['/dashboard/contractFinished']);
			},
			(err: HttpErrorResponse) => {
				this.spinner.hide();
				this.errorHandler.error(err, this.cancelSubscription.bind(this));
			}
		)
	}

	getCompleteAddress() {
		if (!this.rental) {
			return '';
		}
		return `${this.rental.endereco}, ${this.rental.cidade} - ${this.rental.uf.toUpperCase()}`;
	}

	haveVeloe() {
		if (this.subscription) {
			if (this.subscription.veloe === true) {
				return String('Sim')
			} else {
				return String('Não')
			}
		}
	}

	contractStatus() {
		if (this.subscription) {
			if (this.subscription.finalized) {
				return 'Contrato finalizado';
			} else if (this.subscription.cancelled) {
				return 'Contrato cancelado';
			} else if (this.subscription.cancellationRequest) {
				return 'Cancelamento solicitado';
			} else if (this.subscription.terminated !== null && this.subscription.status === true) {
				return String('Contrato ativo');
			} else if (this.subscription.terminated === null && this.subscription.status === true) {
				return String('Em andamento');
			} else {
				return String('-');
			}
		}
	}

	plateFormat(): string {
		if (this.subscription) {
			const {
				plate
			} = this.subscription.plan.vehicle;
			let mercosulPlate = false;

			if (isNaN(parseInt(String(plate).substr(4, 1), 10))) {
				mercosulPlate = true;
			}

			if (mercosulPlate) {
				return String(plate).toUpperCase();
			}

			return `${String(plate).substr(0, 3)} - ${String(plate).substr(3, plate.length)}`.toUpperCase();
		} else {
			return '';
		}
	}

	driveList(): void {
		localStorage.setItem('plate-driver', this.subscription?.plan?.vehicle?.plate);
		this.router.navigate(['/dashboard/driveList']);
	}

	toSummaryPage() {
		this.router.navigate(['/dashboard/summary']);
	}

	redirectDebit() {
		this.router.navigate(['/dashboard/drivesDebts']);
	}

	showContract() {
		this.spinner.show();

		this.subscriptionService.downloadContract().subscribe(
			(data) => {
				this.spinner.hide();
				var downloadURL = window.URL.createObjectURL(data);
				var link = document.createElement('a');
				link.href = downloadURL;
				link.download = "contract.pdf";
				link.click();
			},
			(err) => {
				this.spinner.hide();
				this.alert.error('Erro ao carregar contrato. Por favor, tente novamente mais tarde.');
			}
		);
	}

	showCancelButton() {
		this.showCancel = !this.showCancel;
	}

	triggerMessage(severity: string, detail: string) {
		this.messageService.clear();
		this.messageService.add({
			key: 'msg',
			sticky: true,
			severity: severity,
			summary: '',
			detail: detail
		});
	}

	showMoreKilometersInfo() {
		this.moreKilometersInfo = !this.moreKilometersInfo;
	}

	showTooltip(value: boolean, tooltipeNumber: number): void {
		switch (tooltipeNumber) {
			case 1:
				this.tooltip.one = value;
				break;
			case 2:
				this.tooltip.two = value;
				break;
			case 3:
				this.tooltip.three = value;
				break;
			default:
				this.tooltip.one = this.tooltip.two = this.tooltip.three = false;
				break;
		}
	}

	mountMonthSettings(request: SubscriptionDetail) {
		var month = [{
				mt: 1,
				month: 'Janeiro'
			},
			{
				mt: 2,
				month: 'Fevereiro'
			},
			{
				mt: 3,
				month: 'Março'
			},
			{
				mt: 4,
				month: 'Abril'
			},
			{
				mt: 5,
				month: 'Maio'
			},
			{
				mt: 6,
				month: 'Junho'
			},
			{
				mt: 7,
				month: 'Julho'
			},
			{
				mt: 8,
				month: 'Agosto'
			},
			{
				mt: 9,
				month: 'Setembro'
			},
			{
				mt: 10,
				month: 'Outubro'
			},
			{
				mt: 11,
				month: 'Novembro'
			},
			{
				mt: 12,
				month: 'Dezembro'
			},
		];

		this.currentMonth = new Date().getMonth() + 1;
		request.monthLabel = month[month.findIndex(value => value.mt == this.currentMonth)].month;

		this.getTelemetryFromRange(request.monthLabel, request.monthLabel, request).subscribe(
			data => {
				var maxkm = request.plan.maxKm;
				request.mensalOdometer = data ? < number > data : 0;
				request.monthConsumePercentage = (request.mensalOdometer / maxkm) * 100;
			}
		);
	}

	mountTrimesterSettings(request: SubscriptionDetail) {
		this.currentMonth = new Date().getMonth() + 1;
		this.initialMonth = new Date(request.plan.vehicle.deliveryDate).getMonth() + 1;
		this.currentTrimester = this.calcTrimester(this.currentMonth);

		var monthToTrimester = [{
				mt: 1,
				month: 'Janeiro'
			},
			{
				mt: 2,
				month: 'Fevereiro'
			},
			{
				mt: 3,
				month: 'Março'
			},
			{
				mt: 4,
				month: 'Abril'
			},
			{
				mt: 5,
				month: 'Maio'
			},
			{
				mt: 6,
				month: 'Junho'
			},
			{
				mt: 7,
				month: 'Julho'
			},
			{
				mt: 8,
				month: 'Agosto'
			},
			{
				mt: 9,
				month: 'Setembro'
			},
			{
				mt: 10,
				month: 'Outubro'
			},
			{
				mt: 11,
				month: 'Novembro'
			},
			{
				mt: 12,
				month: 'Dezembro'
			},
		];

		monthToTrimester.forEach(o => {
			o.mt = this.calcTrimester(o.mt);
		})

		monthToTrimester = monthToTrimester.filter(value => {
			return value.mt == this.currentTrimester;
		});

		if (monthToTrimester.find(value => value.month == 'Outubro')) {
			monthToTrimester = monthToTrimester;
		} else if (monthToTrimester.find(value => value.month == 'Novembro')) {
			monthToTrimester[0].month = 'Novembro';
			monthToTrimester[1].month = 'Dezembro';
			monthToTrimester[2].month = 'Janeiro';
		} else if (monthToTrimester.find(value => value.month == 'Dezembro')) {
			monthToTrimester[0].month = 'Dezembro';
			monthToTrimester[1].month = 'Janeiro';
			monthToTrimester[2].month = 'Fevereiro';
		}

		monthToTrimester.splice(1, 1);

		request.trimesterLabel = monthToTrimester[0].month + ' - ' + monthToTrimester[1].month;

		this.getTelemetryFromRange(monthToTrimester[0].month, monthToTrimester[1].month, request).subscribe(
			data => {
				var maxkm = request.plan.maxKm;
				request.quarterOdometer = data ? < number > data : 0;
				request.quarterConsumePercentage = (request.quarterOdometer / (maxkm * 3)) * 100;
			}
		);
	}

	calcTrimester(month: number) {
		return Math.ceil(((month - this.initialMonth + 12) % 12 + 1) / 3);
	}

	getTelemetryFromRange(startMonth: string, endMonth: string, request: SubscriptionDetail) {
		var monthToTrimester = [{
				mt: '01',
				month: 'Janeiro'
			},
			{
				mt: '02',
				month: 'Fevereiro'
			},
			{
				mt: '03',
				month: 'Março'
			},
			{
				mt: '04',
				month: 'Abril'
			},
			{
				mt: '05',
				month: 'Maio'
			},
			{
				mt: '06',
				month: 'Junho'
			},
			{
				mt: '07',
				month: 'Julho'
			},
			{
				mt: '08',
				month: 'Agosto'
			},
			{
				mt: '09',
				month: 'Setembro'
			},
			{
				mt: '10',
				month: 'Outubro'
			},
			{
				mt: '11',
				month: 'Novembro'
			},
			{
				mt: '12',
				month: 'Dezembro'
			},
		];

		// day is fixed at the 1st day of the month, always
		const day = '01';

		// get the start and end month of the trimester range
		const sm = monthToTrimester[monthToTrimester.findIndex(value => value.month == startMonth)].mt;
		const em = monthToTrimester[monthToTrimester.findIndex(value => value.month == endMonth)].mt;

		// get the start and end year of the trimester range
		var sy = 0;
		var ey = 0;

		if ((sm != '11' && sm != '12') || sm == em) {
			sy = new Date().getFullYear();
			ey = sy;
		} else {
			const currentMonth = new Date().getMonth() + 1;

			if (currentMonth >= 11) {
				sy = new Date().getFullYear();
				ey = new Date().getFullYear() + 1;
			} else {
				sy = new Date().getFullYear() - 1;
				ey = new Date().getFullYear();
			}
		}

		const range = {
			begin: sy.toString() + sm + day,
			end: ey.toString() + em + day
		}

		return this.getOdometerFromRange(range.begin, range.end, request);
	}

	getOdometerFromRange(startDate: string, endDate: string, request: SubscriptionDetail) {
		let vin = request.plan.vehicle.chassis;
		var uuid = this.applicationService.getUserLogged().token.uuid;

		return this.http.get(this.baseUrl + '/' + uuid + '/telemetry/?vin=' + vin + '&start=' + startDate + '&end=' + endDate);
	}

	getVehicleAccessories(): void {
		const user = this.applicationService.getUserLogged();
		const sub = this.applicationService.getActiveSubscription();

		this.http.get(this.baseUrl + `/${user.token.uuid}/accessories/${sub.uuid}/vehicle`).subscribe({
			next: (res: Array<VehicleAccessorie>) =>  {
				this.accessoriesVehicle = res;
				this.calculateAmountWithAccessories();
			},
			error: (err: HttpErrorResponse) => {
			}
		})
	}

	calculateAmountWithAccessories(): void {
		let acsPriceMonth = 0;

		if (this.accessoriesVehicle.length > 0) {
			this.accessoriesVehicle.forEach(acs => {
				acsPriceMonth += acs.monthPrice
			});

			this.amountWithAccessories = acsPriceMonth + this.subscription?.plan?.amount;
		}
	}

}
