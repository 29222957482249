import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'btn-ford',
	templateUrl: './ford-btn.component.html',
	styleUrls: ['./ford-btn.component.scss'],
})
export class BtnFordComponent implements OnInit {
	@Output() back = new EventEmitter();
	@Output() next = new EventEmitter();

	ngOnInit() {

	}

	emitNext() {
		this.next.emit();
	}

	emitBack() {
		this.back.emit();
	}
}
