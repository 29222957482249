import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Input, OnChanges,
  OnInit, SimpleChanges
} from '@angular/core';
import {SubscriptionDetail, SubscriptionDetailFleet} from "../../models/subscription-detail.model";
import {ApplicationService} from "../../../core/services/application.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'kilometers-percentage',
  templateUrl: './kilometers-percentage.component.html',
  styleUrls: ['./kilometers-percentage.component.scss']
})
export class KilometersPercentageComponent implements OnInit, OnChanges {
  baseUrl = environment.baseUrl + '/customer';
  constructor(private applicationService: ApplicationService, private http: HttpClient, private cdref: ChangeDetectorRef) { }

  @Input() subscription: SubscriptionDetailFleet | SubscriptionDetail;
  @Input() mountMonth = false;
  @Input() mountTrimester = false;

  ngOnInit(): void {
    if(this.mountMonth){
      if(this.subscription === undefined || this.subscription === null) return;
      this.mountMonthSettings(this.subscription);
    }
    if(this.mountTrimester){
      if(this.subscription === undefined || this.subscription === null) return;
      this.mountTrimesterSettings(this.subscription);
    }
  }

  initialMonth: number = 0;
  currentMonth: number = 0;
  currentTrimester: number = 0;
  quarterOdometer =  0;
  quarterConsumePercentage = 0;
  mensalOdometer = 0;
  monthConsumePercentage = 0;
  excessKm: boolean = true;
  excessKmNot: boolean = true;

  mountMonthSettings(request: SubscriptionDetail | SubscriptionDetailFleet) {
    var month = [{
      mt: 1,
      month: 'Janeiro'
    },
      {
        mt: 2,
        month: 'Fevereiro'
      },
      {
        mt: 3,
        month: 'Março'
      },
      {
        mt: 4,
        month: 'Abril'
      },
      {
        mt: 5,
        month: 'Maio'
      },
      {
        mt: 6,
        month: 'Junho'
      },
      {
        mt: 7,
        month: 'Julho'
      },
      {
        mt: 8,
        month: 'Agosto'
      },
      {
        mt: 9,
        month: 'Setembro'
      },
      {
        mt: 10,
        month: 'Outubro'
      },
      {
        mt: 11,
        month: 'Novembro'
      },
      {
        mt: 12,
        month: 'Dezembro'
      },
    ];

    this.currentMonth = new Date().getMonth() + 1;
    request.monthLabel = month[month.findIndex(value => value.mt == this.currentMonth)].month;


    if(request.plan.vehicle.chassis != null) {
      this.getTelemetryFromRange(request.monthLabel, request.monthLabel, request).subscribe(
        data => {
          var maxkm = request.plan.maxKm;
          request.mensalOdometer = data ? <number>data : 0;
          request.monthConsumePercentage = (request.mensalOdometer / maxkm) * 100;
          this.applyExcessFilter(request);
        }
      );
    }else{
      request.mensalOdometer =  0;
      request.monthConsumePercentage = 0;
      this.applyExcessFilter(request);
    }

  }

  mountTrimesterSettings(request: SubscriptionDetail | SubscriptionDetailFleet) {
    this.currentMonth = new Date().getMonth() + 1;
    this.initialMonth = new Date(request.plan.vehicle.deliveryDate).getMonth() + 1;
    this.currentTrimester = this.calcTrimester(this.currentMonth);

    var monthToTrimester = [{
      mt: 1,
      month: 'Janeiro'
    },
      {
        mt: 2,
        month: 'Fevereiro'
      },
      {
        mt: 3,
        month: 'Março'
      },
      {
        mt: 4,
        month: 'Abril'
      },
      {
        mt: 5,
        month: 'Maio'
      },
      {
        mt: 6,
        month: 'Junho'
      },
      {
        mt: 7,
        month: 'Julho'
      },
      {
        mt: 8,
        month: 'Agosto'
      },
      {
        mt: 9,
        month: 'Setembro'
      },
      {
        mt: 10,
        month: 'Outubro'
      },
      {
        mt: 11,
        month: 'Novembro'
      },
      {
        mt: 12,
        month: 'Dezembro'
      },
    ];

    monthToTrimester.forEach(o => {
      o.mt = this.calcTrimester(o.mt);
    })

    monthToTrimester = monthToTrimester.filter(value => {
      return value.mt == this.currentTrimester;
    });

    if (monthToTrimester.find(value => value.month == 'Outubro')) {
      monthToTrimester = monthToTrimester;
    } else if (monthToTrimester.find(value => value.month == 'Novembro')) {
      monthToTrimester[0].month = 'Novembro';
      monthToTrimester[1].month = 'Dezembro';
      monthToTrimester[2].month = 'Janeiro';
    } else if (monthToTrimester.find(value => value.month == 'Dezembro')) {
      monthToTrimester[0].month = 'Dezembro';
      monthToTrimester[1].month = 'Janeiro';
      monthToTrimester[2].month = 'Fevereiro';
    }

    monthToTrimester.splice(1, 1);

    request.trimesterLabel = monthToTrimester[0].month.substring(0, 3) + ' - ' + monthToTrimester[1].month.substring(0, 3);




    if(request.plan.vehicle.chassis != null){
      this.getTelemetryFromRange(monthToTrimester[0].month, monthToTrimester[1].month, request).subscribe(
        data => {
          var maxkm = request.plan.maxKm;
          request.quarterOdometer = data ? < number > data : 0;
          request.quarterConsumePercentage = (request.quarterOdometer / (maxkm * 3)) * 100;
          this.applyExcessFilter(request);
        }
      );
    }else{
      request.quarterOdometer =  0;
      request.quarterConsumePercentage = 0;
      this.applyExcessFilter(request);
    }
  }

   getTelemetryFromRange(startMonth: string, endMonth: string, request: SubscriptionDetail | SubscriptionDetailFleet) {
    var monthToTrimester = [{
      mt: '01',
      month: 'Janeiro'
    },
      {
        mt: '02',
        month: 'Fevereiro'
      },
      {
        mt: '03',
        month: 'Março'
      },
      {
        mt: '04',
        month: 'Abril'
      },
      {
        mt: '05',
        month: 'Maio'
      },
      {
        mt: '06',
        month: 'Junho'
      },
      {
        mt: '07',
        month: 'Julho'
      },
      {
        mt: '08',
        month: 'Agosto'
      },
      {
        mt: '09',
        month: 'Setembro'
      },
      {
        mt: '10',
        month: 'Outubro'
      },
      {
        mt: '11',
        month: 'Novembro'
      },
      {
        mt: '12',
        month: 'Dezembro'
      },
    ];

    // day is fixed at the 1st day of the month, always
    const day = '01';

    // get the start and end month of the trimester range
    const sm = monthToTrimester[monthToTrimester.findIndex(value => value.month == startMonth)].mt;
    const em = monthToTrimester[monthToTrimester.findIndex(value => value.month == endMonth)].mt;

    // get the start and end year of the trimester range
    var sy = 0;
    var ey = 0;

    if ((sm != '11' && sm != '12') || sm == em) {
      sy = new Date().getFullYear();
      ey = sy;
    } else {
      const currentMonth = new Date().getMonth() + 1;

      if (currentMonth >= 11) {
        sy = new Date().getFullYear();
        ey = new Date().getFullYear() + 1;
      } else {
        sy = new Date().getFullYear() - 1;
        ey = new Date().getFullYear();
      }
    }

    const range = {
      begin: sy.toString() + sm + day,
      end: ey.toString() + em + day
    }

    return this.getOdometerFromRange(range.begin, range.end, request);
  }

  getOdometerFromRange(startDate: string, endDate: string, request: SubscriptionDetail | SubscriptionDetailFleet) {
    let vin = request.plan.vehicle.chassis;
    var uuid = this.applicationService.getUserLogged().token.uuid;

    return this.http.get(this.baseUrl + '/' + uuid + '/telemetry/?vin=' + vin + '&start=' + startDate + '&end=' + endDate);
  }

  calcTrimester(month: number) {
    return Math.ceil(((month - this.initialMonth + 12) % 12 + 1) / 3);
  }

  applyExcessFilter(request: SubscriptionDetailFleet | SubscriptionDetail) {
    if ((request.monthConsumePercentage > 100 && !this.excessKm) && (request.quarterConsumePercentage > 100 && !this.excessKm)) {
      request.isExcessKm = true;
    }

    if ((request.monthConsumePercentage <= 100 && !this.excessKmNot) && (request.quarterConsumePercentage <= 100 && !this.excessKmNot)) {
      request.isExcessKm = true;
    }

    if (request.quarterConsumePercentage > 100 && !this.excessKm) {
      request.isExcessKm = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.mountMonth){
      if(this.subscription === undefined || this.subscription === null) return;
      this.mountMonthSettings(this.subscription);
    }
    if(this.mountTrimester){
      if(this.subscription === undefined || this.subscription === null) return;
      this.mountTrimesterSettings(this.subscription);
    }
  }
}
