import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {FormGroup} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Alert} from '../../shared/class/alert';
import {AuthService} from '../../shared/service/Auth/auth.service';
import {HttpErrorHandleService} from '../../shared/service/data/http-error-handle/http-error-handle.service';
import {AccessTokenResponse, User} from '../../shared/service/User/user';
import {SubscriptionService} from '../../shared/service/subscription.service';
import {ApplicationService} from '../../core/services/application.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public enviroment = environment;
  hideShowPasswordToogle: boolean = false;
  image1 = '../../../assets/image/login/open-eye.svg';
  image2 = '../../../assets/image/login/closed-eye.svg';
  loading = false;
  isFmaReady = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService,
    private alert: Alert,
    private errorHandler: HttpErrorHandleService,
    private subscriptionService: SubscriptionService,
    private applicationService: ApplicationService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute
  ) {
    const regex = new RegExp('^https://login.*[.]ford[.]com/$');
    this.loading = regex.test(document.referrer);

    this.spinner.show();

    this.activatedRoute.queryParamMap.subscribe(      
      params => {
        const action = params.get('action');

        switch (action) {
          case 'register':
            {
              this.launchFmaForActionRegister();
              break;
            }
          default:
            {
              this.spinner.hide();
              break;
            }
        }
      }
    )
  }

  ngOnInit() {
    this.translate.use('pt');

    document.body.addEventListener('fma_authenticated', () => {
      this.spinner.show();
      this.alert.cancel();
      this.loading = false;

      this.updateStorage(window.fma.CATBundle, undefined);

      this.subscriptionService.getCustomerUuid().subscribe(
        (uuid) => {
          this.updateStorage(window.fma.CATBundle, uuid);
          this.login();
        },
        (err) => {
          this.spinner.hide();
          if (err.status == 404) this.alert.error('Cliente não encontrado.');
          else this.alert.error('Error ao acessar o sistema. Por favor, tente novamente mais tarde. (0000)');
        });
      });
  }

  updateStorage(catBundle: any, uuid: string) {
    var token: AccessTokenResponse = {
      access_token: catBundle.access_token,
      expires_in: catBundle.expires_in,
      refresh_token: catBundle.refresh_token,
      token_type: 'bearer',
      uuid: uuid
    }

    var user: User = {token: token};

    localStorage.setItem(environment.storage.user, JSON.stringify(user));
  }

  login(): void {
      this.spinner.show();

      this.subscriptionService.isFleetOwner().subscribe(result => {
        if(result === true) {
          this.applicationService.setUserFleet('true');

          this.subscriptionService.findAll().subscribe(
            () => {
              this.router.navigate(['/fleet']);
            },
            this.errorSubscriptionFindAllCallback.bind(this)
          );
        }else{
          this.subscriptionService.findAll().subscribe(
            (subscriptions) => {
              if (subscriptions.length) {
                this.applicationService.setSubscriptions(subscriptions);
                this.applicationService.setUserFleet('false');
                this.router.navigate(['/dashboard']);
              } else {
                return this.alert.error('Você não possui assinaturas ativas');
              }
            },
            this.errorSubscriptionFindAllCallback.bind(this));
        }
      });
  }

  errorSubscriptionFindAllCallback(err: HttpErrorResponse): void {
    this.spinner.hide();
    if (err.status === 400 && err.error.message === 'PJ') {
      this.router.navigate(['/dashboard/contractor']);
    } else if (err.status === 400) {
      this.router.navigate(['/dashboard/upload']);
    } else {
      this.applicationService.clearApplication();
      this.errorHandler.getErrorMessage(err, 'subscriptionFindAll').subscribe(
        (translatedText: string) => {
          this.alert.error(translatedText);
        }
      );
    }
  }

  errorLoginCallback(err: HttpErrorResponse): void {
    this.spinner.hide();
    this.errorHandler.getErrorMessage(err, 'login').subscribe(
      (translatedText: string) => {
        this.alert.error(translatedText);
      }
    );
  }

  redirectAssinatura() {
    window.location.href = environment.assinaturaPortalUrl;

  }

  hideShowPassword() {
    this.hideShowPasswordToogle = !this.hideShowPasswordToogle;
  }

  fmaLogin() {
    window.fma.login();
  }

  fmaRegister() {
    window.fma.register();
  }

  fmaReset() {
    window.fma.passwordReset();
  }

  async stopLogin() {
    const question = await this.alert.question('Atenção! Login em andamento. Deseja interromper a ação e reiniciar o login?');
    if (question.dismiss) return;
    
    window.fma.login();
  }

  launchFmaForActionRegister() {
    this.isFmaReady = (window.fma != undefined)

    if (this.isFmaReady === false) {
      setTimeout(() => {
        this.launchFmaForActionRegister();
      }, 100);
    }
    else {
      window.fma.register();
    }
  }
}
