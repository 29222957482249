/**This file contains a constant object that has properties that are necessary
 * for the build found in the `development` configuration in `angular.json`.
 */
export const environment = {
	production: false,
	inmem: false,
	animal: '🐧',
	baseUrl: 'https://wwwdev.mobilidade.ford.com/api/v1',
	oath: {
		basic: 'Basic aktWdTY5cGxZbHQ4amRxZXNaTFVCWnJZZVdNWGlYOmV4ZjlGdXRSUHNHWDZiNUxCcjNUYlBHZ0xXamRuQg==',
	},
	storage: {
		subscriptions: '935a4f4fb6586ad4a9ade080bd964cc5',
		user: '67b525e2b62d5505a335d9ed4d2b755b',
	},
	assinaturaPortalUrl: 'https://fordgo.com.br',
  fma: {
    url: 'https://wwwdev.account.ford.com/etc/fd/fma/bundle.js',
    clientid: undefined,
    appid: undefined,
    ibmid: undefined,
    redirecturl: 'https://wwwdev.mobilidade.ford.com'
  }
};
