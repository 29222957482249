export class AdditionalUserModel {
  id?: string;
  name: string;
  email: string;
  type: UserAdditionalEnum;
  createdDate: Date;


  public constructor(name: string, email: string, type: UserAdditionalEnum) {
    this.name = name;
    this.email = email;
    this.type = type;
  }
}


export enum UserAdditionalEnum{
  FINANCIAL ,
  ADMINISTRATOR_FLEET ,
  MANAGER_FLEET
}


export function getTypeUser(type: string): string{
  switch (type) {
    case 'FINANCIAL': return 'Financeiro';
    case 'ADMINISTRATOR_FLEET': return 'Gestor de Frota';
    case 'MANAGER_FLEET': return 'Gerente de Frota';
  }
}
