import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NavMobileService {
	public menu = new BehaviorSubject(false);

	show() {
		this.menu.next(true);
	}

	hide() {
		this.menu.next(false);
	}
}
