import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {Driver} from "../../models/driver.model";

@Component({
  selector: 'alert-cnh',
  templateUrl: './alert-cnh.component.html',
  styleUrls: ['./alert-cnh.component.scss']
})
export class AlertCnhComponent implements OnInit {
  @Input() drivers: Array<Driver>;

  cnhValidityStatus: string;

  labelCondutor: string;

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    this.elementRef.nativeElement.style.display = 'none';
    this.setCnhValidityStatus();
  }

  setCnhValidityStatus() {
    this.drivers.forEach((driver) => {
      if (driver.cnhValidityStatus !== 'OK') {
        this.elementRef.nativeElement.style.display = 'block';
        this.cnhValidityStatus = driver.cnhValidityStatus;
        this.labelCondutor = driver.mainDriver ? 'principal' : 'adicional';
      }
    });
  }

  closeAlert() {
    this.elementRef.nativeElement.style.display = 'none';
  }

}
