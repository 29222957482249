import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {Alert} from 'src/app/shared/class/alert';
import {DashboardService} from '../shared/dashboard.service';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {SubscriptionService} from '../../../shared/service/subscription.service';
import {ApplicationService} from '../../../core/services/application.service';
import {mode as FileType, UploadFile} from '../../../shared/components/upload/upload.interface';
import {ErrorHandler} from '../../../shared/class/ErrorHandler';

@Component({
  selector: 'app-dashboard-regisry-licensed-upload',
  templateUrl: './dashboard-registry-licensed-upload.component.html',
  styleUrls: ['./dashboard-registry-licensed-upload.component.scss']
})
export class DashboardRegistryLicensedUploadComponent implements OnInit {
  public files: any[] = [];
  public allowExtensions = ['png', 'jpg', 'jpeg', 'pdf'];
  public registryLicensedFiles: Array<UploadFile> = [];

  constructor(
    private translate: TranslateService,
    private alert: Alert,
    private dashboardService: DashboardService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscriptionService: SubscriptionService,
    private applicationService: ApplicationService,
    private errorHandler: ErrorHandler
  ) {}

  ngOnInit(): void {
    this.translate.use('pt');
  }

  bindingFile(files: Array<UploadFile>, type: FileType) {
    if (type === 'registryLicenced') {
       if(files.length === 0) this.registryLicensedFiles = [];
       else this.registryLicensedFiles.push(...files);
    }
  }

  submit() {
    if (this.registryLicensedFiles.length < 2) {
        return this.alert.warning('Envie pelo menos 1 registros e 1 certificado válido para que seja possível análise do nosso time.');
    }
    return this.uploadFiles();
  }

  uploadFiles() {
    this.spinner.show();
    this.dashboardService.sendFilesRegistryLicensed(this.registryLicensedFiles).subscribe(
    () => {
      this.loadSubscriptions();
      this.spinner.hide();
      this.alert.success("Obrigado! Os documentos serão analisados. Informaremos no seu e-mail em breve.");
    },
      (err: HttpErrorResponse) => {
        this.errorHandler.error(err, this.uploadFiles.bind(this));}
    );
  }

  loadSubscriptions() {
    this.subscriptionService.findAll().subscribe(
      (subscriptions) => {
        this.applicationService.setSubscriptions(subscriptions);
        this.router.navigate(['/dashboard']);
      },
      (err: HttpErrorResponse) => {
        if (err.status === 400) {
          this.router.navigate(['/dashboard/registryLicensed']);
        } else {
          this.applicationService.clearApplication();
          this.errorHandler.error(err, this.loadSubscriptions.bind(this));
        }
      }
    );
  }

  makeViewErrorsMessage(errors: Array<{ fieldName: string, message: string }>): string {
    let html = '';

    errors.forEach((err) =>
      html =
        `${html} <h4 style='color: #d63031; font-weight: 600; margin-left: 10px' align='left'>
				${err.message}
			</h4>`);

    return `<div style='max-height: 400px; overflow-y: auto;'>${html}</div>`;
  }

  nextStep(){
    if(this.applicationService.getUserFleet() === 'true'){
      this.router.navigate(['/fleet']);
    }else{
      this.router.navigate(['/dashboard']);
    }
  }
}
