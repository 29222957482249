import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavMobileService } from '../nav-mobile/nav-mobile.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { FineService } from '../../service/fine.service';
import { AuthService } from '../../service/Auth/auth.service';
import { DriveService } from '../../service/drive.service';

export interface Link {
	title: string;
	link: string;
	eventLabel: string;
	eventAction: string;
}
@Component({
	selector: 'app-shared-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {

	isChangeMyData: boolean = false;

	constructor(
		public router: Router,
		public navMobileService: NavMobileService,
		private applicationService: ApplicationService,
		private fineService: FineService,
		private authService: AuthService,
		private driveService: DriveService) {
	}

  	public links: Array<Link> = this.applicationService.getUserFleet() === 'true' ? this.getLinksFleet() : this.getLinks();

	@ViewChild('menu', { static: false }) public menu: ElementRef;

	existsFines = false;


	ngOnInit() {
		this.fineService.checkIfThereAreFines(this.applicationService.getUserLogged().token.uuid).subscribe((response) => {
			this.existsFines = <boolean>response;
		});

		this.driveService.getChangeStatus().subscribe(value => {
			this.isChangeMyData = value;
		});
	}

	checkRouter(linkItem: Link): boolean {
		return this.router.url === linkItem.link;
	}

	logout() {
		this.authService.logout();
	}

	goToRouter(route: string): void {
		if(!this.isChangeMyData) {
			this.router.navigate([route]);
		} else {
			localStorage.setItem('route', route);
			this.driveService.sendPermissionModal(true);
		}
	}

	getLinksFleet(): Array<Link> {
		return [
		{title: 'Minha frota', link: '/fleet', eventLabel: 'meus-veiculos', eventAction: 'menu-meus-veiculos'},
		{title: 'Multas', link: '/dashboard/fines', eventLabel: 'controle-de-multa', eventAction: 'menu-controle-de-multa'},
		// {title: 'Meus dados', link: '/dashboard/drivers', eventLabel: 'meus-dados', eventAction: 'menu-meus-dado'},
		{title: 'Extrato Geral', link: '/fleet/statement', eventLabel: 'extrato-geral', eventAction: 'menu-extrato-geral'}
    ];
	}

	getLinks(): Array<Link> {
		return [
		{title: 'Meu Veículo', link: '/dashboard', eventLabel: 'meus-veiculos', eventAction: 'menu-meus-veiculos'},
		// {title: 'Meus Dados', link: '/dashboard/drivers', eventLabel: 'meus-dados', eventAction: 'menu-meus-dado'},
		{title: 'Controle de Multas', link: '/dashboard/fines', eventLabel: 'controle-de-multa', eventAction: 'menu-controle-de-multa'},
		];
	}
}
