import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PaymentMethod, RequestStatusModel, StatusRequest, TransactionStatus} from "../../class/request-status.model";
import {SubscriptionService} from "../../service/subscription.service";

const menuData = require('./accordion-status.data.json').data;

@Component({
	selector: 'accordion-status',
	templateUrl: './accordion-status.component.html',
	styleUrls: ['./accordion-status.component.scss']
})
export class AccordionStatusComponent implements OnChanges, OnInit {

	menu = menuData;
  itens: any[] = [];
  paymentMethod = PaymentMethod.CREDIT_CARD;
  transactionStatus = TransactionStatus.PAID;
  requestStatus: RequestStatusModel;

  constructor(public subscriptionService: SubscriptionService) {
  }

  @Input() blockedContent: boolean;
  @Input() showDate: boolean;
  @Input() codeSubscription: number;

  ngOnChanges(changes: SimpleChanges): void {
    if(this.codeSubscription !== null)
       this.getStatusByCodeSubscription();
  }

  configData(statusZero: StatusRequest, statusOne: StatusRequest, statusTwo: StatusRequest, statusThree: StatusRequest, statusFour: StatusRequest, statusFive: StatusRequest, statusSix: StatusRequest, statusSeven: StatusRequest) {
    this.itens = [
      {status: statusZero, active: false , title: RequestStatusModel.getTitle(statusZero), description: RequestStatusModel.getDescription(statusZero)},
      {status: statusOne, active: false , title: RequestStatusModel.getTitle(statusOne), description: RequestStatusModel.getDescription(statusOne)},
      {status: statusTwo, active: false , title: RequestStatusModel.getTitle(statusTwo), description: RequestStatusModel.getDescription(statusTwo)},
      {status: statusThree, active: false , title: RequestStatusModel.getTitle(statusThree), description: RequestStatusModel.getDescription(statusThree)},
      {status: statusFour, active: false , title: RequestStatusModel.getTitle(statusFour), description: RequestStatusModel.getDescription(statusFour)},
      {status: statusFive, active: false , title: RequestStatusModel.getTitle(statusFive), description: RequestStatusModel.getDescription(statusFive)},
      {status: statusSix, active: false , title: RequestStatusModel.getTitle(statusSix), description: RequestStatusModel.getDescription(statusSix)},
      {status: statusSeven, active: false , title: RequestStatusModel.getTitle(statusSeven), description: RequestStatusModel.getDescription(statusSeven)}
    ]
  }

  getStatusByCodeSubscription(){
    this.subscriptionService.getAllStatus().subscribe(
      result =>{
        this.requestStatus = result;

        if (this.requestStatus.status.length > 0) {
          if (this.requestStatus.paymentMethod === PaymentMethod.CREDIT_CARD) {
            this.getOrderCreditCard();
            this.requestStatus.status.forEach(sts => { this.itens.find(item => item.status === sts).active = true; });
          } else {
            this.getOrderBoleto();
            this.requestStatus.status.forEach(sts => {  this.itens.find(item => item.status === sts).active = true; });
          }
        } else {
          if (this.requestStatus.paymentMethod === PaymentMethod.CREDIT_CARD) { this.getOrderCreditCard(); } else { this.getOrderBoleto(); }
        }
      },error => {
      }
    );
  }

  getOrderCreditCard(){
    this.configData(StatusRequest.PAYMENT, StatusRequest.PENDING_ANALYSE, StatusRequest.APPROVED, StatusRequest.VEHICLE_PRODUCED,
      StatusRequest.LICENSE_PLATE, StatusRequest.TRANSPORT, StatusRequest.DELIVERY_SCHEDULE, StatusRequest.VEHICLE_DELIVERED);
  }

  getOrderBoleto(){
    this.configData(StatusRequest.PENDING_ANALYSE, StatusRequest.APPROVED, StatusRequest.PAYMENT, StatusRequest.VEHICLE_PRODUCED,
      StatusRequest.LICENSE_PLATE, StatusRequest.TRANSPORT, StatusRequest.DELIVERY_SCHEDULE, StatusRequest.VEHICLE_DELIVERED);
  }

  ngOnInit(): void {
  }

}
