import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ReactiveFormsModule } from '@angular/forms'

// Components
import { DashboardUploadComponent } from './dashboard-upload/dashboard-upload.component';
import { DashboardVehicleComponent } from './dashboard-vehicle/dashboard-vehicle.component';
import { DashboardDriverComponent } from './dashboard-driver/dashboard-driver.component';
import { SharedModule } from './../../shared/shared.module';
import { CredcardComponent } from 'src/app/shared/components/credcard/credcard.component';
import { DriversTermsComponent } from './drivers-terms/drivers-terms.component';
import { UploadDocComponent } from 'src/app/shared/components/upload-doc/upload-doc.component';
import { UploadUpdateComponent } from 'src/app/shared/components/upload-update/upload-update.component';
import { DriverListComponent } from './driver-list/driver-list.component';
import { ContractFinishComponent } from './contract-finish/contract-finish.component';
import { LoadingComponent } from './loading/loading.component';
import { BreadcrumbComponent } from 'src/app/shared/components/breadcrumb/breadcrumb.component';
import { DriversDebitsComponent } from './drivers-debits/drivers-debits.component';
import { DashboardFinesComponent } from './dashboard-fines/dashboard-fines.component';
import { DashboardFineDetailComponent } from './dashboard-fines/detail/dashboard-fine-detail.component';

// Services
import { DashboardService } from './shared/dashboard.service';
import { HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

// PrimeNG
import {CardModule, CardModule as PNGCardModule} from 'primeng/card';
import {TableModule} from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import {DashboardContractorUploadComponent} from './dashboard-contractor-upload/dashboard-contractor-upload.component';
import {
  DashboardRegistryLicensedUploadComponent
} from "./dashboard-registry-licensed-upload/dashboard-registry-licensed-upload.component";
import {AccordionStatusComponent} from "../../shared/components/accordion-status/accordion-status.component";
import {PaymentHistoryComponent} from "../../shared/components/payment-history/payment-history.component";
import { DashboardSummaryModule } from './dashboard-summary/dashboard-summary.module';
import {PlateComponent} from "../../shared/components/plate/plate.component";
import {
  KilometersPercentageComponent
} from "../../shared/components/kilometers-percentage/kilometers-percentage.component";
import { DashboardStatementComponent } from './dashboard-statement/dashboard-statement.component';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ProgressBarModule } from 'primeng/progressbar';
import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';

export function HttpLoaderFactory(httpClient: HttpClient) {
	return new TranslateHttpLoader(httpClient);
}
@NgModule({
  declarations: [
    DashboardUploadComponent,
    DashboardVehicleComponent,
    DashboardDriverComponent,
    DriverListComponent,
    ContractFinishComponent,
    DriversTermsComponent,
    LoadingComponent,
    DriversDebitsComponent,
    DashboardFinesComponent,
    DashboardFineDetailComponent,
    DashboardContractorUploadComponent,
    DashboardRegistryLicensedUploadComponent,
    DashboardStatementComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    DashboardRoutingModule,
    SharedModule,
    CardModule,
    ReactiveFormsModule,
    DropdownModule,
    CalendarModule,
    OverlayPanelModule,
    TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			}
    }),
    PNGCardModule,
    TableModule,
    ButtonModule,
    DialogModule,
    ProgressBarModule,
    ToastModule,
    AccordionModule,
    DashboardSummaryModule
  ],
  exports: [
    TranslateModule
  ],
  entryComponents: [
    UploadDocComponent,
    UploadUpdateComponent,
    CredcardComponent,
    AccordionStatusComponent,
    PaymentHistoryComponent,
    PlateComponent,
    KilometersPercentageComponent
  ],
  providers: [
    DashboardService,
    BreadcrumbComponent,
    MessageService
  ]
})
export class DashboardModule { }
