import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {SubscriptionDetailFleet} from "../../models/subscription-detail.model";
import {truncate} from "fs";
import {start} from "repl";

@Component({
  selector: 'dates',
  templateUrl: './dates.component.html',
  styleUrls: ['./dates.component.scss']
})
export class DatesComponent implements OnInit{


  form: FormGroup;
  br: any;
  originalList = new Array < SubscriptionDetailFleet >();
  resultList: Array < SubscriptionDetailFleet >

  constructor(private formBuilder: FormBuilder) { }

  @Input() entryList: Array < SubscriptionDetailFleet >;
  @Input() close = new EventEmitter<boolean>();
  @Output() result = new EventEmitter<Array<SubscriptionDetailFleet>>();
  @Output() clear = new EventEmitter<boolean>();


  ngOnInit(): void {
    this.getForm();
    this.getMonths();

    this.change();
  }


  getForm() {
    this.form = this.formBuilder.group({
      start:   [false as boolean],
      startDate: new FormControl({value: '', disabled: true}),
      end: [false as boolean],
      endDate: new FormControl({value: '', disabled: true})
    });

    this.form.get('start').valueChanges.subscribe(value => {
      if(value === true){
        this.form.get('startDate').enable();
      }else { this.form.get('startDate').disable(); }
    });

    this.form.get('end').valueChanges.subscribe(value => {
      if(value === true){
        this.form.get('endDate').enable();
      }else { this.form.get('endDate').disable(); }
    });
  }

  change(){
    this.close.asObservable().subscribe( value => {
      if(value === true){
        this.getForm();
      }
    });
  }

  getClear(){
    this.getForm();
    this.clear.emit(true);
  }


  send() {
     if(this.form.get('start').value || this.form.get('end').value){
       this.filterList();
       console.log(this.form);
       this.result.emit(this.resultList);
     }else{
       this.getForm();
       this.result.emit(this.entryList);
     }

     console.log(this.form);
  }

  getMonths(){
    this.br = {
      monthNamesShort: [ "Jan", "Fev", "Mar", "Abr", "Maio", "Jun","Jul", "Ago", "Set", "Out", "Nov", "Dez" ],
    };
  }

  filterList(){
    if(this.form.get('start').value === true || this.form.get('end').value === true){
      if(this.form.get('start').value){
        const start = this.form.get('startDate').value as Date;
        this.resultList = this.entryList.filter(value => {
          const deliveryDate = value.plan.vehicle.deliveryDate;
          if(deliveryDate !== undefined) {
            if ((new Date(deliveryDate).getMonth() + 1 === start.getMonth() + 1) && (new Date(deliveryDate).getFullYear() === start.getFullYear())) {
              return value;
            }
          }
        });
      }else if(this.form.get('end').value){
        const end = this.form.get('endDate').value as Date;
        this.resultList = this.entryList.filter(value => {
          const terminated = new Date(value.terminated);
          if ((terminated.getMonth() + 1 === end.getMonth() + 1) && (terminated.getFullYear() === end.getFullYear())) {
            return value;
          }
        });
      }
    } else this.resultList = this.entryList;
  }
}
