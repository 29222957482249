import { Address } from "./customer-update.model";

export class PersonalInformation {
	id?: number;
	name?: string;
	email?: string;
	phone?: string;
	created?:Date;
	cpf?: string;
	rg?: string;
	issuer?: string;
	customer?: any;
    subscription?: any;
	address?: Address = new Address();
}