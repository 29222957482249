import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandler } from 'src/app/shared/class/ErrorHandler';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';
import { PaymentMethod, PaymentStatus, PaymentType, Statement } from 'src/app/shared/models/statement.model';
import { Alert } from 'src/app/shared/class/alert';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-dashboard-statement',
  templateUrl: './dashboard-statement.component.html',
  styleUrls: ['./dashboard-statement.component.scss'],
})
export class DashboardStatementComponent implements OnInit {
  uuid: string;
  statements: Statement[];
  originalStatements: Statement[];
  statement: Statement;

  show = false;
  detailsText: string;
  detailsPlate: string;
  sortState: any = { key: '', asc: true }

  currentQ: string = '';
  timer: any;

  actionMenu: HTMLElement;
  actionMenuHeight: number;

  checkBoxBegin: boolean = false;
  checkBoxEnd: boolean = false;
  dateFilterBeginParam: Date;
  dateFilterEndParam: Date;

  showPaymentFilter: boolean = false;
  showContractFilter: boolean = false;

  allPaymentType = false;

  paymentType = [
    {type: 'Acessórios', selected: true },
    {type: 'Assinatura', selected: true },
    {type: 'Estorno', selected: true },
    {type: 'Extra', selected: true },
    {type: 'Informativo', selected: true },
    {type: 'Multa', selected: true },
    {type: 'Multa de Cancelamento', selected: true },
    {type: 'Primeira Transação', selected: true },
    {type: 'Quilometragem Adicional', selected: true }
  ]

  pt: any;

  @ViewChild('actionMenu', { static: true }) actionMenuOverlay: OverlayPanel;

  constructor(
    public spinner: NgxSpinnerService,
    private translate: TranslateService,
    private subscriptionService: SubscriptionService,
    private alert: Alert
  ) { }

  ngOnInit(): void {
    this.translate.use('pt');

    this.pt = {
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun','Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje',
      clear: 'Limpar',
      dateFormat: 'mm/dd/yy',
      weekHeader: 'Wk'
  };

    this.getStatements();
  }

  getStatements() {
    this.spinner.show();

    this.subscriptionService.getAllStatements().subscribe(
      (data) => {
        this.statements = data;
        this.statements.forEach(statement => {
          statement.plate = statement.plate ? statement.plate : 'Breve';
          statement.paymentMethod = PaymentMethod[statement.paymentMethod];
          statement.paymentStatus = PaymentStatus[statement.paymentStatus];
          statement.paymentType = PaymentType[statement.paymentType];
        });

        this.sort('createdDate');
        this.originalStatements = this.statements;
        this.spinner.hide();
      }
    );
  }

  showDetails(statement?: Statement) {
    if (statement == undefined) statement = this.statement;

    this.show = true;
    this.detailsText = statement.details;
    this.detailsPlate = statement.plate;
  }

  hideDetails() {
    this.show = false;
    this.detailsText = undefined;
    this.detailsPlate = undefined;
  }

  resetSort() {
    this.statements = this.originalStatements;
    this.sort('createdDate');
  }

  sort(key: string) {
    if (key == 'createdDate') {
      this.sortState.key = key;
      this.sortState.asc = true;
    }
    else {
      if (this.sortState.key == key) {
        if (this.sortState.asc) this.sortState.asc = false;
        else {
          this.sort('createdDate');
          return;
        }
      }
      else {
        this.sortState.key = key;
        this.sortState.asc = true;
      }
    }

    var mod = 0;

    if (this.sortState.asc) mod = 1;
    else mod = -1;

    this.statements.sort((a, b) => {
      if (a[key] < b[key]){
        return -1 * mod;
      }
      if (a[key] > b[key]){
        return 1  * mod;
      }

      return 0;
    });
  }

  liveSearch(q: string) {
    if (this.statements == undefined || this.statements.length == 0) return
    
    if (this.currentQ === q) {
      return
    }
    else {
      this.currentQ = q;
    }
    
    clearTimeout(this.timer);

    this.timer = undefined;
    
    this.timer = setTimeout(() => {
      this.search(this.currentQ);
      this.timer = undefined;
    }, 1000);
  }

  clearSearch(target: any) {
    target.value = '';
    this.currentQ = '';
    this.search(this.currentQ);
  }

  search(searchTerm: string) {
    this.resetSort();
    this.clearContractRangeFilter();
    this.clearPaymentTypeFilter();

    this.statements = this.originalStatements;
    const keys: string[] = ['beginSubDate', 'endSubDate', 'paymentMethod', 'paymentStatus', 'paymentType', 'plate', 'vehicle', 'version'];
    this.statements = this.statements.filter(o => keys.some(k => String(o[k]).toLowerCase().includes(searchTerm.toLowerCase())));
  }

  showActionMenu(event: any, target: any, statement: Statement) {
    this.statement = statement;
    this.actionMenuOverlay.toggle(event, target);
  }

  clearPaymentTypeFilter() {
    this.statements = this.originalStatements;
    this.allPaymentType = false;
    this.paymentType.forEach(value => value.selected = true);
  }

  togglePaymentTypeFilter(type: any) {
    type.selected = !type.selected;
  }

  allPaymentTypeFilter() {
    this.allPaymentType = !this.allPaymentType;
  }

  applyPaymentTypeFilter() {
    this.resetSort();
    this.clearContractRangeFilter();

    this.statements = this.originalStatements;

    var filteredPaymentTypes;

    if (!this.allPaymentType) filteredPaymentTypes = this.paymentType.filter(o => o.selected)
    else filteredPaymentTypes = this.paymentType;

    this.statements = this.statements.filter(o => filteredPaymentTypes.some(k => k.type.toLowerCase() == o.paymentType.toLowerCase()));
  }

  resetPaymentTypeFilter() {
    this.allPaymentType = false;
    this.paymentType.forEach(type => type.selected = false);
  }

  showPaymentFilterModal() {
    this.showPaymentFilter = true;
  }

  hidePaymentFilterModal() {
    this.showPaymentFilter = false;
  }

  showContractFilterModal() {
    this.showContractFilter = true;
  }

  hideContractFilterModal() {
    this.showContractFilter = false;
  }

  togglecheckBoxBegin() {
    this.checkBoxBegin = !this.checkBoxBegin;
  }

  togglecheckBoxEnd() {
    this.checkBoxEnd = !this.checkBoxEnd;
  }

  clearContractRangeFilter() {
    this.checkBoxBegin = false;
    this.checkBoxEnd = false;
    this.dateFilterBeginParam = undefined;
    this.dateFilterEndParam = undefined;
  }

  resetContractRangeFilter() {
    this.resetSort();
    this.clearPaymentTypeFilter();
    this.clearContractRangeFilter();
  }

  applyContractRangeFilter(checkBoxBegin: boolean, checkBoxEnd: boolean) {
    this.resetSort();
    this.clearPaymentTypeFilter();

    this.statements = this.originalStatements;
    if (checkBoxBegin) this.applyBeginDateFilter(this.dateFilterBeginParam);
    if (checkBoxEnd) this.applyEndDateFilter(this.dateFilterEndParam);
  }

  applyBeginDateFilter(beginDate: Date) {
    this.statements = this.statements.filter(o => new Date(o.beginSubDate) >= beginDate);
  }

  applyEndDateFilter(endDate: Date) {
    this.statements = this.statements.filter(o => {
      if (o.endSubDate == undefined || o.endSubDate === '') return false;
      return new Date(o.endSubDate) < this.addMonths(endDate, 1);
    });
  }

  addMonths(date: Date, months: number) {
    var day = date.getDate();
    var newDate = new Date(date);

    newDate.setMonth(newDate.getMonth() + months);

    if (newDate.getDate() != day) {
      newDate.setDate(0);
    }

    return newDate;
  }

	downloadTicketSAPP(ticketId: string){
		this.spinner.show();
		this.subscriptionService.downloadBoletoSAPP(ticketId).subscribe(result => {
			this.spinner.hide();
      const blob = new Blob([result], {type: 'application/pdf'});
			const url = window.URL.createObjectURL(blob);
			var link = document.createElement('a');
			link.href = url;
			link.download = "boleto_".concat(ticketId).concat('.pdf');
			link.click();
			this.alert.success("Boleto Gerado!");
		}, error => {
			this.spinner.hide();
			this.alert.error("Ocorreu um erro ao gerar o boleto!");
		});
	}
}
