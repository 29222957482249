import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { DashboardSummaryComponent } from './dashboard-summary.component';
import { FilterSummaryComponent } from './component/filter-summary/filter-summary.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';

const routes: Routes = [];

@NgModule({
  declarations: [
		DashboardSummaryComponent,
		FilterSummaryComponent,
	],
	imports: [
		CommonModule,
		SharedModule,
		ReactiveFormsModule,
		FormsModule,
		RouterModule.forChild(routes),
	],
	exports: [
		DashboardSummaryComponent

	],
	providers: [
		SubscriptionService,
	],
	entryComponents: [
		//ExtractFilterMobileComponent,
	]
})
export class DashboardSummaryModule { }
