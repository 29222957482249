import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Alert } from 'src/app/shared/class/alert';
import { UserService } from 'src/app/shared/service/User/user.service';
import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { HttpErrorHandleService } from 'src/app/shared/service/data/http-error-handle/http-error-handle.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public forgotPasswordForm: FormGroup;
  public buttonDisable = true;
  public type = '';

  constructor(
    private alert: Alert,
    private userService: UserService,
    private errorHandler: HttpErrorHandleService,
    private translate: TranslateService,
    private router: Router,
    private param: ActivatedRoute
  ) {
    this.forgotPasswordForm = new FormGroup({
      username: new FormControl(null, Validators.compose([Validators.required, Validators.email]))
    });
  }

  ngOnInit() {
    this.translate.use('pt');

    this.forgotPasswordForm.valueChanges.subscribe(() => {
      if(this.forgotPasswordForm.controls.username.valid){
        this.buttonDisable = false
      } else {
        this.buttonDisable = true;
      }
    })

    this.param.queryParams.subscribe(parm => {
      this.type = parm.type;
    });

  }

  forgotPassword(): void {
    if (this.type === 'new') {
      this.userService.newPassword(this.forgotPasswordForm.value).subscribe(
        (response: HttpEvent<Object>) => {
          if (response.type === HttpEventType.Response) {
            const translatedKey = "alert." + response.status.toString() + ".forgotPassword";

            this.translate.get(translatedKey).subscribe(msg =>
              this.alert.checkEmail('Pode levar alguns minutos para chegar. Verifique sua caixa de Spam.')
                .then(() => this.navigateToLogin()));

          }
        },
        (err: HttpErrorResponse) => {
          this.errorHandler.getErrorMessage(err, 'newPassword').subscribe(
            (translatedText: string) => {
              this.alert.error(translatedText);
            }
          );
        }
      );

    } else {

      this.userService.setPassword(this.forgotPasswordForm.value).subscribe(
        (response: HttpEvent<Object>) => {
          if (response.type === HttpEventType.Response) {
            const translatedKey = "alert." + response.status.toString() + ".forgotPassword";

            this.translate.get(translatedKey).subscribe(msg =>
              this.alert.checkEmail('Pode levar alguns minutos para chegar. Verifique sua caixa de Spam.')
                .then(() => this.navigateToLogin()));

          }
        },
        (err: HttpErrorResponse) => {
          this.errorHandler.getErrorMessage(err, 'forgotPassword').subscribe(
            (translatedText: string) => {
              this.alert.error(translatedText);
            }
          );
        }
      );
    }
  }

  navigateToLogin() {
    this.router.navigate(['/']);
  }

  validatorEmail() {
    if (this.forgotPasswordForm.status !== 'VALID') {
      return 'E-mal inválido';
    } else {
      return '';
    }
  }

}
