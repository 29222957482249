import { Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';

@Pipe({ name: 'validateFormInput', pure: false })
export class ValidateFormInputPipe implements PipeTransform {
	transform(form: FormControl): string {
		if (form.touched && form.invalid) {
			return 'is-invalid';
		}

		return '';
	}
}

@Pipe({ name: 'validateFormInputFeedback', pure: false })
export class ValidateFormInputFeedbackPipe implements PipeTransform {
	transform(control: FormControl) {
		if (control.invalid && control.touched) {
			return this.errList(control);
		}

		return '';
	}

	errList(control: FormControl) {
		const errors = [];

		for (const [error, data] of Object.entries(control.errors)) {
			switch (error) {
				case 'resetPassword':
					errors.push(data);
					break;
				case 'required':
					errors.push('Senha inválida');
					break;
				default:
					errors.push('');
					break;
			}
		}

		return errors;
	}
}


@Pipe({ name: 'validateFormInputFeedbackCreditCard', pure: false })
export class validateFormInputFeedbackCreditCard implements PipeTransform {
	transform(control: FormControl) {
		if (control.invalid && control.touched) {
			return this.errList(control);
		}

		return '';
	}

	errList(control: FormControl) {
		for (const [error] of Object.entries(control.errors)) {
			switch (error) {
				case 'required':
					return 'O campo é obrigatório';
				case 'email':
					return 'Informe um e-mail válido';
				case 'phone':
					return 'Informe um telefone válido';
				default:
					return '';
			}
		}
	}
}


