export type payment = 'CREDIT_CARD' | 'BOLETO' | string;

export class Pendency {

    constructor(
        public code?: string,
        public creditCard?: string | null,
        public type?: {
            type?: string,
            description?: string,
        },
        public status?: {
            status?: string,
            description?: string,
        },
        public paymentMethod?: string,
        public date?: string,
        public amount?: string,
        public description?: string,
        public boletoUrl?: string,
        public charged?: string,
    ) { }

    get mounthList() {
        const days = String(this.date).split('/')[0];
        const mounths = String(this.date).split('/')[1].replace('0', '');
        switch (mounths) {
            case '1':
                return `${days} /jan`;
            case '2':
                return `${days} /fev`;
            case '3':
                return `${days} /mar`;
            case '4':
                return `${days} /abr`;
            case '5':
                return `${days} /mai`;
            case '6':
                return `${days} /jun`;
            case '7':
                return `${days} /jul`;
            case '8':
                return `${days} /ago`;
            case '9':
                return `${days} /set`;
            case '10':
                return `${days} /out`;
            case '11':
                return `${days} /nov`;
            case '12':
                return `${days} /dez`;
        }
    }

    get paymentList() {
        return {
            CREDIT_CARD: 'CREDIT_CARD',
            BOLETO: 'BOLETO',
        }
    }

    get translatePayment() {
        switch (this.paymentMethod) {
            case this.paymentList.CREDIT_CARD:
                return 'Cartão de crédito';
            case this.paymentList.BOLETO:
                return 'Boleto bancário';
            default:
                throw new Error('status not found');
        }
    }

    static fromJson(jsonData: any): Pendency {
        const element = {};

        for (const [key, value] of Object.entries(jsonData)) {
            if (typeof (value) === 'string') {
                Object.assign(element, { [key]: String(value).trim() });
            } else {
                Object.assign(element, { [key]: value });
            }
        }

        return Object.assign(new Pendency(), element);
    }

    static fromJsonArr(jsonData: Array<any>): Array<Pendency> {
        const elementArr: Array<Pendency> = [];

        for (const subscription of jsonData as Array<any>) {
            elementArr.push(Pendency.fromJson(subscription));
        }

        return elementArr;
    }
}
