import { Injectable } from '@angular/core';
import { Subscription } from '../../shared/models/subscription.model';
import { environment } from '../../../environments/environment';
import { User } from '../../shared/service/User/user';
import { SummaryFilterFields,SummaryStatusFields } from 'src/app/pages/dashboard/dashboard-summary/component/filter-summary/filter-summary.component';
import { BehaviorSubject } from 'rxjs';
import { Type } from 'src/app/pages/dashboard/dashboard-summary/component/filter-summary/filter-summary.component';
import {FleetStatus} from "../../shared/models/subscription-detail.model";

export interface SubscriptionStorage {
	active: string;
	subscriptions: Array<Subscription>;
}

@Injectable({ providedIn: 'root' })
export class ApplicationService {
	getUserLogged(): User {
		return JSON.parse(localStorage.getItem(environment.storage.user)) as User;
	}

	setUserLogged(user: User) {
		localStorage.setItem(environment.storage.user, JSON.stringify(user));
	}

	getSubscriptions(): Array<Subscription> {
		const subsciptions = localStorage.getItem(environment.storage.subscriptions);
		if (subsciptions) {
			const subs = JSON.parse(subsciptions) as SubscriptionStorage;
			return subs.subscriptions;
		}

		return undefined;

	}

	setSubscriptions(subscriptions: Array<Subscription>) {
		localStorage.setItem(environment.storage.subscriptions, JSON.stringify({
			active: subscriptions[0].uuid,
			subscriptions,
		} as SubscriptionStorage));
	}

	getActiveSubscription(): Subscription {
		const subs = <SubscriptionStorage>JSON.parse(localStorage.getItem(environment.storage.subscriptions));
		return subs.subscriptions.filter(sub => sub.uuid === subs.active)[0];
	}

	changeActiveSubscription(subscriptionId: string) {
		const subs = <SubscriptionStorage>JSON.parse(localStorage.getItem(environment.storage.subscriptions));
		subs.active = subscriptionId;
		localStorage.setItem(environment.storage.subscriptions, JSON.stringify(subs));
	}

	clearApplication() {
		localStorage.clear();
	}

	getRefreshToken() {
		try {
			return JSON.parse(localStorage.getItem(environment.storage.user)).token.refresh_token;
		} catch (error) {
			return null;
		}
	}

	public summaryData = new BehaviorSubject({});

	public summaryFilterMobile = new BehaviorSubject({
		date: null,
		type: null,
	} as SummaryFilterFields);

	public extractStatusFilter = new BehaviorSubject({
		paid: null,
		refused: null,
		pendingSlip: null,
		processing:null,
		authorized:null,
		refunded:null,
		pendingRefund: null,
		chargedback: null,

	} as SummaryStatusFields);

  setUserFleet(fleet: string) {
    localStorage.setItem('fleet', fleet);
  }

  getUserFleet(): string {
    return  localStorage.getItem('fleet');
  }

  setActiveSubscriptionFleet(subscriptionId: string) {
    localStorage.setItem('activeSubscription', subscriptionId);
  }

  getActiveSubscriptionFleet() {
    return localStorage.getItem('activeSubscription');
  }

  setStatusFleet(status: string) {
    localStorage.setItem('statusFleet', status.toString());
  }

  getStatusFleet() {
    return localStorage.getItem('statusFleet');
  }
}
