import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';

import {DashboardUploadComponent} from './dashboard-upload/dashboard-upload.component';
import {DashboardVehicleComponent} from './dashboard-vehicle/dashboard-vehicle.component';
import {DashboardDriverComponent} from './dashboard-driver/dashboard-driver.component';
import {DashboardSummaryComponent} from './dashboard-summary/dashboard-summary.component';
import {DriverListComponent} from './driver-list/driver-list.component';
import {ContractFinishComponent} from './contract-finish/contract-finish.component';
import {DriversTermsComponent} from './drivers-terms/drivers-terms.component';
import {LoadingComponent} from './loading/loading.component';
import {DriversDebitsComponent} from './drivers-debits/drivers-debits.component';
import {DashboardFinesComponent} from './dashboard-fines/dashboard-fines.component';
import {DashboardFineDetailComponent} from './dashboard-fines/detail/dashboard-fine-detail.component';
import {DashboardContractorUploadComponent} from './dashboard-contractor-upload/dashboard-contractor-upload.component';
import {
  DashboardRegistryLicensedUploadComponent
} from "./dashboard-registry-licensed-upload/dashboard-registry-licensed-upload.component";
import { DashboardStatementComponent } from './dashboard-statement/dashboard-statement.component';
import {DriverGuard} from "./dashboard-driver/driver.guard";

export const routes: Routes = [
  { path: '', component: DashboardVehicleComponent },
  { path: 'upload', component: DashboardUploadComponent },
  { path: 'drivers', component: DashboardDriverComponent, canActivate: [DriverGuard]},
  { path: 'summary', component: DashboardSummaryComponent },
  { path: 'driveList', component: DriverListComponent },
  { path: 'terms/:driverId', component: DriversTermsComponent },
  { path: 'contractFinished', component: ContractFinishComponent },
  { path: 'drivesDebts', component: DriversDebitsComponent },
  { path: 'loading', component: LoadingComponent },
  { path: 'fines', component: DashboardFinesComponent },
  { path: 'fines/detail', component: DashboardFineDetailComponent},
  { path: 'contractor', component: DashboardContractorUploadComponent},
  { path: 'registryLicensed', component: DashboardRegistryLicensedUploadComponent}
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ]
})
export class DashboardRoutingModule {}
