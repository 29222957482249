import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'plate',
  templateUrl: './plate.component.html',
  styleUrls: ['./plate.component.scss']
})
export class PlateComponent  {
  constructor() { }
  @Input() plate: string;
}
