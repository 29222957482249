import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {HttpErrorResponse} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpErrorHandleService} from '../../service/data/http-error-handle/http-error-handle.service';
import {ApplicationService} from 'src/app/core/services/application.service';
import {SubscriptionService} from '../../service/subscription.service';
import {Alert} from 'src/app/shared/class/alert';
import {UploadUpdateComponent} from '../upload-update/upload-update.component';
import {mode as FileType, UploadFile} from '../../../shared/components/upload/upload.interface';
import {Driver} from '../../models/driver.model';

export interface UploadDocModal {
  mainDriver?: boolean;
  driver: Driver;
}

@Component({
  selector: 'upload-doc',
  templateUrl: './upload-doc.component.html',
  styleUrls: ['./upload-doc.component.scss']
})
export class UploadDocComponent implements OnInit {
  public files: any[] = [];
  public allowExtensions = ['png', 'jpg', 'jpeg', 'pdf'];
  public filesCnh: Array<UploadFile> = [];
  public filesPhoto: Array<UploadFile> = [];
  public mainDriver = false;

  public uploadForm: FormGroup;

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private alert: Alert,
    public dialogRef: MatDialogRef<UploadUpdateComponent>,
    private spinner: NgxSpinnerService,
    private subscriptionService: SubscriptionService,
    @Inject(MAT_DIALOG_DATA) public uploadDocModal: UploadDocModal,
  ) {
  }

  ngOnInit(): void {
    this.translate.use('pt');
    this.buildForm();

    if (this.uploadDocModal.mainDriver != null) {
      this.mainDriver = this.uploadDocModal.mainDriver;
    }
  }

  bindingFile(files: Array<UploadFile>, type: FileType) {
    if (type === 'cnh') {
      this.filesCnh = files;
    } else if (type === 'self') {
      this.filesPhoto = files;
    }
  }

  buildForm(): void {
    this.uploadForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      cpf: ['', [Validators.required, Validators.minLength(13)]],
    });
  }

  submit() {
    if (!this.uploadForm.valid) {
      return this.uploadForm.markAllAsTouched();
    }

    if (this.filesCnh[0] && this.filesPhoto[0]) {
      return this.mainDriver === false ? this.createDriver() : this.createMainDriver();
    }

    let msg: string;

    if (!this.filesCnh[0]) {
      msg = 'O envio da CNH é obrigatório';
    } else {
      msg = 'O envio da Foto é obrigatório';
    }

    return this.alert.warning(msg);
  }

  createDriver() {
    this.spinner.show();

    this.subscriptionService.addDriver({
      driverImages: {
        cnhComplete: this.filesCnh[0].base64,
        faceMatch: this.filesPhoto[0].base64,
      },
      name: this.uploadForm.get('name').value.trim(),
      cpf: String(this.uploadForm.get('cpf').value).replace(/\.|\,|\-/g, '').trimLeft().trimRight(),
    }).subscribe(
      () => {
        this.spinner.hide();
        this.alert.success('Documentos recebidos com sucesso!<br>Os documentos serão analisados e em breve o motorista será adicionado.').then(() => this.closeDialog());
      },
      (err: HttpErrorResponse) => {
        const response = err.error;

        if (response.errors) {
          const errors = [];

          response.errors.forEach((err: { fieldName: string, message: string }) => errors.push(err.message));
          this.spinner.hide();
          return this.alert.multipleErrors(errors);
        }

        this.spinner.hide();
        return this.alert.error(response.message);
      }
    );
  };

  createMainDriver() {
    this.spinner.show();

    this.subscriptionService.addMainDriver({
      driverImages: {
        cnhComplete: this.filesCnh[0].base64,
        faceMatch: this.filesPhoto[0].base64,
      },
      name: this.uploadForm.get('name').value.trim(),
      cpf: String(this.uploadForm.get('cpf').value).replace(/\.|\,|\-/g, '').trimLeft().trimRight(),
    }).subscribe(
      () => {
        this.spinner.hide();
        this.alert.success('Documentos recebidos com sucesso!<br>Os documentos serão analisados e em breve o motorista será adicionado').then(() => this.closeDialog());
      },
      (err: HttpErrorResponse) => {
        const response = err.error;

        if (response.errors) {
          const errors = [];

          response.errors.forEach((err: { fieldName: string, message: string }) => errors.push(err.message));
          this.spinner.hide();
          return this.alert.multipleErrors(errors);
        }

        this.spinner.hide();
        return this.alert.error(response.message);
      }
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
