import {StatusRequest} from "../class/request-status.model";

interface Plan {
	amount ? : number;
	maxKm ? : number;
	vehicle ? : Vehicle;
	maxMonth ? : number;
};

interface Vehicle {
	model ? : string;
	version ? : string;
	color ? : string;
	plate ? : string | null;
	renavam ? : string | null;
	chassis ? : string | null;
	deliveryDate ? : Date | null;
}

interface Dealer {
	id ? : string;
	name ? : string;
	number ? : string;
	email ? : string;
	latitude ? : string;
	longitude ? : string;
	mapImage ? : any;
}

interface Telemetry {
	id ? : string;
	vin ? : string | null;
	odometer ? : number | null;
	odometerRound ? : number | null;
	updateTime ? : Date | null;
	updateTimeInText ? : string | null;
	updatedQuarter ? : Date | null;
	quarterOdometer ? : number | null;
	updatedMensal ? : Date | null;
	mensalOdometer ? : number | null;
}

export class SubscriptionDetail {
	constructor(
		public code ? : number,
		public plan ? : Plan,
		public dealer ? : Dealer,
		public creditCard ? : string,
		public agreement ? : boolean,
		public veloe ? : boolean,
		public terminated ? : Date | null,
		public status ? : boolean,
		public cancelled ? : boolean,
		public finalized ? : boolean,
		public existsPendency ? : boolean,
		public cancellationRequest ? : boolean | Date,
		public contractor ? : string,
		public mainDriver ? : string,
		public cnpj ? : boolean,
		public needMainDriver ? : boolean,
		public telemetry ? : Telemetry,
		public monthLabel ? : string,
		public mensalOdometer ? : number,
		public monthConsumePercentage ? : number,
		public trimesterLabel ? : string,
		public quarterOdometer ? : number,
		public quarterConsumePercentage ? : number,
		public fleetOwner?:boolean,
		public paymentMethod?:string,
		public deadLine?: number,
		public restrictedDay?:string,
		public isExcessKm ?: boolean,
  	) { this.isExcessKm = false}

	static fromJson(jsonData: any): SubscriptionDetail {
		const element = {};

		for (const [key, value] of Object.entries(jsonData)) {
			if (typeof (value) === 'string') {
				Object.assign(element, {
					[key]: String(value).trim()
				});
			} else {
				Object.assign(element, {
					[key]: value
				});
			}
		}

		return element;
	}
}

export class SubscriptionDetailFleet{
  constructor(
    public code ? : number,
    public uuid ? : string,
    public plan ? : Plan,
    public terminated ? : Date | null,
    public status ? : FleetStatus,
    public monthLabel ? : string,
    public mensalOdometer ? : number,
    public monthConsumePercentage ? : number,
    public trimesterLabel ? : string,
    public quarterOdometer ? : number,
    public quarterConsumePercentage ? : number,
    public isExcessKm ? : boolean,
  ) { this.isExcessKm = false}

  static fromJson(jsonData: any): SubscriptionDetailFleet {
    const element = {};

    for (const [key, value] of Object.entries(jsonData)) {
      if (typeof (value) === 'string') {
        Object.assign(element, {
          [key]: String(value).trim()
        });
      } else {
        Object.assign(element, {
          [key]: value
        });
      }
    }

    return element;
  }
}

export enum FleetStatus {
    PAYMENT ,
    PENDING_ANALYSE ,
    APPROVED  ,
    VEHICLE_PRODUCED ,
    LICENSE_PLATE ,
    TRANSPORT  ,
    DELIVERY_SCHEDULE ,
    VEHICLE_DELIVERED  ,
    CONNECTED  ,
    DISCONNECTED  ,
    CANCELED ,
    FINALIZED ,
    WITHOUT
}



