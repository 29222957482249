import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Alert } from 'src/app/shared/class/alert';
import { Info, User } from 'src/app/shared/service/User/user';
import { UserService } from 'src/app/shared/service/User/user.service';
import { ErrorHandler } from 'src/app/shared/class/ErrorHandler';

import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { SubscriptionDetail } from 'src/app/shared/models/subscription-detail.model';
import { DriveService } from 'src/app/shared/service/drive.service';
import { ExternalService } from 'src/app/shared/service/external.service';
import { PersonalInformation } from 'src/app/shared/models/personal-info.model';
import { DashboardDriverConstants } from './dashboard-driver.contants';
import { Address, CustomerUpdate } from 'src/app/shared/models/customer-update.model';

@Component({
  selector: 'app-dashboard-driver',
  templateUrl: './dashboard-driver.component.html',
  styleUrls: ['./dashboard-driver.component.scss'],
})
export class DashboardDriverComponent implements OnInit {
  public user: User;
  public form: FormGroup;
  lastData: User;
  public sub = new SubscriptionDetail;
  public personalInfo: PersonalInformation;
  public stateList: Array<any> = DashboardDriverConstants.STATE_LIST;

  public customerUpdate: CustomerUpdate;

  public isEditing: boolean = false;
  public isPJ: boolean = false;
  public isFleet: boolean = false;
  private firstFindCep: boolean = true;


  @ViewChild('dialogSair', { static: true }) dialogSair: TemplateRef<any>;

  constructor(
    public spinner: NgxSpinnerService,
    public http: HttpClient,
    public dialog:MatDialog, 
    public routesChange:Router,
    protected injector: Injector,
    private userService: UserService,
    private errorHandler: ErrorHandler,
    private translate: TranslateService,
    private alert: Alert,
    private fb: FormBuilder,
    private driveService: DriveService,
    private externalService: ExternalService
  ) {
   
  }

  ngOnInit(): void {
    this.translate.use('pt');
    this.buildForm();
    this.loadDriverData();
    this.driveService.getPermissionModal().subscribe(perm => {
      if(perm) {
        this.dialog.open(this.dialogSair);
      }
    })

    this.form.valueChanges.subscribe(() => {
      if(this.form.dirty) {
        this.driveService.sendChangeStatus(true);
      } else {
        this.driveService.clearStatus();
      }
    });
  }

  buildForm() {
    this.form = this.fb.group({
      name: [{value:'', disabled: true}, [Validators.minLength(6)]],
      email: [{value:'', disabled: true}, [Validators.minLength(6)]],
      document: [{value:'', disabled: true}],
      mobile: [{value:'', disabled: true}, [Validators.minLength(15)]],
      street: [{value:'', disabled: true}],
      number: [{value:'', disabled: true}],
      complement: [{value:'', disabled: true}],
      neighborhood: [{value:'', disabled: true}],
      city: [{value:'', disabled: true}],
      state: [{value:'', disabled: true}, [Validators.minLength(2), Validators.maxLength(2)]],
      postalcode: [{value:'', disabled: true}, [Validators.minLength(8), Validators.maxLength(9)]],
      nothingAddressNumber: [false as boolean],
      rg: [{value:'', disabled: true},],
      organIssues: [{value:'', disabled: true}],
      namePersonal: [{value:'', disabled: true}, [Validators.minLength(6)]],
			cpfPersonal: [{value:'', disabled: true}],
			rgPersonal: [{value:'', disabled: true}],
			issuerPersonal: [{value:'', disabled: true}],
			mobilePersonal: [{value:'', disabled: true}, [Validators.minLength(15)]],
			emailPersonal: [{value:'', disabled: true}, [Validators.minLength(6)]],
      postalcodePersonal: [{value:'', disabled: true}],
      numberPersonal: [{value:'', disabled: true}],
      complementPersonal: [{value:'', disabled: true}],
      neighborhoodPersonal: [{value:'', disabled: true}],
      cityPersonal: [{value:'', disabled: true}],
      streetPersonal: [{value:'', disabled: true}],
      statePersonal: [{value:'', disabled: true}],
      nothingAddressNumberPersonal: [false as boolean],
    });

  }

  onChangeFieldNumbers(): void {
    this.form.get('nothingAddressNumber').valueChanges.subscribe((marked) => {
			if (marked) {
				this.form.get('number').setValue('');
				return this.form.get('number').disable();
			}

			return this.form.get('number').enable();
		});

    this.form.get('nothingAddressNumberPersonal').valueChanges.subscribe((marked) => {
			if (marked) {
				this.form.get('numberPersonal').setValue('');
				return this.form.get('numberPersonal').disable();
			}

			return this.form.get('numberPersonal').enable();
		});
  }

  patchFormData(): void {
    this.isPJ = this.user.info.document.length > 11;
    this.isFleet = this.isPJ && this.user.info.personalInformation == undefined;

    if (!this.isPJ) {
      this.form.controls['rg'].setValue(this.user.info.driver.rg);
      this.form.controls['organIssues'].setValue(this.user.info.driver.issuer);
    } else if (this.isPJ && !this.isFleet) {
      this.form.controls['rg'].setValue(this.user.info.personalInformation?.rg);
      this.form.controls['organIssues'].setValue(this.user.info.personalInformation?.issuer);
    }

    if (this.isPJ && this.isFleet) {
      this.populaFormFleet(this.user.info);
    } else if (this.isPJ && !this.isFleet) {
			this.populateFormPj(this.user.info);
		} else {
			this.populateFormPf(this.user.info);
		}

    setTimeout(() => {
      this.firstFindCep = false;
    }, 2000);
    
    if (this.user.info.address.number != 0) {
      this.form.controls.number.setValue(this.user.info.address.number);
    } else {
      this.form.controls.nothingAddressNumber.setValue(true);
    }
  }

  populaFormFleet(info: Info): void {
    this.form.patchValue({
      name: info?.name,
      document: info?.document,
      email: info?.email,
      mobile: info?.mobile,
      postalcode: info?.address?.postalCode,
      street: info?.address?.street,
      number: info?.address?.number,
      complement: info?.address?.complement,
      neighborhood: info?.address?.neighborhood,
      city: info?.address?.city,
      state: info?.address?.state
    });
  }

  populateFormPj(info: Info): void {
    this.form.patchValue({
      name: info?.name,
      document: info?.document,
      email: info?.email,
      mobile: info?.mobile,
      postalcode: info?.address?.postalCode,
      street: info?.address?.street,
      number: info?.address?.number,
      complement: info?.address?.complement,
      neighborhood: info?.address?.neighborhood,
      city: info?.address?.city,
      state: info?.address?.state,
      namePersonal: info?.personalInformation?.name,
      cpfPersonal: info?.personalInformation?.cpf,
      rgPersonal: info?.personalInformation?.rg,
      issuerPersonal: info?.personalInformation?.issuer,
      mobilePersonal: this.convertPhone(info?.personalInformation?.phone),
      emailPersonal: info?.personalInformation?.email,
      streetPersonal: info?.personalInformation?.address?.street,
      complementPersonal: info?.personalInformation?.address?.complement,
      neighborhoodPersonal: info?.personalInformation?.address?.neighborhood,
      cityPersonal: info?.personalInformation?.address?.city,
      statePersonal: info?.personalInformation?.address?.state,
      postalcodePersonal: info?.personalInformation?.address?.postalCode,
      numberPersonal: info?.personalInformation?.address?.number
    });
	}

  populateFormPf(info: Info): void {
    this.form.patchValue({
      namePersonal: info?.name,
      cpfPersonal: info?.document,
      rgPersonal: info?.driver?.rg,
      issuerPersonal: info?.driver?.issuer,
      mobilePersonal: this.convertPhone(info?.mobile),
      emailPersonal: info?.email,
      streetPersonal: info?.address?.street,
      complementPersonal: info?.address?.complement,
      neighborhoodPersonal: info?.address?.neighborhood,
      cityPersonal: info?.address?.city,
      statePersonal: info?.address?.state,
      postalcodePersonal: info?.address?.postalCode,
      numberPersonal: info?.address?.number
    });
  }

  convertPhone(phone: string): string { 
    if(phone && phone.length == 11) {
      phone = phone.replace(/(\d{2})?(\d{5})?(\d{4})/, '($1) $2-$3');
    }
    
    return phone;
  }

  loadDriverData() {
    this.spinner.show();
    this.userService.getInfo().subscribe(
      (user: User) => {
        this.user = user;
        this.patchFormData();
        this.spinner.hide();
      },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        this.errorHandler.error(err, this.loadDriverData.bind(this));
      }
    );
  }

  save(): void { 
    if (this.form.valid) {
      this.spinner.show();
      const data = this.generateData(this.user.info);

      this.userService.setInfo(data).subscribe(
        () => {
            this.translate.get('alert.200.updateDriver').subscribe(msg => {
              this.alert.success(msg);
              this.spinner.hide();
              this.driveService.sendUpdateUser(true);
              this.enableOrDisableFields(false);
              this.isEditing = false;
              this.loadDriverData();

              setTimeout(() => {
                this.driveService.clearStatus();
              }, 1000);
            });
          },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          this.errorHandler.error(err, this.save.bind(this));
        }
      )
    } else {
      this.form.markAllAsTouched();
    }
  }

  generateData(info: Info): CustomerUpdate {
    this.customerUpdate = new CustomerUpdate();
    this.customerUpdate.name = this.isPJ ? this.form.controls['name'].value : this.form.controls['namePersonal'].value;
    this.customerUpdate.mobile = this.OmitCharecterTel(this.form.controls[this.isPJ ? 'mobile' : 'mobilePersonal'].value);
    
    if (!this.isPJ) {
      this.customerUpdate.rg = this.form.controls['rgPersonal'].value;
      this.customerUpdate.issuer = this.form.controls['issuerPersonal'].value;
    }
    this.customerUpdate.address.postalCode = this.form.controls[this.isPJ ? 'postalcode' : 'postalcodePersonal'].value.replace(/-/g, '');
    this.customerUpdate.address.street = this.form.controls[this.isPJ ? 'street' : 'streetPersonal'].value;
    this.customerUpdate.address.number = parseInt(this.form.controls[this.isPJ ? 'number' : 'numberPersonal'].value);
    this.customerUpdate.address.complement = this.form.controls[this.isPJ ? 'complement' : 'complementPersonal'].value;
    this.customerUpdate.address.neighborhood = this.form.controls[this.isPJ ? 'neighborhood' : 'neighborhoodPersonal'].value;
    this.customerUpdate.address.city = this.form.controls[this.isPJ ? 'city' : 'cityPersonal'].value;
    this.customerUpdate.address.state = this.form.controls[this.isPJ ? 'state' : 'statePersonal'].value;

    if (this.isPJ && !this.isFleet) {
      this.customerUpdate.personalInfo.id = info.personalInformation?.id;
      this.customerUpdate.personalInfo.name = this.form.controls['namePersonal'].value;
      this.customerUpdate.personalInfo.email = this.form.controls['emailPersonal'].value;
      this.customerUpdate.personalInfo.phone = this.form.controls['mobilePersonal'].value;
      this.customerUpdate.personalInfo.cpf = this.form.controls['cpfPersonal'].value;
      this.customerUpdate.personalInfo.rg = this.form.controls['rgPersonal'].value;
      this.customerUpdate.personalInfo.issuer = this.form.controls['issuerPersonal'].value;
      this.customerUpdate.personalInfo.customer = info.personalInformation?.customer;
      this.customerUpdate.personalInfo.subscription = info.personalInformation?.subscription;
      this.customerUpdate.personalInfo.address.neighborhood = this.form.controls['neighborhoodPersonal'].value;
      this.customerUpdate.personalInfo.address.city = this.form.controls['cityPersonal'].value;
      this.customerUpdate.personalInfo.address.state = this.form.controls['statePersonal'].value;
      this.customerUpdate.personalInfo.address.street = this.form.controls['streetPersonal'].value;
      this.customerUpdate.personalInfo.address.postalCode = this.form.controls['postalcodePersonal'].value.replace(/-/g, '');
      this.customerUpdate.personalInfo.address.complement = this.form.controls['complementPersonal'].value;
      this.customerUpdate.personalInfo.address.number =  parseInt(this.form.controls['numberPersonal'].value);
    } else {
      this.customerUpdate.personalInfo = null;
    }

    console.log('NothindAddress  ::: ', this.form.controls['nothingAddressNumberPersonal'].value);
    if (this.form.controls['nothingAddressNumber'].value) {
      this.customerUpdate.address.number = 0;
    }

    if (this.form.controls['nothingAddressNumberPersonal'].value) {
      if (this.isPJ && !this.isFleet) {
        this.customerUpdate.personalInfo.address.number = 0;
      } else {
        this.customerUpdate.address.number = 0;
      }
    }

    return this.customerUpdate;
  }

  OmitSpecialCharacter(e: any) {
		if (/^[0-9\s]*$/.test(e.key)) return true;

		e.preventDefault();
		return false;
	}
   
  OmitCharecterTel(data: string) {
    data = data.replace( /[{(}]/g, ''); 
    data = data.replace( /[{)}]/g, '');
    data = data.replace( /[{ }]/g, '');
    data = data.replace( /-/g, '');

    return data;
  }

  openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }

  findCep(postalCode: string, isAddressPersonal: boolean): void {
    if (postalCode.length == 9 && !this.firstFindCep) {
      this.externalService.getAddressByCep(postalCode).subscribe(data => {
        this.fillAddress(data, isAddressPersonal);
       }
      );
    }
  }

  fillAddress(cepResponse: any, isAddressPersonal: boolean) : void {
    if (isAddressPersonal) {
      this.form.controls['streetPersonal'].setValue(cepResponse.logradouro);
      this.form.controls['neighborhoodPersonal'].setValue(cepResponse.bairro);
      this.form.controls['cityPersonal'].setValue(cepResponse.localidade);
      this.form.controls['statePersonal'].setValue(cepResponse.uf);
    } else {
      this.form.controls['street'].setValue(cepResponse.logradouro);
      this.form.controls['neighborhood'].setValue(cepResponse.bairro);
      this.form.controls['city'].setValue(cepResponse.localidade);
      this.form.controls['state'].setValue(cepResponse.uf);
    }

    this.disableFieldsResponse(cepResponse, isAddressPersonal);
  }

  disableFieldsResponse(cepResponse: any, isAddressPersonal: boolean): void {
    if (isAddressPersonal) {
      cepResponse.logradouro ? this.form.controls['streetPersonal'].disable() : this.form.controls['streetPersonal'].enable();
      cepResponse.bairro ? this.form.controls['neighborhoodPersonal'].disable() : this.form.controls['neighborhoodPersonal'].enable();
      cepResponse.localidade ? this.form.controls['cityPersonal'].disable() : this.form.controls['cityPersonal'].enable();
      cepResponse.uf ? this.form.controls['statePersonal'].disable() : this.form.controls['statePersonal'].enable();
    } else {
      cepResponse.logradouro ? this.form.controls['street'].disable() : this.form.controls['street'].enable();
      cepResponse.bairro ? this.form.controls['neighborhood'].disable() : this.form.controls['neighborhood'].enable();
      cepResponse.localidade ? this.form.controls['city'].disable() : this.form.controls['city'].enable();
      cepResponse.uf ? this.form.controls['state'].disable() : this.form.controls['state'].enable();
    }
  }

  verifyForm(): boolean {
    return this.form.dirty;
  }

  enableOrDisableEdit(): void {
    this.isEditing = !this.isEditing;
    this.enableOrDisableFields(this.isEditing);

    this.firstFindCep = true;

    if (this.isPJ && this.isFleet) {
      this.populaFormFleet(this.user.info);
    } else if (this.isPJ && !this.isFleet) {
			this.populateFormPj(this.user.info);
		} else {
			this.populateFormPf(this.user.info);
		}
    
    setTimeout(() => {
      this.firstFindCep = false;
    }, 2000);
  }

  enableOrDisableFields(enable: boolean): void {
		const keysFleet = ['name', 'mobile', 'postalcode', 'street', 'neighborhood', 'city', 'state'];
		const keysPF = ['namePersonal', 'rgPersonal', 'issuerPersonal', 'mobilePersonal', 'postalcodePersonal', 'streetPersonal', 'neighborhoodPersonal', 'cityPersonal', 'statePersonal'];
		const keysPJ = ['name', 'mobile', 'postalcode', 'street', 'neighborhood', 'city', 'state', 'namePersonal', 'rgPersonal', 'cpfPersonal', 'issuerPersonal', 'mobilePersonal', 'emailPersonal', 'postalcodePersonal', 'streetPersonal', 
    'neighborhoodPersonal', 'cityPersonal', 'statePersonal'];

		let keys = this.isFleet ? keysFleet : this.isPJ ? keysPJ : keysPF;

		keys.forEach(key => {
			if (enable) {
				this.form.controls[key].enable();
				this.form.controls[key]?.setValidators(Validators.required);
			} else {
				this.form.controls[key].disable();
				this.form.controls[key]?.clearValidators();
			}
			this.form.controls[key].updateValueAndValidity();
		});

    this.enableOrDisableFieldNumber(enable);
	}

  enableOrDisableFieldNumber(enable: boolean): void {
    if (enable) {
      this.form.controls['complement'].enable();
      this.form.controls['complementPersonal'].enable();
      this.form.controls['number'].enable();
      this.form.controls['numberPersonal'].enable();
    } else {
      this.form.controls['complement'].disable();
      this.form.controls['complementPersonal'].disable();
      this.form.controls['number'].disable();
      this.form.controls['numberPersonal'].disable();
    }
    this.form.controls['complement'].updateValueAndValidity();
    this.form.controls['complementPersonal'].updateValueAndValidity();
    this.form.controls['number'].updateValueAndValidity();
    this.form.controls['numberPersonal'].updateValueAndValidity();
  }

  exitWithoutEdit(): void {
    let route = localStorage.getItem('route');
    this.enableOrDisableEdit();
    this.routesChange.navigateByUrl(route);
    this.dialog.closeAll();
  }
} 

