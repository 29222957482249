import {Component, AfterViewInit, ViewChild, ElementRef, OnInit, OnChanges} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NavMobileService } from '../nav-mobile/nav-mobile.service';
import { filter } from 'rxjs/operators';
import {ApplicationService} from "../../../core/services/application.service";
import {User} from "../../service/User/user";
import { environment } from 'src/environments/environment';

export interface Link {
	title: string;
	link: string;
	icon: string;
	icon_active: string;
}

@Component({
	selector: 'app-nav-mobile',
	templateUrl: './nav-mobile.component.html',
	styleUrls: ['./nav-mobile.component.scss'],
})
export class NavMobileComponent implements AfterViewInit {
	constructor(public router: Router, public navMobileService: NavMobileService, private applicationService: ApplicationService) {
    this.getUser();
  }

  user: User;

  public links: Array<Link> =  this.applicationService.getUserFleet() === 'true' ? this.getLinksFleet() : this.getLinks();

  isFleet = this.applicationService.getUserFleet();

  @ViewChild('menu', { static: false }) public menu: ElementRef;

  ngAfterViewInit() {
		this.navMobileService.menu.subscribe(state => {
			if (state) {
        this.links = this.applicationService.getUserFleet() === 'true' ? this.getLinksFleet() : this.getLinks();
        (this.menu.nativeElement as HTMLDivElement).style.display = 'flex';
			} else {
        this.links = this.applicationService.getUserFleet() === 'true' ? this.getLinksFleet() : this.getLinks();
        (this.menu.nativeElement as HTMLDivElement).style.display = 'none';
			}
		});

		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		).subscribe((event: NavigationEnd) => this.navMobileService.hide());

	}

	checkRouter(linkItem: Link): boolean {
		if (this.router.url === linkItem.link) {
			return true;
		}
		return false;
	}

	logout() {
    sessionStorage.clear();
    localStorage.clear();

    window.fma.model.config.logoutURL = environment.fma.redirecturl + '/logout';
    window.fma.logout();
	}

  getLinksFleet(): Array<Link>{
    return [
        {title: 'Minha frota', link: '/fleet', icon: 'assets/image/mobile-menu/car.svg', icon_active: 'assets/image/mobile-menu/car_active.svg'},
        {title: 'Multas', link: '/dashboard/fines', icon: 'assets/image/mobile-menu/receipt.svg', icon_active: 'assets/image/mobile-menu/receipt_active.svg'},
        {title: 'Meus dados', link: '/dashboard/drivers', icon: 'assets/image/mobile-menu/user.svg', icon_active: 'assets/image/mobile-menu/user_active.svg'},
        {title: 'Extrato Geral', link: '/fleet/statement', icon: 'assets/image/mobile-menu/receipt.svg', icon_active: 'assets/image/mobile-menu/receipt_active.svg'}
      ];
  }

  getLinks(): Array<Link>{
    return [
      {title: 'Meu Veículo', link: '/dashboard', icon: 'assets/image/mobile-menu/car.svg', icon_active: 'assets/image/mobile-menu/car_active.svg'},
      {title: 'Meus Dados', link: '/dashboard/drivers', icon: 'assets/image/mobile-menu/user.svg', icon_active: 'assets/image/mobile-menu/user_active.svg'},
      {title: 'Controle de Multas', link: '/dashboard/fines', icon: 'assets/image/mobile-menu/receipt.svg', icon_active: 'assets/image/mobile-menu/receipt_active.svg'}
    ];
  }

  getUser(){
    this.user = this.applicationService.getUserLogged();
  }
}
