import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Alert } from 'src/app/shared/class/alert';
import { HttpErrorHandleService } from 'src/app/shared/service/data/http-error-handle/http-error-handle.service';
import { UserService } from 'src/app/shared/service/User/user.service';
import { StorageService } from 'src/app/shared/service/data/storage/storage.service';


@Component({
  selector: 'new-customer',
  templateUrl: './new-customer.component.html',
  styleUrls: ['./new-customer.component.scss']
})
export class NewCustomerComponent implements OnInit {
  newPasswordForm: FormGroup;
  hideShowPasswordToogle: boolean = false;

  constructor(
    private formBiulder: FormBuilder,
    private router: Router,
    private alert: Alert,
    private storageService: StorageService,
    private userService: UserService,
    private errorHandler: HttpErrorHandleService,
    private translate: TranslateService
  ) {
    this.newPasswordForm = this.formBiulder.group({
      newPassword: new FormControl(null, Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(12)])),
      confirmPassword: new FormControl(null, Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(12)])),
    }, { validator: this.matches });
  }

  ngOnInit() {
    this.translate.use('pt');
  }

  matches(form: AbstractControl) {
    return form.get('newPassword').value == form.get('confirmPassword').value ? null : { equals: true };
  }

  hideShowPassword() {
    this.hideShowPasswordToogle = !this.hideShowPasswordToogle;
  }

  newPassword(): void {
    this.userService.setPassword(this.newPasswordForm.value).subscribe(
      (response: HttpEvent<Object>) => {
        if (response.type === HttpEventType.Response) {
          this.storageService.removeItem("username");
          const translatedKey = "alert."+response.status.toString()+".newPassword";
          this.translate.get(translatedKey).subscribe(msg =>
            this.alert.success(msg)
              .then(() => ''));

          this.router.navigate(['/dashboard']);
        }
      },
      (err: HttpErrorResponse) => {
        this.errorHandler.getErrorMessage(err, 'newPassword').subscribe(
          (translatedText: string) => {
            this.alert.error(translatedText)
            .then(() => this.navigateToLogin());

          }
        );
      });

  }
  navigateToLogin() {
    this.router.navigate(['/']);
  }

}
