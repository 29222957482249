import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersComponent } from './users.component';
import {MatTableModule} from "@angular/material/table";
import { AdditionalUserComponent } from './additional-user/additional-user.component';
import {MatDialogModule} from "@angular/material/dialog";
import { DropdownModule } from "primeng/dropdown";
import { TooltipModule } from "primeng/tooltip";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@NgModule({
  declarations: [UsersComponent, AdditionalUserComponent],
    imports: [
        CommonModule,
        MatTableModule,
        MatDialogModule,
        DropdownModule,
        FormsModule,
        ReactiveFormsModule,
        TooltipModule
    ]
})
export class UsersModule { }
