import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatPostalCode', pure: false })
export class PostalCodePipe implements PipeTransform {
  transform(value: string): string {
    const sanitizedValue = String(value).replace(/-/g, '').trim();

    switch(sanitizedValue.length) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return sanitizedValue;
      case 6:
        return sanitizedValue.replace(/(\d+)(\d{1})/, '$1-$2');
      case 7:
        return sanitizedValue.replace(/(\d+)(\d{2})/, '$1-$2');
      case 8:
        return sanitizedValue.replace(/(\d+)(\d{3})/, '$1-$2');
      default:
        return sanitizedValue.replace(/(\d+)(\d{3})/, '$1-$2');
    }
  }
}
