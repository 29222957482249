import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Ng2PicaService } from 'ng2-pica';
import { BehaviorSubject } from 'rxjs';
import { Alert } from '../../class/alert';
import { Upload, UploadFile, mode } from './upload.interface';
import { MessageService } from 'primeng/api';

const config = require('./upload-config.json');

@Component({
  selector: 'document-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
  public upload: Upload;
  public filesList = new BehaviorSubject([] as Array<UploadFile>);

  @ViewChild('fileDropRef', { static: false }) public fileInput: ElementRef;

  @Input('mode') public mode: mode;
  @Input('extensionsAllow') public extensionsAllow: Array<string>;
  @Input('multiple') public multiple: boolean;

  @Output('files') public files = new EventEmitter();

  constructor(private alert: Alert, private picaService: Ng2PicaService, private messageService: MessageService) {}

  ngOnInit() {
    this.upload = config[this.mode] as Upload;
    this.registerEmitter();
  }

  registerEmitter() {
    this.filesList.subscribe((filesList) => this.files.emit(filesList as Array<UploadFile>));
  }

  onFileDropped(fileList: FileList) {
    this.prepareFile(fileList as FileList);
  }

  fileBrowseHandler(fileList: FileList) {
    this.prepareFile(fileList);
  }

  async prepareFile(fileList: FileList) {
    for (var i = 0; i < fileList.length ; i++) {
      let file = fileList[i] as File;

      if (this.extensionsAllow.findIndex(extension => extension === String(file.name).toLowerCase().split('.').reverse()[0]) >= 0) {
        if (this.mode === 'self' || this.mode === 'cnh') {
          if(file.type !== 'application/pdf')
            file = await this.resizeFile(file);
        }

        const newFile = file as UploadFile;
        newFile.extension = String(file.name).toLowerCase().split('.').reverse()[0];
        newFile.base64 = await this.convertImageToBase64(fileList[i]);

        this.sendMessage('success', '', 'Arquivo(s) selecionado(s).');
        this.filesList.next([newFile]);
      } else {
        const message = `Não é possivel carregar o arquivo ${file.name}\n motivo: Formato não Permitido\n` +
          `Por favor utilize os seguintes formatos: ${this.extensionsAllow.join(', ')}`;
        this.alert.errorToastr(message);
        (this.fileInput.nativeElement as HTMLInputElement).value = null;
      }
    }
  }

   resizeFile(file: File): Promise<File> {
    return new Promise((resolve, reject) => {
      try {
        this.picaService.resize([file], 640, 800, true).subscribe(newFile => {
          if (newFile) resolve(new File([newFile], newFile.name));
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  clearFile() {
    this.filesList.next([]);
    (this.fileInput.nativeElement as HTMLInputElement).value = null;
    this.messageService.clear();
  }

  convertImageToBase64(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  sendMessage(severity: string, summary: string, detail: string) {
    this.messageService.clear();
    this.messageService.add({severity, summary, detail});
  }
}
