import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../../../shared/service/User/user.service";
import {AdditionalUserModel, getTypeUser, UserAdditionalEnum} from "../additional-user.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Alert} from "../../../shared/class/alert";
import {NgxSpinnerService} from "ngx-spinner";

export interface City{
  name: string;
  code: string;
}
@Component({
  selector: 'additional-user',
  templateUrl: './additional-user.component.html',
  styleUrls: ['./additional-user.component.scss']
})
export class AdditionalUserComponent implements OnInit {

  form: FormGroup;
  user: AdditionalUserModel;
  types = Object.values(UserAdditionalEnum).filter((v) => isNaN(Number(v))).map(op => new Object({label: getTypeUser(op.toString()) , type: op}));

  constructor(private formBuilder: FormBuilder, private userService: UserService, public dialogRef: MatDialogRef<AdditionalUserComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private spinner: NgxSpinnerService, private alert: Alert) {
  }

  ngOnInit(): void {
    if(this.data != null) this.user = this.data.user;
    else this.user = null;
    this.getForm();
  }

  getForm() {
    this.form = this.formBuilder.group({
      name: [(this.user?.name ? this.user.name : ''), Validators.required],
      email: [(this.user?.email ? this.user.email : ''), [Validators.required, Validators.email]],
      type: [(this.user?.type ? new Object({label: getTypeUser(this.user.type.toString()), type: this.user.type})  : ''), Validators.required]
    });
  }

  save(){
    if(this.form.valid){
      if(this.user !== null){
        this.user.name = this.form.get('name').value;
        this.user.email = this.form.get('email').value;
        this.user.type = this.form.get('type').value.type;
      } else{
        this.user = new AdditionalUserModel(this.form.get('name').value, this.form.get('email').value, this.form.get('type').value.type);
      }

      this.spinner.show();
      this.userService.saveAdditionalUser(this.user).subscribe(save => {
        this.spinner.hide();
        if(this.user?.id) this.alert.success('Usuário alterado com sucesso!');
        else this.alert.success('Usuário adicionado com sucesso!');
        this.dialogRef.close(true);
      },
        error => {
         this.spinner.hide();
          this.alert.warning('Erro: ação não permitida!');
          this.dialogRef.close(true);
        });
    }
  }
}
