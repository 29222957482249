export class Subscription {

  constructor(
    public uuid?: string,
    public name?: string,
    public needMainDriver?: boolean,
    public active?: boolean
  ) {}

  static fromJson(jsonData: any): Subscription {
    const element = {};

    for (const [key, value] of Object.entries(jsonData)) {
      if (typeof(value) === 'string') {
        Object.assign(element, { [key]: String(value).trim() });
      } else {
        Object.assign(element, { [key]: value });
      }
    }

    return element;
  }

  static fromJsonArr(jsonData: Array<any>): Array<Subscription> {
    const elementArr: Array<Subscription> = [];

    for (const subscription of jsonData as Array<any>) {
      elementArr.push(Subscription.fromJson(subscription));
    }

    return elementArr;
  }
}
