import { NgModule, Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from '../services/application.service';

@Injectable()
export class AuthInterceptorRequest implements HttpInterceptor {
  constructor(
    public translateService: TranslateService,
    private applicationService: ApplicationService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.mustInjectToken(req)) {
      return next.handle(req);
    }

    const user = this.applicationService.getUserLogged();

    const headers = new HttpHeaders({
      'Authorization': 'Bearer '+ user.token.access_token,
      'Accept-Language': 'pt',
      // 'Accept-Language': this.translateService.getBrowserLang(),
    });

    const dupReq = req.clone({ headers });

    return next.handle(dupReq);
  }

  mustInjectToken(req){
    if (!(req.url.indexOf('api') > -1)) {
      return false;
    }

    const urlSuffix = String(req.url).split('/').reverse()[0].trim() || undefined;
    const user = this.applicationService.getUserLogged();

    if (urlSuffix === 'token' || urlSuffix === 'password' || !user){
      return false;
    }

    return true;
  }
}

@NgModule({
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptorRequest,
    multi: true
  }]
})
export class AuthInterceptor {}
