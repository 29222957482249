import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

declare global {
	interface Window {
		fma: any;
	}
}

/** Application component which will be bootstrapped by Angular and hold the application */
@Component({
	selector: 'body',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	private langs: Array<string> = ['pt', 'en'];
	show: boolean = false;
	public enviroment = environment;

	constructor(private translate: TranslateService, private router: Router) {
		this.translate.addLangs(this.langs);
		// this.translate.use(this.translate.getBrowserLang().match(/pt|en/) ? this.translate.getBrowserLang() : 'pt');
		this.translate.use('pt');

		router.events.subscribe(event  => {
			if (event instanceof NavigationEnd) {}
			if (window.location.pathname.length > 1 && ( window.location.pathname != '/forgot-password' && window.location.pathname != '/new-password')) this.show = true;
			else this.show = false;
		});
	}

	ngOnInit() {
		document.body.addEventListener('fma_unauthenticated', () => {
			sessionStorage.clear();
			localStorage.clear();
			this.router.navigate(['/']);
		});
	}
}
