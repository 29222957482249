import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpErrorHandleService } from '../../service/data/http-error-handle/http-error-handle.service';

import { ApplicationService } from 'src/app/core/services/application.service';
import { DashboardService } from 'src/app/pages/dashboard/shared/dashboard.service';
import { SubscriptionService } from '../../service/subscription.service';

import { Alert } from 'src/app/shared/class/alert';
import { UploadFile, mode as FileType } from '../../../shared/components/upload/upload.interface';
import { Driver } from '../../models/driver.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'upload-update',
  templateUrl: './upload-update.component.html',
  styleUrls: ['./upload-update.component.scss']
})
export class UploadUpdateComponent implements OnInit {
  public files: any[] = [];
  public allowExtensions = ['png', 'jpg', 'jpeg', 'pdf'];
  public filesCnh: Array<UploadFile> = [];
  public filesPhoto: Array<UploadFile> = [];
  public formUpdate: FormGroup;
  public driver: Driver;
  public isCnpj: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private alert: Alert,
    private dashboardService: DashboardService,
    public dialogRef: MatDialogRef<UploadUpdateComponent>,
    private spinner: NgxSpinnerService,
    private subscriptionService: SubscriptionService,
    private applicationService: ApplicationService,
    private errorHandler: HttpErrorHandleService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.driver = this.data['driver'];
    this.isCnpj = this.data['isCnpj'];

    this.translate.use('pt');
    this.buildForm();
  }

  bindingFile(files: Array<UploadFile>, type: FileType) {
    if (type === 'cnh') {
      this.filesCnh = files;
    } else if (type === 'self') {
      this.filesPhoto = files;
    }
  }


  buildForm() {
    this.formUpdate = this.formBuilder.group({
      name: [this.driver.name, [Validators.required]],
      cpf: [this.driver.cpf, [Validators.required]],
    });

  }

  submit() {
    this.spinner.show();

    if (this.filesCnh[0] && this.filesPhoto[0]) {
      try {
        this.dashboardService.updateMainDriver({ cnhComplete: this.filesCnh[0].base64, faceMatch: this.filesPhoto[0].base64 }, this.isCnpj, this.driver.name, this.driver.cpf, this.driver.mainDriver).subscribe(
          () => {
            this.loadSubscriptions();
            this.alert.success('Documentos recebidos com sucesso!<br>Por favor, aguarde enquanto fazemos a análise.').then(() => {
              this.closeDialog();
            });
          },
          (error: HttpErrorResponse) => {
            if (error.status === 400) {
              this.loadSubscriptions();
            }
            this.translate.get('alert.general.updateDriver').subscribe(msg => {
              if (msg) {
                this.alert.success(msg)
                  .then(() => this.closeDialog());
                this.spinner.hide();
              }
            })
          }
        )
      } catch (error) {
        this.spinner.hide();
        this.errorHandler.getErrorMessage(null, 'addDriverError').subscribe(
          (translatedText: string) => {
            this.alert.error(translatedText);
          }
        );
      }
    } else {
      this.spinner.hide();
      this.closeDialog();
      this.openAlertErroEdit();
    }
  }

  openAlertErroEdit(): void {
    const msg = 'Você precisa realizer o upload da nova CNH e da foto para completar essa ação.';
		Swal.fire({
			confirmButtonText:'OK',
			html: `<img width="32" height="32" src="assets/image/icons/safety.svg"><p style=\'font-size: 18px; color: #00095B; font-family: FordAntenna; font-weight: 400; margin: 32px 0; line-height: 21px;\'>${msg}</p>`,
      customClass: {
				confirmButton: 'btn-alert-ok'
			},
			onClose: () => {}
		});
	}

  loadSubscriptions() {
    this.subscriptionService.findAll().subscribe(
      (subscriptions) => {
        this.applicationService.setSubscriptions(subscriptions);
        this.spinner.hide();
      },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        if (err.status === 400) {
          this.closeDialog();
        } else {
          this.applicationService.clearApplication();
          this.errorHandler.getErrorMessage(err, 'subscriptionFindAll').subscribe(
            (translatedText: string) => {
              this.alert.error(translatedText);
            }
          );
        }
      }
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
