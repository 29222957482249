import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {ExtractGeralComponent} from "./extract-geral/extract-geral.component";
import {GuardFleet} from "./auth/guard.fleet";
import { DashboardStatementComponent } from '../dashboard/dashboard-statement/dashboard-statement.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [GuardFleet]
  },
  {
    path: 'extract',
    component: ExtractGeralComponent,
    canActivate: [GuardFleet]
  },
  {
    path: 'statement',
    component: DashboardStatementComponent,
    canActivate: [GuardFleet]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class DashboardFleetRoutingModule { }
