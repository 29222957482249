import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandler } from 'src/app/shared/class/ErrorHandler';
import { ApplicationService } from 'src/app/core/services/application.service';
import { Fine } from 'src/app/shared/models/fine.model';
import { FineService } from 'src/app/shared/service/fine.service';

@Component({
  selector: 'app-dashboard-fines',
  templateUrl: './dashboard-fines.component.html',
  styleUrls: ['./dashboard-fines.component.scss'],
})
export class DashboardFinesComponent implements OnInit {
  uuid: string;
  fines: Fine[];
  originalFines: Fine[];

  show = false;
  selectedFine: Fine;
  sortState: any = { key: '', asc: true }

  currentQ: string = '';
  timer: any;

  constructor(
    public spinner: NgxSpinnerService,
    private translate: TranslateService,
    private applicationService: ApplicationService,
    private fineService: FineService
  ) { }

  ngOnInit(): void {
    this.translate.use('pt');

    this.uuid = this.applicationService.getUserLogged().token.uuid;

    this.getFines();
  }

  getFines() {
    this.spinner.show();

    this.fineService.getAllFinesByUserUuid(this.uuid).subscribe((data) => {
      this.fines = <Fine[]>data;
      this.fines.forEach(fine => {
        if (fine.nomeCondutorIndicado == undefined || fine.nomeCondutorIndicado.length == 0) {
          if(fine.status > 1) fine.nomeCondutorIndicado = 'Sem indicação de condutor infrator';
          else fine.nomeCondutorIndicado = 'Pendente';
        }
      })
      this.originalFines = this.fines;
      this.sort('dataInfracao');
      this.spinner.hide();
    });
  }

  showFine(fine: Fine) {
    this.show = true;
    this.selectedFine = fine;
  }

  hideFine() {
    this.show = false;
    this.selectedFine = undefined;
  }

  sort(key: string) {
    if (this.sortState.key == key) {
      this.sortState.asc = !this.sortState.asc;
    }
    else {
      this.sortState.key = key;
      this.sortState.asc = true;
    }

    var mod = 0;

    if (this.sortState.asc) mod = 1;
    else mod = -1;

    this.fines.sort((a, b) => {
      if (a[key] < b[key]){
        return -1 * mod;
      }
      if (a[key] > b[key]){
        return 1  * mod;
      }

      return 0;
    });
  }

  liveSearch(q: string) {
    if (this.fines == undefined || this.fines.length == 0) return

		if (this.currentQ === q) {
			return
		}
		else {
			this.currentQ = q;
		}

		clearTimeout(this.timer);

    this.timer = undefined;

		this.timer = setTimeout(() => {
			this.search(this.currentQ);
      this.timer = undefined;
		}, 1000);
	}

  clearSearch(target: any) {
		target.value = '';
		this.currentQ = '';
		this.search(this.currentQ);
	}

  search(searchTerm: string) {
    this.fines = this.originalFines;
    const keys: string[] = ['dataInfracao', 'placa', 'nomeCondutorIndicado', 'pontuacao', 'cidade'];
    this.fines = this.fines.filter(o => keys.some(k => String(o[k]).toLowerCase().includes(searchTerm.toLowerCase())));
  }
}
