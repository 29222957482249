import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApplicationService } from '../../core/services/application.service';
import { Subscription } from '../models/subscription.model';
import {SubscriptionDetail, SubscriptionDetailFleet} from '../models/subscription-detail.model';
import { Driver } from '../models/driver.model';
import { AddDriverRequest } from './subscription.interface';
import { Creditcard } from '../service/subscription.interface';
import { Summary } from '../models/summary.model';
import { Pendency } from '../models/pendency.modal';
import {RequestStatusModel} from "../class/request-status.model";
import {PaymentModel} from "../components/payment-history/model/payment.model";
import { Statement } from '../models/statement.model';

@Injectable({ providedIn: 'root' })
export class SubscriptionService {
  private baseurl = `${environment.baseUrl}`;
  constructor(private http: HttpClient, private applicationService: ApplicationService) { }

  findAll(): Observable<Array<Subscription>> {
    const user = this.applicationService.getUserLogged();
    const url = `${this.baseurl}/customer/${user.token.uuid}/subscription`;

    return this.http.get(url).pipe(
      map(Subscription.fromJsonArr),
      catchError((err: HttpErrorResponse) => throwError(err)),
    )
  }

  detail(subscriptionId: string): Observable<SubscriptionDetail> {
    const user = this.applicationService.getUserLogged();
    const url = `${this.baseurl}/customer/${user.token.uuid}/subscription/${subscriptionId}`;

    return this.http.get(url).pipe(
      map(SubscriptionDetail.fromJson),
      catchError((err: HttpErrorResponse) => throwError(err)),
    );
  }

  cancel(subscriptionId: string): Observable<any> {
    const user = this.applicationService.getUserLogged();
    const url = `${this.baseurl}/customer/${user.token.uuid}/subscription/${subscriptionId}/cancel`;

    return this.http.post(url, {}).pipe(
      map((response: any) => response),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }

  drivers(subscriptionId: string): Observable<Array<Driver>> {
    const user = this.applicationService.getUserLogged();
    const url = `${this.baseurl}/customer/${user.token.uuid}/subscription/${subscriptionId}/driver`;

    return this.http.get(url).pipe(
      map(Driver.fromJsonArr),
      catchError((err: HttpErrorResponse) => throwError(err)),
    );
  }

  deleteDriver(subscriptionId: string, driverId: number): Observable<any> {
    const user = this.applicationService.getUserLogged();
    const url = `${this.baseurl}/customer/${user.token.uuid}/subscription/${subscriptionId}/driver/${driverId}`;

    return this.http.delete(url).pipe(
      map((response: any) => response),
      catchError((err: HttpErrorResponse) => throwError(err)),
    )
  }

  addDriver(req: AddDriverRequest): Observable<any> {
    const user = this.applicationService.getUserLogged();
    const subscription = this.applicationService.getActiveSubscription();
    const url = `${this.baseurl}/customer/${user.token.uuid}/subscription/${subscription.uuid}/driver`;

    return this.http.post(url, req).pipe(
      map((response: any) => response),
      catchError((err: HttpErrorResponse) => throwError(err)),
    );
  }

  addMainDriver(req: AddDriverRequest): Observable<any> {
    const user = this.applicationService.getUserLogged();
    const subscription = this.applicationService.getActiveSubscription();
    const url = `${this.baseurl}/customer/${user.token.uuid}/subscription/${subscription.uuid}/driverPJ`;

    return this.http.post(url, req).pipe(
      map((response: any) => response),
      catchError((err: HttpErrorResponse) => throwError(err)),
    );
  }

  fineAggrement(driver: Driver, file: FormData, isCnpj: boolean) {
    const user = this.applicationService.getUserLogged();
    const subscription = this.applicationService.getActiveSubscription();
    let url;

    if (driver.mainDriver && !isCnpj) {
      url = `${this.baseurl}/customer/${user.token.uuid}/subscription/${subscription.uuid}/finesagreement`;
    } else {
      url = `${this.baseurl}/customer/${user.token.uuid}/subscription/${subscription.uuid}/driver/${driver.id}/finesagreement`;
    }
    return this.http.post(url, file).pipe(
      map((response: any) => response),
      catchError((err: HttpErrorResponse) => throwError(err)),
    );
  }

  changeCreditcard(data: Creditcard ): Observable<any> {
    const user = this.applicationService.getUserLogged();
    const subscription = this.applicationService.getActiveSubscription();

		return this.http.put(`${this.baseurl}/customer/${user.token.uuid}/subscription/${subscription.uuid}/creditcard`, data)
			.pipe(
				map((response: any) => response),
				catchError((err: HttpErrorResponse) => throwError(err)),
			);
  }

  summary(subscriptionId: string): Observable<Array<Summary>> {
    const user = this.applicationService.getUserLogged();
    const url = `${this.baseurl}/customer/${user.token.uuid}/subscription/${subscriptionId}/payment`;

    return this.http.get(url).pipe(
      map(Summary.fromJsonArr),
      catchError((err: HttpErrorResponse) => throwError(err)),
    );
  }

  paymentPendency(subscriptionId: string): Observable<Array<Pendency>> {
    const user = this.applicationService.getUserLogged();
    const url = `${this.baseurl}/customer/${user.token.uuid}/subscription/${subscriptionId}/payment/boleto`;

    return this.http.get(url).pipe(
      map(Pendency.fromJsonArr),
      catchError((err: HttpErrorResponse) => throwError(err)),
    );
  }

  downloadTerm(params: any) {
    const url = `${this.baseurl}/term`;

    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.http.post(url, params, httpOptions);
  }

  getVehicleImage(vin: string, country: string, year: string) {
    const url = `${this.baseurl}/vehicle-image/?vin=${vin}&country=${country}&year=${year}`;

    return this.http.get<string>(url, { responseType: 'text' as 'json' });
  }

  downloadContract() {
    const customerUuid = this.applicationService.getUserLogged().token.uuid;
    const subUuid = this.applicationService.getActiveSubscription().uuid;
    const baseurl = `${environment.baseUrl}`;
    const url = baseurl + '/customer/' + customerUuid + '/subscription/' + subUuid + '/contract';

    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.http.get(url, httpOptions);
  }

  getPaytmentReceipt(statementId: string ): Observable<Blob>{
    const customerUuid = this.applicationService.getUserLogged().token.uuid;
    const subUuid = this.applicationService.getActiveSubscription().uuid;
    const url = `${environment.baseUrl}/customer/${customerUuid}/subscription/${subUuid}/download/receipt/statement/${statementId}`;
    return this.http.get(url, {responseType: 'blob'});
  }

  getAllStatus(): Observable<RequestStatusModel>{
    const subUuid = this.applicationService.getActiveSubscription().uuid;
    const url = `${environment.baseUrl}/customer/request/status/findallby/subscription/${subUuid}`;

    return this.http.get<RequestStatusModel>(url).pipe(
      map((response: any) => response),
      catchError((err: HttpErrorResponse) => throwError(err)),
    );
  }

  getAllPayments(): Observable<PaymentModel[]> {
    const customerUuid = this.applicationService.getUserLogged().token.uuid;
    const subUuid = this.applicationService.getActiveSubscription().uuid;
    const url = `${environment.baseUrl}/customer/${customerUuid}/subscription/${subUuid}/recurring`;

    return this.http.get<PaymentModel[]>(url).pipe(
      map((response: any) => response),
      catchError((err: HttpErrorResponse) => throwError(err)),
    );
  }

  downloadBoleto(boletoCode) {
    const customerUuid = this.applicationService.getUserLogged().token.uuid;
    const subUuid = this.applicationService.getActiveSubscription().uuid;
    const baseurl = `${environment.baseUrl}`;
    const url = baseurl + '/customer/' + customerUuid + '/download/boleto/' + boletoCode;

    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.http.get(url, httpOptions);
  }

  downloadBoletoSAPP(boletoCode): Observable<Blob> {
    const customerUuid = this.applicationService.getUserLogged().token.uuid;
    const baseurl = `${environment.baseUrl}`;
    const url = baseurl + '/customer/' + customerUuid + '/download/boleto/' + boletoCode;
    
		return this.http.get(url, {responseType: 'blob'});
  }

  needRegistryLicensed(): Observable<boolean> {
    const user = this.applicationService.getUserLogged();
    const url = `${this.baseurl}/customer/${user.token.uuid}/registry/licensed`;
    return this.http.get<boolean>(url);
  }

  isFleetOwner(): Observable<boolean> {
    const user = this.applicationService.getUserLogged();
    const url = `${this.baseurl}/customer/${user.token.uuid}/isfleetowner`;
    return this.http.get<boolean>(url);
  }

  findAllFleet(): Observable<SubscriptionDetailFleet[]> {
    const user = this.applicationService.getUserLogged();
    const url = `${this.baseurl}/customer/${user.token.uuid}/fleet`;
    return this.http.get<SubscriptionDetailFleet[]>(url);
  }

  getAllStatements(): Observable<Statement[]> {
    const customerUuid = this.applicationService.getUserLogged().token.uuid;
    const url = `${this.baseurl}/customer/${customerUuid}/statement`;

    return this.http.get<PaymentModel[]>(url).pipe(
      map((response: any) => response),
      catchError((err: HttpErrorResponse) => throwError(err)),
    );
  }

  getCustomerUuid(): Observable<string> {
    const url = `${this.baseurl}/customer`;
    return this.http.get(url, {responseType: 'text' as 'text'});
  }

  changeMainDriver(suuid: string, id: number) {
    const user = this.applicationService.getUserLogged();
    const url = `${this.baseurl}/customer/${user.token.uuid}/subscription/${suuid}/main-driver/${id}`;
    return this.http.put(url, undefined);
  }
}
