import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'input-ford',
	templateUrl: './input-ford.component.html',
	styleUrls: ['./input-ford.component.scss']
})
export class InputFordComponent implements OnInit {
	@Input() control: FormControl;
	@Input() label: string;
	

	ngOnInit() {

	}
}
