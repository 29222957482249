import { ApplicationService } from './../services/application.service';
import { AuthService } from './../../shared/service/Auth/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ErrorHandler } from '../../shared/class/ErrorHandler';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private authService: AuthService, private applicationService: ApplicationService, private errorHandler: ErrorHandler) {}

	async canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (this.authService.isTokenValid()) {
			const subs = this.applicationService.getSubscriptions();
			if (subs && subs.length > 0 ) {
				return true;
			}

			return this.goToUpload(state.url);
		}

		const refresh = await this.errorHandler.refresh();
		if (refresh) return true;

		return this.goToLogin();
	}

	goToLogin(){
		this.cleanup();
		window.location.replace('/');
		return false;
	}

	goToUpload(toUrl){
		if(toUrl !== '/dashboard/upload' && toUrl !== '/dashboard/contractor' && toUrl !== '/dashboard/registryLicensed') {
			window.location.replace('/dashboard/upload');
			window.location.reload();
			return false;
		}

		return true;
	}

	cleanup(){
		this.applicationService.clearApplication();
	}
}
