import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {SubscriptionDetail} from "../models/subscription-detail.model";

@Injectable({
  providedIn: 'root'
})
export class RentalService {

  private baseurl = `${environment.baseUrl}`;

  constructor(
    private http: HttpClient,
  ) { }

  getRentalOfDealer(dealerId: string){
    const url = `${this.baseurl}/rental/dealer/${dealerId}`;
    return this.http.get(url);
  }

  getRentalMapImage(lat:string, long:string) {
    const url = `${this.baseurl}/rental/dealer/map/${lat}/${long}`;
    return this.http.get<string>(url, { responseType: 'text' as 'json' });
  }
}
