import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ExternalService {
    
    constructor(private http: HttpClient) {}

    getAddressByCep(postalCode: string) {
        const url = `https://viacep.com.br/ws/${postalCode}/json/`;

        return this.http.get(url);
    }

}